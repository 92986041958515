import React, {Component} from 'react';
import {AuthUserContext, withAuthorization} from '../../Components/Session/Session';

import ReviewSummary from '../../Components/Feedback/Review';
import FeedbackSummary from '../../Components/Feedback/Feedback';
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import classnames from 'classnames';
import LoadingDiv from '../../Components/Misc/Loading'

const INITIAL_STATE = {
  something: false,
  activeTab: 'feedback',
  childrenToLoad: {
    FeedbackSummary: false,
    ReviewSummary: false
  },
};

class PerformanceView extends Component {
  constructor (props) {
      super(props);
      this.childrenDidLoad = this.childrenDidLoad.bind(this)

      this.state = {...INITIAL_STATE};
  };

  componentDidMount() {
    if (window.location.hash === "#reviews") this.setState({ activeTab: 'reviews' }); // if hash is set in the url then the default tab is loaded
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      window.location.hash = tab; // set the hash for the url to determine default tab

      this.setState({
        activeTab: tab
      });
    }
  }
  
  isRenderComplete() {
    var isRenderCompleted = true;
    for (var key in this.state.childrenToLoad) {
        isRenderCompleted &= this.state.childrenToLoad[key];
        //console.log(key + " : " + this.state.childrenToLoad[key]);
    }
    this.setState({ rendering: !isRenderCompleted });
    if (isRenderCompleted && this.props.didLoad !== undefined) {
      this.props.didLoad();
    }
  }

  childrenDidLoad(component) {
      var { childrenToLoad } = this.state;
      childrenToLoad[component] = true;
      this.setState( { childrenToLoad: childrenToLoad } 
                      ,() => { this.isRenderComplete();  })
  }

  render () {
    return (
      <div className="content">
        <Row>
          <Col md="12">
            <AuthUserContext.Consumer>
              {authUser => (
                <div>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === 'feedback' })}
                        onClick={() => { this.toggle('feedback'); }}
                      >
                        FEEDBACK
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === 'reviews' })}
                        onClick={() => { this.toggle('reviews'); }}
                      >
                        REVIEWS
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <br />
                  <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="feedback">
                      <LoadingDiv />
                      <FeedbackSummary uid={authUser.uid} email={authUser.email} didLoad={() => this.childrenDidLoad("FeedbackSummary")} />
                    </TabPane>
                    <TabPane tabId="reviews">
                      <LoadingDiv />
                      <ReviewSummary uid={authUser.uid} email={authUser.email} didLoad={() => this.childrenDidLoad("ReviewSummary")} />
                    </TabPane>
                  </TabContent>
                </div>
              )}
            </AuthUserContext.Consumer>
          </Col>
        </Row>
      </div>

      
    );
  };
};

const condition = authUser => !!authUser;

export default withAuthorization(condition)(PerformanceView);
