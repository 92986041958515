import React, {Component} from 'react';
import {withFirebase} from '../Firebase/Firebase';
import {
    Button, 
    Form, 
    FormGroup, 
    Label, 
    Input, 
    Row,
    Col,
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    Table,
    FormFeedback,
    UncontrolledTooltip
} from 'reactstrap';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
//import { tsThisType } from '@babel/types';



/***************************************************************************************/
/*************************** Action Items - View/Create/Edit ***************************/
/***************************************************************************************/

const INITIAL_STATE_ACTION_ITEM_MODAL = {
    loading: true,
    changed: false,
    validToSubmit: false,
    aiDoc: null,
    mainModal: false,
    cancelConfirmationModal: false,
    isValidDueDate: '',

    description: '',
    term: 'Short',
    dueDate: '',
    key: 0,
};

class ActionItemCreateEditModalBase extends Component {
    constructor(props) {
        super(props);
        //props.uid
        //props.mentorshipSessionID // optional if created from mentorshipSession
        //props.buttonText // optional
        //props.aiDoc // if aiDoc is passed in then it is NOT a create new
        //props.refreshPage

        this.state = {...INITIAL_STATE_ACTION_ITEM_MODAL};
    }

    // Load session data from fs on load
    componentDidMount() {
        this.loadDefaults();
    }

    loadDefaults() {
        this.setState({...INITIAL_STATE_ACTION_ITEM_MODAL});
        if (this.props.aiDoc !== null && this.props.aiDoc !== undefined) {
            this.setState({ aiDoc: this.props.aiDoc });
            this.setState({ description: this.props.aiDoc.data.description });
            this.setState({ term: this.props.aiDoc.data.term });
            this.setState({ dueDate: this.props.aiDoc.data.dueDate });
            this.setState({ key: this.props.aiDoc.id });
        }
        this.setState({loading: false});
    }

    // call to firestore to create the FS entry
    submitCreateActionItem() {
        return this.props.firebase.doCreateActionItem( // then create the new mentorship relationship in firestore
            this.props.uid,
            this.props.mentorshipSessionID,
            this.state.description,
            this.state.term,
            this.state.dueDate
        ).then (() => {
            this.onConfirmationConfirm();
            this.props.refreshPage();
        }).catch(error => {
            this.setState({errorConfirmModal: error});
        })
    }

    // call to firestore to update the FS entry
    submitUpdateActionItem() {
        return this.props.firebase.doUpdateActionItem( // then create the new mentorship relationship in firestore
            this.props.aiDoc.id,
            this.state.description,
            this.state.term,
            this.state.dueDate
        ).then (() => {
            this.onConfirmationConfirm();
            this.props.refreshPage();
        }).catch(error => {
            this.setState({errorConfirmModal: error});
        })
    }

    // open the main modal
    openMainModal() {
        this.setState({ mainModal: true });
    }


    onSubmit() {
        if (this.state.changed) {
            this.validateSubmission("all");
            if (this.state.validToSubmit) {
                if (this.state.aiDoc) {
                    this.submitUpdateActionItem();
                } else {
                    this.submitCreateActionItem();
                }
            } else this.setState({ mainModalError: new Error("There may be invalid values or missing required fields.")});
        } else {
            this.onConfirmationConfirm();
        }
    }

    onRemove() {
        this.props.firebase.doDeleteGoalItem(this.props.aiDoc.id)
        .then(() => {
            this.onConfirmationConfirm();
            this.props.refreshPage();
        }).catch(error => {
            this.setState({errorConfirmModal: error});
        })
    }
    
    onCloseModal() {
        if (this.state.changed) {
            this.setState({ cancelConfirmationModal: true });
        } else {
            this.onConfirmationConfirm();
        }
    }

    // modal actions: cancels the modal and resets it
    onConfirmationConfirm() {
        this.loadDefaults();
        this.setState({ loading: false });
    }
    
    // modal actions: close the confirmation modal
    onConfirmationCancel() {
        this.setState({ cancelConfirmationModal: false });
    }

    onChange = event => {
        event.persist(); // allows event to be available to the callback

        this.setState({
            [event.target.name]: event.target.value
        }, () => {
            this.validateSubmission(event.target.name);
            this.isChanged();
        });
    };

    // Validate whether there are changes to the form
    isChanged() {
        let isChanged = false;

        if (this.state.aiDoc !== null && this.state.aiDoc !== undefined) {
            isChanged = this.state.description !== this.state.aiDoc.data.description
                        || this.state.term !== this.state.aiDoc.data.term
                        || this.state.dueDate !== this.state.aiDoc.data.dueDate;
        } else {
            isChanged = this.state.description !== INITIAL_STATE_ACTION_ITEM_MODAL['description']
                        || this.state.term !== INITIAL_STATE_ACTION_ITEM_MODAL['term']
                        || this.state.dueDate !== INITIAL_STATE_ACTION_ITEM_MODAL['dueDate'];
        }
        this.setState({ changed: isChanged });
    }

    // Validate whether the values of the form are valid
    validateSubmission = fieldToValidate => {
        var isValid = true; 

        if (fieldToValidate === "dueDate" || fieldToValidate === "all") {
            var today = new Date();
            if (this.state.dueDate !== '' && this.state.dueDate < today) { 
                this.setState({isValidDueDate: "has-danger"});
                isValid = false;
            } else this.setState({isValidDueDate: ""});
        }
        this.setState({ validToSubmit: isValid });
    }

    render() {
        if (this.state && !this.state.loading) {
            return (
                <>
                    <Button color="link"
                            id={"createEditActionItem"+this.state.key}
                            title=""
                            type="button" 
                            className="btn-no-spacing"
                            onClick={() => this.openMainModal()}>
                        <p className="text-muted"><i className={this.state.aiDoc? "tim-icons icon-pencil": "tim-icons icon-simple-add"} /></p>
                        {this.props.buttonText? " "+this.props.buttonText : null }
                    </Button>
                    <UncontrolledTooltip delay={0}
                                        target={"createEditActionItem"+this.state.key}
                                        placement="right" >
                        {this.state.aiDoc? "Edit Action Item": "Create New Action Item"}
                    </UncontrolledTooltip>


                    <Modal isOpen={this.state.mainModal} toggle={() => this.onCloseModal()} size="lg" >
                        <ModalHeader tag="h4" toggle={() => this.onCloseModal()} >
                            {this.state.aiDoc? "Edit Action Item" : "New Action Item"}
                        </ModalHeader>
                        <ModalBody>
                            <Form className="form-newActionItem">
                                <FormGroup>
                                    <Label for="actionItem-description">Action Item Description</Label>
                                    <Input
                                        id="actionItem-description"
                                        className="form-control"
                                        name = "description"
                                        value = {this.state.description}
                                        onChange = {e => this.onChange(e)}
                                        onBlur = {e => this.onChange(e)}
                                        type = "textarea"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="session-term">Term</Label>
                                    <Input
                                        id="actionItem-term"
                                        className="form-control"
                                        name = "term"
                                        value = {this.state.term}
                                        onChange = {e => this.onChange(e)}
                                        onBlur = {e => this.onChange(e)}
                                        type = "select">
                                    <option>Short</option>
                                    <option>Medium</option>
                                    <option>Long</option>
                                    </Input>
                                </FormGroup>
                                <FormGroup className={this.state.isValidDueDate}>
                                    <Label for="actionItem-dueDate">Due Date</Label>
                                    <Input 
                                        id="actionItem-dueDate"
                                        type="date" 
                                        name="dueDate" 
                                        value = {this.state.dueDate}
                                        onChange = {e => this.onChange(e)}
                                        onBlur = {e => this.onChange(e)}
                                        />
                                    <FormFeedback>Due date cannot be in the past</FormFeedback>
                                </FormGroup>
                            </Form>

                            <Modal isOpen={this.state.cancelConfirmationModal} toggle={() => this.onCloseModal()} size="sm">
                                <ModalHeader tag="h4" toggle={() => this.onCloseModal()}>Cancel Confirmation</ModalHeader>
                                <ModalBody>
                                    <center>
                                        You have unsaved changes, are you sure you would like to cancel?
                                    </center>
                                </ModalBody>
                                <ModalFooter>
                                    <i className="tim-icons icon-check-2" onClick={() => this.onConfirmationConfirm()}/>
                                    <i className="tim-icons icon-simple-remove" onClick={() => this.onConfirmationCancel()}/>
                                </ModalFooter>
                            </Modal>
                            {this.state.mainModalError && <>{this.state.mainModalError.message}</>}
                        </ModalBody>
                        <ModalFooter>
                            <i className="tim-icons icon-check-2" onClick={() => this.onSubmit()}/>
                            {this.state.aiDoc? <i className="tim-icons icon-trash-simple" onClick={() => this.onRemove()}/> : null}
                            <i className="tim-icons icon-simple-remove" onClick={() => this.onCloseModal()}/>
                        </ModalFooter>
                    </Modal>
                </>
            )
        } else return null
    }
}



/***************************************************************************************/
/*************************** Action Items - RenderCheckboxActionItem *******************/
/***************************************************************************************/

class RenderCheckboxActionItemBase extends Component {
    constructor(props) {
        super(props);
        //props.uid
        //props.aiDoc
        //props.renderCheckbox // boolean
        //props.refreshPage

        this.state = { status: props.aiDoc.data.status,
                        updateError: null }

        // this.onCreateAchivement = this.onCreateAchivement.bind(this);
    }

    // When the checkbox is modified, the status is updated between active and completed
    handleChangeChk = () => {
        var newStatus = "active"
        if (this.state.status === "active") newStatus = "completed";

        this.props.firebase.doUpdateActionItemStatus(this.props.aiDoc.id, newStatus)
        .then(() => {
            this.setState({status: newStatus});
            //this.props.refreshPage(); // comment out to prevent reload on check
        }).catch(error => {
            console.log(error)
            this.setState({updateError: error});
        });
    }

    // // when an achivement is created, the goal is archived
    // onCreateAchivement = (achievementDocID) => {
    //     this.props.firebase.doUpdateGoalItemAchievement(this.props.aiDoc.id, achievementDocID)
    //     .then(() => {
    //         this.setState({status: "archived"});
    //         //this.props.refreshPage(); // comment out to prevent reload on check
    //     }).catch(error => {
    //         console.log(error)
    //         this.setState({updateError: error});
    //     });
    // }

    // Renders the edit button for non archived goals
    renderEdit() {
        if (this.state.status !== "archived") {
            return(
                <ActionItemCreateEditModal aiDoc={this.props.aiDoc} refreshPage={this.props.refreshPage} />
            )
        } 
    }

    // Renders the edit button for non archived goals
    renderWithCheckbox() {
        if (this.props.renderCheckbox) {
            return(
                <>
                    <td>
                        <FormGroup check>
                            <Label check>
                                <Input type="checkbox" checked={this.state.status === "completed"} onChange={this.handleChangeChk} />
                                <span className="form-check-sign">
                                    <span className="check" />
                                </span>
                            </Label>
                        </FormGroup>
                    </td>
                    <td>
                        <p className="text-muted">
                            {this.props.aiDoc.data.description}
                        </p>
                    </td>
                    <td className="td-actions text-right">
                        {this.renderEdit()}
                    </td>
                </>
            )
        } else {
            return(
                <>
                    <td>
                        <p className="text-muted">
                            {this.props.aiDoc.data.description}
                        </p>
                    </td>
                    <td>
                        {this.renderEdit()}
                    </td>
                </>
            )
        }
    }

    render() {
        return (
            <>
                {this.renderWithCheckbox()}
                {this.state.updateError && <>{this.state.updateError.message}</>}
            </>
        )
    };
};


/***************************************************************************************/
/*************************** Action Items - RenderCheckboxActionItemList ***************/
/***************************************************************************************/

class RenderCheckboxActionItemListBase extends Component {
    // constructor(props) {
    //     super(props);
    //     //props.uid
    //     //props.aiDocs
    //     //props.renderCheckbox // boolean
    //     //props.refreshPage
    // }

    render() {
        return (
            <div className={this.props.renderScrollbar? "table-full-width table-responsive": "" } >
                <Table>
                    <tbody>
                        { this.props.aiDocs.map(aiDoc => 
                            <tr key={"renderactionitemlist_item_"+aiDoc.id}> 
                                <RenderCheckboxActionItem renderCheckbox={this.props.renderCheckbox} 
                                uid={this.props.uid} 
                                aiDoc={aiDoc} 
                                refreshPage={this.props.refreshPage} />
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
        )
    };
};


/***************************************************************************************/
/*************************** Action Items - RenderActionItemsList **********************/
/***************************************************************************************/

// Renders the unordered list of the goals with the appropriate action button beside it
class RenderActionItemsListBase extends Component {
    // constructor(props) {
    //     super(props);
    //     //props.uid
    //     //props.actionItemsList
    //     //props.refreshPage
    //     //props.renderSML
    //     //props.renderCheckbox
    //     //props.renderCreateAchievement
    // }

    render() {
        if (this.props.actionItemsList !== null && this.props.actionItemsList !== undefined) {
            if (this.props.renderSML) {
                var actionItemListForShortTerm = [];
                var actionItemListForMediumTerm = [];
                var actionItemListForLongTerm = [];
                this.props.actionItemsList.forEach(aiDoc => { if (aiDoc.data.term === "Short") actionItemListForShortTerm.push(aiDoc); })
                this.props.actionItemsList.forEach(aiDoc => { if (aiDoc.data.term === "Medium") actionItemListForMediumTerm.push(aiDoc); })
                this.props.actionItemsList.forEach(aiDoc => { if (aiDoc.data.term === "Long") actionItemListForLongTerm.push(aiDoc); })
                return (
                    <div className="table-goals-full-height table-full-width table-responsive">
                        {actionItemListForShortTerm.length > 0?
                            <>
                                <Row>
                                    <Col xs="12">
                                        <h5>Short Term</h5>
                                    </Col>
                                    </Row>
                                <Row>
                                    <Col xs="12">
                                        <RenderCheckboxActionItemListBase uid={this.props.uid} aiDocs={actionItemListForShortTerm} renderCheckbox={this.props.renderCheckbox} refreshPage={this.props.refreshPage} renderScrollbar={false} />
                                    </Col>
                                </Row>
                                <br />
                            </>
                        : null }
                        {actionItemListForMediumTerm.length > 0?
                            <>
                                <Row>
                                    <Col xs="12">
                                        <h5>Medium Term</h5>
                                    </Col>
                                    </Row>
                                <Row>
                                    <Col xs="12">
                                        <RenderCheckboxActionItemListBase uid={this.props.uid} aiDocs={actionItemListForMediumTerm} renderCheckbox={this.props.renderCheckbox} refreshPage={this.props.refreshPage} renderScrollbar={false} />
                                    </Col>
                                </Row>
                                <br />
                            </>
                        : null }
                        {actionItemListForLongTerm.length > 0?
                            <>
                                <Row>
                                    <Col xs="12">
                                        <h5>Long Term</h5>
                                    </Col>
                                    </Row>
                                <Row>
                                    <Col xs="12">
                                        <RenderCheckboxActionItemListBase uid={this.props.uid} aiDocs={actionItemListForLongTerm} renderCheckbox={this.props.renderCheckbox} refreshPage={this.props.refreshPage} renderScrollbar={false} />
                                    </Col>
                                </Row>
                                <br />
                            </>
                        : null }
                        
                        {actionItemListForShortTerm.length === 0 && actionItemListForMediumTerm.length === 0 && actionItemListForLongTerm.length === 0? <center><h5>You don't have any active goals.<br />Create one now!</h5></center>: null}
                    </div>
                );
            } else {
                return (
                    <RenderCheckboxActionItemListBase uid={this.props.uid} aiDocs={this.props.actionItemsList} renderCheckbox={this.props.renderCheckbox} refreshPage={this.props.refreshPage} renderScrollbar={false} />
                )
            }
        } else return null;
    };
};




/***************************************************************************************/
/*************************** Action Items - Mentorship Session Action Items ************/
/***************************************************************************************/

const INITIAL_STATE_MENTORSHIP_SESSION_ACTION_ITEMS = {
    loading: true,
    actionItemDocs: null,
    error: null
};

class MentorshipSessionActionItemsBase extends Component {
    constructor(props) {
        super(props);
        //props.uid
        //props.mentorshipSessionID
        this.state={
           loading: false,
        }

        this.loadData = this.loadData.bind(this);
    }
    
    // Helper function to parse the return from firestore
    mapData = resultArray => {
        const returnArray = [];
        resultArray.forEach(doc => {
            returnArray.push({
                id: doc.id,
                data: doc.data(),
            });
        })
        return returnArray;
    };

    // Load feedback data from database
    componentDidMount() {
        this.loadData();
    }

    // Loads data from Firebase and sets loading state to false
    loadData = () => {
        this.setState({...INITIAL_STATE_MENTORSHIP_SESSION_ACTION_ITEMS});

        // Get goal summary
        this.props.firebase.doGetActionItemsByMentorshipSessionID(this.props.mentorshipSessionID).then(actionItemsQS => {
            this.setState({ actionItemDocs: this.mapData(actionItemsQS), loading: false });
        }).catch(error => {
            console.log(error)
            this.setState({loadingError: error});
        });
    }

    render() {
        if (this.state && !this.state.loading) {
            return (
                <>
                    <RenderActionItemsList uid={this.props.uid} actionItemsList={this.state.actionItemDocs} renderSML={true} renderCheckbox={true} refreshPage={this.loadData} />
                    {this.state.errorCreateModal && <>{this.state.errorCreateModal.message}</>}
                </>
            );
        }

        // While data is being loaded from database
        return null;
    };
};



/***************************************************************************************/
/*************************** Action Items - Render Active Action Items *****************/
/***************************************************************************************/

const INITIAL_STATE_ACTION_ITEMS_ACTIVE = {
    loading: false,
    activeActionItemDocs: null,
    error: null
};

class ActiveActionItemsBase extends Component {
    constructor(props) {
        super(props);
        //props.uid
        this.loadData = this.loadData.bind(this);
    }

    // Helper function to parse the return from firestore
    mapData = resultArray => {
        const returnArray = [];
        resultArray.forEach(doc => {
            returnArray.push({
                id: doc.id,
                status: doc.data().status,
                data: doc.data(),
            });
        })
        return returnArray;
    };

    // Load feedback data from database
    componentDidMount() {
        this.loadData();
    }

    
    // Loads data from Firebase and sets loading state to false
    loadData = () => {
        this.setState({...INITIAL_STATE_ACTION_ITEMS_ACTIVE});

        // get the goals for each of the statuses
        /* Future enhancement to include pagination and limiting for archived goals */
        this.props.firebase.doGetActionItemByUID(this.props.uid, 'active').then(activeQS => {
            this.setState({ activeActionItemDocs: this.mapData(activeQS), loading: false }
                            ,() => { if (this.props.didLoad !== undefined) this.props.didLoad() });
        }).catch(error => {
            console.log(error)
            this.setState({loadingError: error});
        });
    }

    render() {
        if (this.state && !this.state.loading) {
            return (
                <>
                    <RenderActionItemsList uid={this.props.uid} actionItemsList={this.state.activeActionItemDocs} renderSML={true} renderCheckbox={true} refreshPage={this.loadData} />
                    {this.state.errorCreateModal && <>{this.state.errorCreateModal.message}</>}
                </>
            );
        }
        // While data is being loaded from database
        return null;
    };
};


/***************************************************************************************/
/*************************** Action Items - Render Completed Action Items **************/
/***************************************************************************************/


const INITIAL_STATE_ACTION_ITEMS_COMPLETED = {
    loading: true,
    completedActionItemDocs: null,
    archivedActionItemDocs: null,
    error: null
};

class CompletedActionItemsBase extends Component {
    constructor(props) {
        super(props);
        //props.uid
        this.state={}
        this.loadData = this.loadData.bind(this);
    }


    // Helper function to parse the return from firestore
    mapData = resultArray => {
        const returnArray = [];
        resultArray.forEach(doc => {
            returnArray.push({
                id: doc.id,
                status: doc.data().status,
                data: doc.data(),
            });
        })
        return returnArray;
    };

    // Load feedback data from database
    componentDidMount() {
        this.loadData();
    }
    
    // Loads data from Firebase and sets loading state to false
    loadData = () => {
        this.setState({...INITIAL_STATE_ACTION_ITEMS_COMPLETED});

        // get the goals for each of the statuses
        /* Future enhancement to include pagination and limiting for archived goals */
        this.props.firebase.doGetActionItemByUID(this.props.uid, 'completed').then(completedQS => {
            this.setState({ completedActionItemDocs: this.mapData(completedQS) });

            this.props.firebase.doGetActionItemByUID(this.props.uid, 'archived')
            .then(archivedQS => {
                this.setState({ archivedActionItemDocs: this.mapData(archivedQS), loading: false }
                                ,() => { if (this.props.didLoad !== undefined) this.props.didLoad() });
            })
        }).catch(error => {
            this.setState({loadingError: error});
        });
    }


    render() {
        if (this.state && !this.state.loading) {
            return (
                <>
                    <div className="table-goals-full-height table-full-width table-responsive">
                        <Row>
                            <Col xs="12">
                                <h5>Completed Action Items</h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12">
                                { this.state.completedActionItemDocs !== null && this.state.completedActionItemDocs !== undefined && this.state.completedActionItemDocs.length > 0? 
                                    <RenderActionItemsList actionItemsList={this.state.completedActionItemDocs} renderCheckbox={true} uid={this.props.uid} refreshPage={this.loadData} />
                                    : <center><p className="text-muted">Nothing to show here. Keep up the good work!</p><br/ ></center>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12">
                                <h5>Archived Action Items</h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12">
                                { this.state.archivedActionItemDocs !== null && this.state.archivedActionItemDocs !== undefined && this.state.archivedActionItemDocs.length > 0? 
                                    <RenderActionItemsList actionItemsList={this.state.archivedActionItemDocs} uid={this.props.uid} refreshPage={this.loadData} />
                                    : <center><p className="text-muted">Nothing to show here. Keep up the good work!</p></center>
                                }
                            </Col>
                        </Row>
                    </div>

                </>
            );
        } return null;
    };
};



const RenderActionItemsList = withFirebase(RenderActionItemsListBase);
const ActionItemCreateEditModal = withFirebase(ActionItemCreateEditModalBase);
const ActiveActionItems = withFirebase(ActiveActionItemsBase);
const CompletedActionItems = withFirebase(CompletedActionItemsBase);
const RenderCheckboxActionItem = withFirebase(RenderCheckboxActionItemBase);
const MentorshipSessionActionItems = withFirebase(MentorshipSessionActionItemsBase);

export {RenderActionItemsList,
        ActionItemCreateEditModal,
        ActiveActionItems,
        CompletedActionItems,
        RenderCheckboxActionItem,
        MentorshipSessionActionItems};