import React from "react";
// javascript plugin used to create scrollbars on windows
import { Route, Switch } from "react-router-dom";

//import Header from "components/Header/Header.jsx";
import Sidebar from "../Components/Sidebar/Sidebar";
import {dashboardRoutesFull} from "../routes/dashboard";
import DashTopNav from "../Components/Navigation/DashTopNav";
//import FixedPlugin from "../Components/FixedPlugin/FixedPlugin";
import Footer from "../Components/Footer/Footer";

import {withAuthorization} from '../Components/Session/Session';

// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
var ps;


class DashboardLayout extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      backgroundColor: "blue",
      sidebarOpened: document.documentElement.className.indexOf("nav-open") !== -1
    };

    this.childrenDidLoad = this.childrenDidLoad.bind(this); 
  }

  
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.refs.mainPanel, { suppressScrollX: true });
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    
    // set the default color mode. 
    // TODO: implement dark mode preference in user settings and load prior to other ui components loading
    this.activateMode("light");
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
  }

  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      if (navigator.platform.indexOf("Win") > -1) {
        this.reloadPerfectScrollbar();
      }
    }
  }

  reloadPerfectScrollbar() {
    let tables = document.querySelectorAll(".table-responsive");
    for (let i = 0; i < tables.length; i++) {
      ps = new PerfectScrollbar(tables[i]);
    }
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainPanel.scrollTop = 0;
  }

    // Callback for handling loading of children component
    // pass this into the children components that require db read to render AND should load together with the page
  childrenDidLoad(component) {
    this.reloadPerfectScrollbar();
    // remove loading
    var loadingDivs = document.querySelectorAll("#loading");
    loadingDivs.forEach(lDiv => {
      lDiv.style.visibility = 'hidden'; 
      lDiv.style.opacity = 0;
    })
    //console.log(component)
  }

  toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    this.setState({ sidebarOpened: !this.state.sidebarOpened });
  };

  toggleContentbar = () => {
    document.documentElement.classList.toggle("content-open");
    this.setState({ contentbarOpened: !this.state.contentbarOpened });
  };

  handleBgClick = color => {
    this.setState({ backgroundColor: color });
  };

  // light or dark mode
  activateMode = mode => {
    switch (mode) {
      case "light":
        document.body.classList.add("white-content");
        this.handleBgClick("blue");
        break;
      default: // "dark"
        document.body.classList.remove("white-content");
        this.handleBgClick("primary");
        break;
    }
  };

  getBrandText = path => {
    for (let i = 0; i < dashboardRoutesFull.length; i++) {
      if (
        this.props.location.pathname.indexOf(dashboardRoutesFull[i].path) !== -1
      ) {
        return dashboardRoutesFull[i].name;
      }
    }
    return "CMD";
  };

  render() {

    // Attaches the callback object before rendering in Route
    const renderMergedProps = (component, componentName) => {
      const finalProps = Object.assign({ didLoad: () => this.childrenDidLoad(componentName) });
      return React.createElement(component, finalProps);
    }

    return (
        <div className="wrapper">
          <Sidebar
            {...this.props}
            bgColor={this.state.backgroundColor}
            toggleSidebar={this.toggleSidebar}
          />
          <div
            className="main-panel"
            ref="mainPanel"
            data={this.state.backgroundColor}
          >
            <DashTopNav
              {...this.props}
              //brandText={this.getBrandText(this.props.location.pathname)}
              brandText="CMD"
              isDashboard={true}
              toggleSidebar={this.toggleSidebar}
              sidebarOpened={this.state.sidebarOpened}
            />
            <Switch>
                {dashboardRoutesFull.map((prop, key) => {
                  return (
                    <Route path={prop.path} 
                            //component={prop.component} // old method of directly passing component name
                            render={() => {return renderMergedProps(prop.component, prop.name)}}
                            key={key} />
                  );
                })}
            </Switch>
            <Footer fluid />
          </div>
          {/* <FixedPlugin
            bgColor={this.state.backgroundColor}
            handleBgClick={this.handleBgClick}
          /> */}
        </div>
    );
  }
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(DashboardLayout);