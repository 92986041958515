import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from "history";
import * as serviceWorker from './serviceWorker';
import Firebase, {FirebaseContext} from './Components/Firebase/Firebase';

import MainRoutes from './routes';

import "./assets/scss/black-dashboard-react.scss";
import "./assets/demo/demo.css";
import "./assets/css/nucleo-icons.css";

const hist = createBrowserHistory();

ReactDOM.render(
    <FirebaseContext.Provider value={new Firebase()}>
        <MainRoutes hist={hist} />
    </FirebaseContext.Provider>, 
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
