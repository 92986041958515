import React, {Component} from 'react';
import {withFirebase} from '../Firebase/Firebase';
import {SignUpLinkCustom} from '../SignUp/SignUp'
import {ReviewFeedbackRequest} from '../Feedback/Review'



const INITIAL_STATE = {
    loading: true,
    changed: false,
    validToSubmit: false,
    isValidFeedbackGeneral: '',
    saveOrSubmit: null,
    reviewerHasAccount: false,
    isCompleted: false,
    feedbackDoc: null,
    feedbackGeneral: '',
    confirmationModal: null,
    error: null,
}


class FeedbackStandaloneBase extends Component {
    constructor(props) {
        super(props);
        this.loadData = this.loadData.bind(this);

        // props.docid; // the document ID to search
        this.state = {...INITIAL_STATE};
    }

    // Load feedback data from database
    componentDidMount() {
        //console.log(props.uid);
        this.loadData();
    }

    // Loads data from Firebase and sets loading state to false
    loadData = () => {
        this.setState({ loading: true });

        this.props.firebase.doGetFeedbackById(this.props.docID)
            .then(DocumentSnapshot => {
                //console.log(DocumentSnapshot);

                // validate if feedback document exists
                if (DocumentSnapshot.exists) {
                    this.setState({
                        feedbackDoc: DocumentSnapshot,
                        reviewerHasAccount: DocumentSnapshot.data().reviewerUID !== ''
                    });
                    // if the Reviewer UID is present, it means there is an account
                }

                // finish loading
                this.setState({ loading: false});
            })
            .catch(error => {
                console.log(error);
                this.setState({error, loading: false});
            }); 
    }
    
    // // for Save and Submit
    // onUpdate = status => {
    //     this.props.firebase.doUpdateFeedbackRequest(
    //         this.props.docID,
    //         status,
    //         this.state.feedbackGeneral
    //     ).then (() => {
    //         this.setState({...INITIAL_STATE});
    //         if (status === "completed") this.setState({saveOrSubmit: "submit"});
    //         else if (status === "draft") this.setState({saveOrSubmit: "save"});
    //         this.loadData();
    //     }).catch(error => {
    //         this.setState({error});
    //     })
    // }

    // onChange = event => {
    //     event.persist();
        
    //     this.setState({
    //         [event.target.name]: event.target.value
    //     }, () => {
    //         this.setState({validToSubmit: this.validateSubmission(event.target.name)});
    //         this.isChanged();
    //     });
    // };

    // // Validate whether the field value(s) are valid to submit
    // validateSubmission = fieldToValidate => {
    //     var isValid = true; 

    //     if (fieldToValidate === "feedbackGeneral" || fieldToValidate === "all") {
    //         if (this.state.feedbackGeneral === '') { 
    //             this.setState({isValidFeedbackGeneral: "has-danger"});
    //             isValid = false;
    //         } else this.setState({isValidFeedbackGeneral: ""});
    //     }

    //     return isValid;
    // }

    // // Validate whether there are changes to the form
    // isChanged() {
    //     var isChanged = this.state.feedbackDoc.feedbackGeneral !== this.state.feedbackGeneral && this.state.feedbackDoc.feedbackGeneral !== null;

    //     this.setState({ changed: isChanged });
    // }

    render() {
        if (this.state && !this.state.loading) {
    
            // If error occurred when loading data
            if (this.state.error) return (
                <div>
                    An error occurred when loading the data. <br />
                    error: <br />
                    {this.state.error.message}
                </div>
            ) 

            // If feedback document ID was invalid
            if (this.state.feedbackDoc === null) {
                return (
                    <div>
                        The requested feedback does not exist.
                    </div>
                ) 
            }

            var tmpDoc = {
                id: this.state.feedbackDoc.id,
                status: this.state.feedbackDoc.data().status,
                data: this.state.feedbackDoc.data(),
            };

            // Successful retrieval
            return (
                <div>
                    <ReviewFeedbackRequest doc={tmpDoc} refreshPage={this.loadData} renderStandalone={true} />

                    <center>
                        { /* Message to user once there is a successful submit or save */ }
                        {this.state.saveOrSubmit === "submit"? "The feedback was successfully submitted.": null}
                        {this.state.saveOrSubmit === "save"? "The feedback was successfully saved.": null}
                        {this.state.reviewerHasAccount? 
                            (<>
                                <hr />
                                Seems like you don't have an account yet<br />
                                <SignUpLinkCustom phrase="Create one today" /> to manage all your feedback request.
                                <br />
                            </>): null}
                    </center>
                </div>
            )
        } 
        
        // While data is being loaded from database
        return "loading...";
    }
}


const FeedbackStandalone = withFirebase(FeedbackStandaloneBase);

export default FeedbackStandalone;