//import app from 'firebase/app';
import * as firebase from "firebase/app";
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import FirebaseContext, { withFirebase } from './context';
import * as ROLES from '../../constants/roles';

const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID
};

class Firebase {
    constructor() {
        // app.initializeApp(config);

        // this.auth = app.auth();
        // this.db = app.database();
        // this.fs = app.firestore();
        firebase.initializeApp(config);
        this.auth = firebase.auth();
        this.db = firebase.database();
        this.fs = firebase.firestore();

        // console.log(process.env.NODE_ENV);
    }


    // *********************************** //
    // ******** General Functions ******** //
    // *********************************** //

    // Returns today's date in YYYYMMDD format
    getDate() {
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1; //January is 0
        var yyyy = today.getFullYear();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;
        today = '' + yyyy + mm + dd;

        return today;
    }

    // Validates an in put value against a list of valid values
    validateValue(value, validValuesArray, fieldName) {
        if (validValuesArray.includes(value)) return true;
        else throw new Error("Invalid value provided for '" + fieldName + "'. Provided value: '" + value + "' Expected values: " + validValuesArray);
    }

    // ************************************ //
    // ******** Authentication API ******** //
    // ************************************ //

    doCreateUserWithEmailAndPassword = (email, password, firstName, lastName, role) => {
        return this.auth.createUserWithEmailAndPassword(email, password)
            .then(authUser => {
                // Create a user in your Firebase realtime database
                this.doCreateUserAccount(authUser.user.uid,
                    role,
                    firstName,
                    lastName,
                    email, null, null)

            });
    }

    doSignInWithEmailAndPassword = (email, password) =>
        this.auth.signInWithEmailAndPassword(email, password);

    doSignOut = () =>
        this.auth.signOut();

    doPasswordReset = email =>
        this.auth.sendPasswordResetEmail(email);

    doPasswordUpdate = password =>
        this.auth.currentUser.updatePassword(password);

    doEmailUpdate = email =>
        this.auth.currentUser.updateEmail(email);

    doSendVerificationEmail = () =>
        this.auth.currentUser.sendEmailVerification();

    doUpdateProfilePictureURL = pURL =>
        this.auth.currentUser.updateProfile({
            photoURL: pURL
        });


    // ********************************************** //
    // ******** Firestore - User Account API ******** //
    // ********************************************** //

    // Create the base user profile
    doCreateUserAccount = (uid,
        role,
        firstName,
        lastName,
        email,
        organizationName,
        educationLevel) => {
        var user = this.fs.collection('users').doc(uid);

        // Sets common values
        var profileObj = {
            role: role,
            firstName: firstName,
            lastName: lastName,
            email: email.toLowerCase(),
            theme: 'default',
        };

        // validates that a valid value has provided for role
        this.validateValue(role, [ROLES.IND, ROLES.ORG, ROLES.EDU], "ROLE");

        if (role === ROLES.IND) {
            profileObj["industryFocus"] = '';
        } else if (role === ROLES.ORG) {
            profileObj["organizationName"] = organizationName;
            profileObj["industry"] = "";
        } else if (role === ROLES.EDU) {
            profileObj["organizationName"] = organizationName;
            profileObj["educationLevel"] = educationLevel;
        }

        // Set user data at end and update all feedbacks
        return user.set(profileObj)
            .then(
                this.doAssociateFeedbackReviewerUIDByEmail(uid, email, firstName, lastName)
            );
    };

    // User profile retrieval
    doGetUserAccount = uid => 
        this.fs.collection('users').doc(uid).get();

    // User profile update
    doUpdateUserAccount = (uid,
        role,
        firstName,
        lastName,
        organizationName,
        industry,
        educationInstType,
        primaryPhone,
        primaryPhoneType,
        addressLine1,
        addressLine2,
        country,
        provinceState,
        city,
        postalZip,
        addrOther,
        theme,
        industryFocus,
        dob) => {
        console.log("helloagain")
        // Sets common values
        var profileObj = {
            firstName: firstName,
            lastName: lastName,
            theme: theme,
            primaryPhone: primaryPhone,
            primaryPhoneType: primaryPhoneType,
            addressLine1: addressLine1,
            addressLine2: addressLine2,
            country: country,
            provinceState: provinceState,
            city: city,
            postalZip: postalZip,
            addrOther: addrOther,
        };
        console.log("helloagain1")
        // validates that a valid value has provided for role
        this.validateValue(role, [ROLES.IND, ROLES.ORG, ROLES.EDU], "ROLE");
        console.log("helloagain2")
        // Set additional values specific to the account type
        if (role === ROLES.IND) {
            profileObj["dob"] = dob;
            profileObj["industryFocus"] = industryFocus;
        } else if (role === ROLES.ORG) {
            profileObj["organizationName"] = organizationName;
            profileObj["industry"] = industry;
        } else if (role === ROLES.EDU) {
            profileObj["organizationName"] = organizationName;
            profileObj["educationInstType"] = educationInstType;
        }
        console.log("helloagain3")
        // Set user data at end
        return this.fs.collection('users').doc(uid).update(profileObj);
    };


    // Search user by email, return UID (Without elevated admin priviledges)
    doGetUserByEmail = email =>
        this.fs.collection('users').where('email', '==', email.toLowerCase()).get();

    // ********************************************** //
    // ******** Firestore - Adminstrator API ******** //
    // ********************************************** //


    // Update the roles  ********** DO NOT USE IN CURRENT STATE***********
    // doModifyroles = (uid, roles) => {
    //   if (roles === 'individual' || roles === 'organization' || roles === 'education') {
    //     this.fs.collection('users').doc(uid).set({roles: roles});
    //   } else {
    //     // THROW an error if the roles value is invalid
    //     throw new Error("Invalid value for roles provided")
    //   }
    // };


    // ************************************************** //
    // ******** Firestore - Eduction History API ******** //
    // ************************************************** //

    // Add a new initial education entry
    doAddEducationEntry = (
        uid,
        school,
        educationLevel,
        degree,
        grade,
        startDate,
        endDate,
        courses,
        activities) => {
        return this.fs.collection('education').add({
            uid: uid,
            school: school,
            educationLevel: educationLevel,
            degree: degree,
            grade: grade,
            startDate: startDate,
            endDate: endDate,
            courses: courses,
            activities: activities,
        });
    };

    // Delete a specific education entry
    doDeleteEducationEntry = docID =>
        this.fs.collection('education').doc(docID).delete();

    // Retrieve all education entries for the user
    doGetEducationEntries = uid =>
        this.fs.collection('education').where('uid', '==', uid).get();

    // Retrieve specific education entry
    doGetEducationEntry = docID =>
        this.fs.collection('education').doc(docID).get();

    // Update an existing education entry
    doUpdateEducationEntry = (
        educationDocID,
        uid,
        school,
        educationLevel,
        degree,
        grade,
        startDate,
        endDate,
        courses,
        activities) => {
        return this.fs.collection('education').doc(educationDocID).update({
            uid: uid,
            school: school,
            educationLevel: educationLevel,
            degree: degree,
            grade: grade,
            startDate: startDate,
            endDate: endDate,
            courses: courses,
            activities: activities,
        });
    };


    // ************************************************** //
    // ******** Firestore - Certification History API ******** //
    // ************************************************** //

    // Add a new initial certification entry
    doAddCertificationEntry = (
        uid,
        name,
        authority,
        licenseNumber,
        URL,
        doesNotExpire,
        startDate,
        endDate) => {
        return this.fs.collection('certification').add({
            uid: uid,
            name: name,
            authority: authority,
            licenseNumber: licenseNumber,
            URL: URL,
            doesNotExpire: doesNotExpire,
            startDate: startDate,
            endDate: endDate
        });
    };

    // Delete a specific certification entry
    doDeleteCertificationEntry = docID =>
        this.fs.collection('certification').doc(docID).delete();

    // Retrieve all certification entries for the user
    doGetCertificationEntries = uid =>
        this.fs.collection('certification').where('uid', '==', uid).get();

    // Retrieve specific certification entry
    doGetCertificationEntry = docID =>
        this.fs.collection('certification').doc(docID).get();

    // Update an existing certification entry
    doUpdateCertificationEntry = (
        certificationDocID,
        uid,
        name,
        authority,
        licenseNumber,
        URL,
        doesNotExpire,
        startDate,
        endDate) => {
        return this.fs.collection('certification').doc(certificationDocID).update({
            uid: uid,
            name: name,
            authority: authority,
            licenseNumber: licenseNumber,
            URL: URL,
            doesNotExpire: doesNotExpire,
            startDate: startDate,
            endDate: endDate
        });
    };

    // ****************************************** //
    // ******** Firestore - Feedback API ******** //
    // ****************************************** //
    // Feedback Statuses: new, draft, cancelled, completed

    // Create Feedback Request
    doCreateFeedbackRequest = (
        projectID,
        projectName,
        requestorUID,
        requestorEmail,
        reviewerUID,
        reviewerFirstName,
        reviewerLastName,
        reviewerRole,
        reviewerEmail,
        projectRole,
        projectSummary,
        projectHours,
        startDate,
        endDate,
        formVersion,
    ) => {
        if (requestorUID === reviewerUID)
            throw new Error("Feedback requestor and reviewer are the same person");

        return this.doGetUserAccount(requestorUID).then(doc => {
            this.requestorFirstName = doc.data()["firstName"];
            this.requestorLastName = doc.data()["lastName"];
        }).then(() => {
            return this.fs.collection('feedback').add({
                projectID: projectID,
                projectName: projectName,
                requestorUID: requestorUID,
                requestorFirstName: this.requestorFirstName,
                requestorLastName: this.requestorLastName,
                requestorEmail: requestorEmail.toLowerCase(),
                reviewerUID: reviewerUID,
                reviewerFirstName: reviewerFirstName,
                reviewerLastName: reviewerLastName,
                reviewerRole: reviewerRole,
                reviewerEmail: reviewerEmail,
                formVersion: formVersion,
                projectRole: projectRole,
                projectSummary: projectSummary,
                projectHours: projectHours,
                startDate: startDate,
                endDate: endDate,
                requestedDate: this.getDate(),
                status: 'new',
            }).then(() => {
                console.log("create successful");
            })

        }).catch(error => {
            console.log(error);
            return error;
        })
    }

    // Associate feedback reviewerUID to user by email (for signup after account creation)
    doAssociateFeedbackReviewerUIDByEmail = (reviewerUID, reviewerEmail, reviewerFirstName, reviewerLastName) => {
        return this.doGetFeedbackByEmailForReviewer(reviewerEmail)
            .then(QuerySnapshots => {
                QuerySnapshots.forEach(DocumentSnapshot => {
                    this.fs.collection('feedback').doc(DocumentSnapshot.id).update({
                        reviewerUID: reviewerUID,
                        reviewerFirstName: reviewerFirstName,
                        reviewerLastName: reviewerLastName,
                    });
                });
            }).catch(error => { throw error });
    }

    // Retrieve a specific feedback document
    doGetFeedbackById = docID =>
        this.fs.collection('feedback').doc(docID).get();

    // Retrieve all documents for a reviewer by email (For correction during sign up)
    doGetFeedbackByEmailForReviewer = reviewerEmail =>
        this.fs.collection('feedback').where('reviewerEmail', '==', reviewerEmail).get();

    // Retrieve all feedback of type (new, draft, completed, cancelled) requests for a project
    doGetFeedbackForProject = (pid, status) => {
        if (this.validateValue(status, ["new", "draft", "completed", "cancelled"], "status"))
            return this.fs.collection('feedback').where('projectID', '==', pid).where('status', '==', status).get();
    }

    // Retrieve all feedback of type (new, draft, completed, cancelled) requests for a requestor
    doGetFeedbackForRequestor = (uid, status) => {
        if (this.validateValue(status, ["new", "draft", "completed", "cancelled"], "status"))
            return this.fs.collection('feedback').where('requestorUID', '==', uid).where('status', '==', status).get();
    }

    // Retrieve all feedback of type (new, pending, completed) requests for a requestor
    doGetFeedbackForReviewer = (uid, status) => {
        if (this.validateValue(status, ["new", "draft", "completed"], "status"))
            return this.fs.collection('feedback').where('reviewerUID', '==', uid).where('status', '==', status).get();
    }


    // Update Feedback Request
    doUpdateFeedbackRequest = (
        docID,
        status,
        feedbackGeneral
    ) => {
        var feedbackObj = {
            status: status,
            feedbackGeneral: feedbackGeneral
        }

        if (status === 'completed')
            feedbackObj["CompletedDate"] = this.getDate();

        return this.fs.collection('feedback').doc(docID).update(feedbackObj);
    }

    // Update Feedback Status - Cancelled
    doCancelFeedbackRequest = (docID) =>
        this.fs.collection('feedback').doc(docID).update({ status: 'cancelled' });

    // ********************************************* //
    // ******** Firestore - Achievement API ******** //
    // ********************************************* //

    /********************** Organization ***********************/

    // Retrieve all organization entries for the user
    doGetOrganizationEntries = uid =>
        this.fs.collection('organizations').where('uid', '==', uid).get();

    // Retrieve a specific Org Entry
    doGetOrganizationEntryById = orgDocID =>
        this.fs.collection('organizations').doc(orgDocID).get();

    // Add a new initial organization entry
    doAddOrganizationEntry = (uid,
        name,
        location,
        industry,
        description,
        startDate,
        endDate
    ) => {
        return this.fs.collection('organizations').add({
            uid: uid,
            name: name,
            location: location,
            industry: industry,
            description: description,
            startDate: startDate,
            endDate: endDate
        });
    };

    // Update an existing company entry
    doUpdateOrganizationEntry = (orgDocID,
        name,
        location,
        industry,
        description,
        startDate,
        endDate
    ) => {
        return this.fs.collection('organizations').doc(orgDocID).update({
            name: name,
            location: location,
            industry: industry,
            description: description,
            startDate: startDate,
            endDate: endDate
        });
    };

    // Delete an existing company entry & recursively deletes all associated positions
    doDeleteOrganizationEntry = (orgDocID) => {
        return this.doDeletePositionEntryForOrganization(orgDocID)
            .then(() => {
                this.fs.collection('organizations').doc(orgDocID).delete();
            }).catch(error => { throw error });
    };

    /********************** Position ***********************/

    // Retrieve all position entries for the user
    doGetPositionEntries = orgDocID =>
        this.fs.collection('positions').where('orgDocID', '==', orgDocID).get();

    // Retrieve a specific position Entry
    doGetPositionEntryById = positionDocID =>
        this.fs.collection('positions').doc(positionDocID).get();

    // Add a new position entry
    doAddPositionEntry = (uid,
        orgDocID,
        title,
        description,
        startDate,
        endDate) => {
        return this.fs.collection('positions').add({
            uid: uid,
            orgDocID: orgDocID,
            title: title,
            description: description,
            startDate: startDate,
            endDate: endDate
        });
    };

    // Add a update position entry
    doUpdatePositionEntry = (positionDocID,
        title,
        description,
        startDate,
        endDate) => {
        return this.fs.collection('positions').doc(positionDocID).update({
            title: title,
            description: description,
            startDate: startDate,
            endDate: endDate
        });
    };

    // Add a delete position entry & recursively deleteds all associated projects
    doDeletePositionEntry = (positionDocID) => {
        return this.doDeleteProjectEntryForPosition(positionDocID)
            .then(() => {
                this.fs.collection('positions').doc(positionDocID).delete();
            }).catch(error => { throw error });
    };

    // Add a delete position entry for a specific organization (For when organization is deleted)
    doDeletePositionEntryForOrganization = (orgDocID) => {
        return this.doGetPositionEntries(orgDocID)
            .then(QuerySnapshots => {
                QuerySnapshots.forEach(DocumentSnapshot => {
                    this.doDeletePositionEntry(DocumentSnapshot.id);
                });
            }).catch(error => { throw error });
    };


    /********************** Project ***********************/

    // Retrieve all project entries for the user
    doGetProjectEntries = positionDocID =>
        this.fs.collection('projects').where('positionDocID', '==', positionDocID).get();

    // Retrieve a specific project Entry
    doGetProjectEntryById = projectDocID =>
        this.fs.collection('projects').doc(projectDocID).get();

    // Add a new project entry
    doAddProjectEntry = (uid,
        positionDocID,
        name,
        role,
        description,
        startDate,
        endDate) => {
        return this.fs.collection('projects').add({
            uid: uid,
            positionDocID: positionDocID,
            name: name,
            role: role,
            description: description,
            startDate: startDate,
            endDate: endDate,
            //achievements: [''] // no longer storing array of achivement. storing as individual entries
        });
    };

    // Add a update project entry
    doUpdateProjectEntry = (projectDocID,
        name,
        role,
        description,
        startDate,
        endDate) => {
        return this.fs.collection('projects').doc(projectDocID).update({
            name: name,
            role: role,
            description: description,
            startDate: startDate,
            endDate: endDate
        });
    };

    // Add a delete project entry
    doDeleteProjectEntry = (projectDocID) => {
        return this.fs.collection('projects').doc(projectDocID).delete();
    };

    // Add a delete project entry for a specific Position (For when position is deleted)
    doDeleteProjectEntryForPosition = (positionDocID) => {
        return this.doGetProjectEntries(positionDocID)
            .then(QuerySnapshots => {
                QuerySnapshots.forEach(DocumentSnapshot => {
                    this.doDeleteProjectEntry(DocumentSnapshot.id);
                });
            }).catch(error => { throw error });
    };

    /********************** Achievement ***********************/

    // create an achievement entry. UID and Description are mandatory. projectDocID null = orphaned achievement
    doAddAchievement = (uid, description, projectDocID, goalDocID) => {
        if (uid === undefined || uid === null || uid === '') throw new Error("Error adding achievement: UID must be provided");

        var updateMap = {
            uid: uid,
            description: description
        };
        if (projectDocID !== null && projectDocID !== undefined) updateMap['projectDocID'] = projectDocID;
        if (goalDocID !== null && goalDocID !== undefined) updateMap['goalDocID'] = goalDocID;
        return this.fs.collection('achievements').add(updateMap);
    }

    // get achievement directly using doc ID
    doGetAchievementByID = (achievementDocID) => {
        return this.fs.collection('achievements').doc(achievementDocID).get();
    }

    // get all achievements for a specific user
    doGetAchievementsByUID = (uid) => {
        return this.fs.collection('achievements').where('uid', '==', uid).get();
    }

    // get all achievements for a specific project
    doGetAchievementsByProjectDocID = (projectDocID) => {
        return this.fs.collection('achievements').where('projectDocID', '==', projectDocID).get();
    }

    // update just description of the achievement
    doUpdateAchievementDescription = (achievementDocID, description) => {
        return this.fs.collection('achievements').doc(achievementDocID).update({ description: description });
    }

    // update the project association of the achievement
    doUpdateAchievementProject = (achievementDocID, projectDocID) => {
        return this.fs.collection('achievements').doc(achievementDocID).update({ projectDocID: projectDocID });
    }

    // delete achievement entry
    doDeleteAchievement = (achievementDocID) => {
        return this.fs.collection('achievements').doc(achievementDocID).delete();
    }

    // Add a update project entry (Achivement IDs are stored as an array under projects)
    doUpdateProjectAchievements = (projectDocID,
        achievementIDs) => {
        return this.fs.collection('projects').doc(projectDocID).update({
            achievementIDs: achievementIDs
        });
    };

    // ******************************************** //
    // ******** Firestore - Mentorship API ******** //
    // ******************************************** //

    /********************** Mentorship - Mentorship Relationship ***********************/

    // Create an initial mentorship relationship between mentor and mentee
    /** 
      - mentorUID
      - mentorFirstName
      - mentorLastName
      - menteeUID
      - menteeFirstName
      - menteeLastName
      - isPendingApproval = (approved(when org sets relationship)|mentee(when mentor requests)|mentor(when mentee requests))
      - startDate (set when approved)
      - endDate
      - sessionFrequency
      - generalNotesMentor
      - generalNotesMentee
      - nextSessionID
      - lastSessionID
    */
    doCreateMentorshipRelationship = (mentorUID,
        mentorEmail,
        mentorFirstName,
        mentorLastName,
        menteeUID,
        menteeEmail,
        menteeFirstName,
        menteeLastName,
        startDate,
        endDate,
        isPendingApproval) => {

        // field validation
        this.validateValue(isPendingApproval, ['approved', 'mentee', 'mentor', 'rejectedByMentee', 'rejectedByMentor'], "isPendingApproval");

        return this.fs.collection('mentorship').add({
            mentorUID: mentorUID,
            mentorEmail: mentorEmail,
            mentorFirstName: mentorFirstName,
            mentorLastName: mentorLastName,
            menteeUID: menteeUID,
            menteeEmail: menteeEmail,
            menteeFirstName: menteeFirstName,
            menteeLastName: menteeLastName,
            startDate: startDate,
            endDate: endDate,
            isPendingApproval: isPendingApproval
        });
    };

    // Approves the mentorship relationship and updates the status
    doApproveMentorshipRelationship = (mentorshipDocID) => {
        return this.fs.collection('mentorship').doc(mentorshipDocID).update({ isPendingApproval: 'approved' });
    };

    // Update the mentorship relationship details
    doUpdateMentorshipRelationshipDetails = (mentorshipID,
        startDate,
        endDate,
        sessionFrequency,
        generalNotesMentor,
        generalNotesMentee) => {
        return this.fs.collection('mentorship').doc(mentorshipID).update({
            startDate: startDate,
            endDate: endDate,
            sessionFrequency: sessionFrequency,
            generalNotesMentor: generalNotesMentor,
            generalNotesMentee: generalNotesMentee,
        });
    };

    // Update the mentorship relationship Next Session details
    doUpdateMentorshipRelationshipNextSession = (mentorshipID,
        nextSessionID) =>
        this.fs.collection('mentorship').doc(mentorshipID).update({ nextSessionID: nextSessionID })

    // Update the mentorship relationship Next Session & past Sessions details
    doUpdateMentorshipRelationshipSessionDetails = (mentorshipID,
        nextSessionID) =>
        this.fs.collection('mentorship').doc(mentorshipID).update({
            nextSessionID: nextSessionID
        })

    // removes the clears the nextSession value and appends it to the lastSessionID
    doArchiveCurrentMentorshipSession = (mentorshipID) => {
        var mDocRef = this.fs.collection('mentorship').doc(mentorshipID);
        return mDocRef.get()
            .then((mDoc) => {
                //return mDocRef.update({nextSessionID: '', lastSessionID: firebase.firestore.FieldValue.arrayUnion(mDoc.data().nextSessionID)
                return mDocRef.update({ nextSessionID: '', lastSessionID: mDoc.data().nextSessionID });
            }).catch(error => { throw error });
    }

    // // remove a specific mentorship session from lastSessionID by session ID
    // doRemovePastMentorshipSession = (mentorshipID,
    //                                 sessionID) => 
    //   this.fs.collection('mentorship').doc(mentorshipID).update({lastSessionID: firebase.firestore.FieldValue.arrayRemove(sessionID)});

    // Retrieves a specific mentorship relationship entry by the session ID
    doGetMentorshipRelationshipByID = mentorshipID =>
        this.fs.collection('mentorship').doc(mentorshipID).get();

    // Retrieves all mentorship relationships for a specific Mentee
    doGetMentorshipRelationshipsByMenteeID = (menteeUID) =>
        this.fs.collection('mentorship').where('menteeUID', '==', menteeUID).get();

    // Retreive all mentorship relationships for a specific Mentor
    doGetMentorshipRelationshipsByMentorID = (mentorUID) =>
        this.fs.collection('mentorship').where('mentorUID', '==', mentorUID).get();

    // Deletes a specific mentorship relationship session entry by the session ID
    doDeleteMentorshipRelationshipByID = mentorshipID =>
        this.fs.collection('mentorship').doc(mentorshipID).delete();

    /********************** Mentorship - Mentorship Session ***********************/

    // Create a mentorship session between mentor and mentee. 
    // At anypoint there should only be one active/inprogress session
    /** 
      - sessionID
      - menteeID
      - menteeFirstName
      - menteeLastName
      - mentorID
      - mentorFirstName
      - mentorLastName
      - status ( new (newly created, default status for creation) | open (session has started / inprogress) | skipped (session skipped, closed) | completed (session completed) )
      - location // text (future enhancement to support google map maybe in other field?)
      - dateTime // dateTime format
      - goalsIDArray [ // array of ID
      -- goalID ]
      - notesGeneral
      - notesMentee
      - notesMentor
      - closedDate // stores servertime when the session is closed off
    */
    doCreateMentorshipSession = (mentorshipID,
        location,
        locationMap,
        dateTime,
        notesGeneral) => {
        return this.doGetMentorshipRelationshipByID(mentorshipID)
            .then(DocumentSnapshot => {
                return this.fs.collection('mentorshipSession').add({
                    mentorshipID: mentorshipID,
                    mentorUID: DocumentSnapshot.data().mentorUID,
                    menteeUID: DocumentSnapshot.data().menteeUID,
                    status: 'new',
                    location: location,
                    locationMap: locationMap,
                    dateTime: dateTime,
                    notesGeneral: notesGeneral
                })
                    .then(DocumentReference => {
                        this.doUpdateMentorshipRelationshipNextSession(mentorshipID, DocumentReference.id); // update the next session on the mentorship relationship
                        return DocumentReference; // return the document reference of the Mentorship Session created
                    }).catch(error => { throw error });
            });
    };

    // update basic information for the mentorship, used prior to a session has started
    doEditBasicMentorshipSession = (sessionID,
        status,
        location,
        locationMap,
        dateTime,
        notesGeneral) => {
        // field validation
        this.validateValue(status, ['new', 'paused', 'open', 'cancelled', 'rescheduled', 'completed'], "status");

        return this.fs.collection('mentorshipSession').doc(sessionID).update({
            status: status,
            location: location,
            locationMap: locationMap,
            dateTime: dateTime,
            notesGeneral: notesGeneral,
        });
    };

    // update notes for the mentorship session. used post session. if a not update is not required, pass null
    doEditNotesMentorshipSession = (sessionID,
        notesGeneral,
        notesMentee,
        notesMentor) => {
        var updateMap = { notesGeneral: notesGeneral };
        if (notesMentee !== null && notesMentee !== undefined) updateMap['notesMentee'] = notesMentee;
        if (notesMentor !== null && notesMentor !== undefined) updateMap['notesMentor'] = notesMentor;

        return this.fs.collection('mentorshipSession').doc(sessionID).update({ ...updateMap });
    };

    // updates the status of the mentorship
    doUpdateStatusMentorshipSession = (sessionID,
        mentorshipID,
        status) => {
        // field validation
        this.validateValue(status, ['new', 'paused', 'open', 'cancelled', 'rescheduled', 'completed'], "status");

        var updateMap = { status: status };
        if (status === 'completed' || status === 'cancelled') {
            var today = new Date();
            var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
            var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            var dateTime = date + ' ' + time;
            updateMap['closedDate'] = dateTime;
            //updateMap['closedDate'] = firebase.firestore.FieldValue.serverTimestamp();
        }

        return this.fs.collection('mentorshipSession').doc(sessionID).update({ ...updateMap })
            .then(documentReference => {
                if (status === 'completed' || status === 'cancelled') {// when the status is completed or cancelled, the mentorship relationship details is updated as well
                    if (mentorshipID === undefined) // check that mentorshipID is provided
                        throw new Error("mentorshipID not provided when attempting to update mentorship session status to: '" + status);
                    else {
                        return this.doArchiveCurrentMentorshipSession(mentorshipID)
                            .then(() => { return documentReference }) // return this docuemnt reference instead of the archive response
                    }
                } else return documentReference
            }).catch(error => { throw error });
    };

    // updates the mentorship session details
    doUpdateMentorshipSession = (sessionID,
        status,
        location,
        locationMap,
        dateTime,
        goalsIDArray,
        notesGeneral,
        notesMentee,
        notesMentor) => {
        // field validation
        this.validateValue(status, ['new', 'paused', 'open', 'cancelled', 'rescheduled', 'completed'], "status");

        return this.fs.collection('mentorshipSession').doc(sessionID).update({
            status: status,
            location: location,
            locationMap: locationMap,
            dateTime: dateTime,
            goalsIDArray: goalsIDArray,
            notesGeneral: notesGeneral,
            notesMentee: notesMentee,
            notesMentor: notesMentor
        });
    };

    // Retrieves a specific mentorship session entry by the session ID
    doGetMentorshipSessionByID = sessionID =>
        this.fs.collection('mentorshipSession').doc(sessionID).get();

    // Retrieves all sessions for a specific mentorshipsession
    doGetMentorshipSessionsByMentorshipSesssionID = (mentorshipID, status) =>
        this.fs.collection('mentorshipSession')
            .where('mentorshipID', '==', mentorshipID)
            .where('status', '==', status)
            .orderBy('closedDate', 'desc')
            .orderBy('dateTime', 'desc').get();

    // Retrieves all sessions for a specific mentorship session limiting to specific number of results
    doGetMentorshipSessionsByMentorshipSesssionID = (mentorshipID, status, resultLimit) =>
        this.fs.collection('mentorshipSession')
            .where('mentorshipID', '==', mentorshipID)
            .where('status', '==', status)
            .orderBy('closedDate', 'desc')
            .orderBy('dateTime', 'desc')
            .limit(resultLimit).get();

    // // Retreive all sessions for a specific Mentor
    // doGetMentorshipSessionsForMentor = (mentorUID, status) => 
    //   this.fs.collection('mentorshipSession')
    //           .where('mentorUID', '==', mentorUID)
    //           .where('status', '==', status)
    //           .orderBy('dateTime', 'desc').get();

    // // Retreive all sessions for a specific Mentor
    // doGetMentorshipSessionsForMentor = (mentorUID, status, resultLimit) => 
    //   this.fs.collection('mentorshipSession')
    //           .where('mentorUID', '==', mentorUID)
    //           .where('status', '==', status)
    //           .orderBy('dateTime', 'desc')
    //           .limit(resultLimit).get();

    // Deletes a specific mentorship session entry by the session ID
    doDeleteMentorshipSessionByID = sessionID =>
        this.fs.collection('mentorshipSession').doc(sessionID).delete();


    /********************** Mentorship - UserGoals ***********************/

    // Get userGoals object
    // only one is permitted per user right now. 
    // once if a userGoal document is not found, one is created for the user
    /*
      user userGoals object:
      - UID
      - goalStatement
    */
    doGetUserGoalsByUID = (UID) => {
        return this.fs.collection('userGoals').where('UID', '==', UID).get().then(doc => {
            var numDocs = doc.size;
            if (numDocs === 1) {
                return doc; // expected behaviour is 1 doc. return if found
            } else if (numDocs === 0) {
                // If userGoals does not exist for the user, then create one
                return this.fs.collection('userGoals').add({ // attempt to create the new userGoals document
                    UID: UID,
                    goalStatement: ''
                }).then(DocumentReference => {
                    return this.fs.collection(DocumentReference.parent.id).doc(DocumentReference.id).get(); // retrieve the new document after it has been created
                }).catch(error => { throw error });
            } else if (numDocs >= 2) { // some error occured resulting in a duplicate
                throw new Error("An error has occurred, duplicate goals entries were found, please contact support for assistance.");
            } else { // some unknown error occured
                throw new Error("An unknown error has occured in retrieving you goals, please contact support for assistance.");
            }

        });
    };

    // Update the userGoals document 
    doUpdateUserGoalsByUID = (userGoalsDocID,
        goalStatement) => {
        return this.fs.collection('userGoals').doc(userGoalsDocID).update({
            goalStatement: goalStatement
        });
    };



    /********************** Mentorship - goalItem ***********************/

    // Create goalItem object
    /*
      goalItem object:
      - UID
      - description
      - term [ short | medium | long ]
      - dueDate
      - status // created as active
      - createdDate (system set)
      - completedDate (determine achievements has been created from this goal)
      - achievementDocID 

    */
    doCreateGoalItem = (UID,
        mentorshipSessionID,
        description,
        term,
        dueDate) => {
        // Validate field
        this.validateValue(term, ['Short', 'Medium', 'Long'], "term");

        var updateMap = {
            UID: UID,
            description: description,
            term: term,
            status: 'active',
            createdDate: this.getDate(),
            dueDate: dueDate,
        }

        // Set mentorshipSessionID only if available, if unavailable then goal is not associated with session
        if (mentorshipSessionID !== null && mentorshipSessionID !== undefined)
            updateMap['mentorshipSessionID'] = mentorshipSessionID;

        return this.fs.collection('goalItem').add({ ...updateMap });
    };

    // update the goalItem object
    doUpdateGoalItem = (goalItemDocID,
        description,
        term,
        dueDate) => {
        // Validate field
        this.validateValue(term, ['Short', 'Medium', 'Long'], "term");

        return this.fs.collection('goalItem').doc(goalItemDocID).update({
            description: description,
            term: term,
            dueDate: dueDate
        });
    };

    // update the goalItem status
    doUpdateGoalStatus = (goalItemDocID,
        status) => {
        this.validateValue(status, ['active', 'completed', 'archived'], "status");
        var updateMap = { status: status };

        if (status === "completed") {
            updateMap['completedDate'] = this.getDate(); // set the completed date
        } else if (status === "active") {
            updateMap['completedDate'] = firebase.firestore.FieldValue.delete(); // remove completed date if turned back to active
        }

        return this.fs.collection('goalItem').doc(goalItemDocID).update({ ...updateMap });
    }

    // set the ID for the achievement associated with this goal
    doUpdateGoalItemAchievement = (goalItemDocID,
        achievementDocID) => {
        return this.fs.collection('goalItem').doc(goalItemDocID).update({
            status: 'archived',
            achievementDocID: achievementDocID
        });
    };

    // get the specific goalItem
    doGetGoalItem = (goalItemDocID) => {
        return this.fs.collection('goalItem').doc(goalItemDocID).get();
    };

    // get all goalItem by UID and status
    // ** opportunity to optimize FS queries by reducing the results to recent ones **
    doGetGoalItemByUID = (UID, status) => {
        // Validate field
        this.validateValue(status, ["active", "completed", "archived"], "status");

        return this.fs.collection('goalItem').where('UID', '==', UID)
            .where('status', '==', status)
            .orderBy('createdDate', 'desc')
            .orderBy('dueDate', 'desc').get();
    };

    // get all goalItems associated with the mentorshipSessionID
    doGetGoalsItemsByMentorshipSessionID = (mentorshipSessionID) => {
        return this.fs.collection('goalItem').where('mentorshipSessionID', '==', mentorshipSessionID).get();
    }

    // Delete the specific goalItem
    doDeleteGoalItem = (goalItemDocID) => {
        return this.fs.collection('goalItem').doc(goalItemDocID).delete();
    };





    /********************** Mentorship - actionItem ***********************/

    // Create actionItem object
    /*
      actionItem object:
      - UID
      - description
      - term [ short | medium | long ]
      - dueDate
      - status // created as active
      - createdDate (system set)
      - completedDate (determine achievements has been created from this goal)
      - achievementDocID 

    */
    doCreateActionItem = (UID,
        mentorshipSessionID,
        description,
        term,
        dueDate) => {
        // Validate field
        this.validateValue(term, ['Short', 'Medium', 'Long'], "term");

        var updateMap = {
            UID: UID,
            description: description,
            term: term,
            status: 'active',
            createdDate: this.getDate(),
            dueDate: dueDate,
        }

        // Set mentorshipSessionID only if available, if unavailable then goal is not associated with session
        if (mentorshipSessionID !== null && mentorshipSessionID !== undefined)
            updateMap['mentorshipSessionID'] = mentorshipSessionID;

        return this.fs.collection('actionItem').add({ ...updateMap });
    };

    // GET actionItem object
    doGetActionItem = (actionItemDocID) => {
        return this.fs.collection('actionItem').doc(actionItemDocID).get();
    };

    // GET actionItem array by UID
    doGetActionItemByUID = (UID, status) => {
        // Validate field
        this.validateValue(status, ["active", "completed", "archived"], "status");

        return this.fs.collection('actionItem').where('UID', '==', UID)
            .where('status', '==', status)
            .orderBy('createdDate', 'desc')
            .orderBy('dueDate', 'desc').get();
    };

    // get all goalItems associated with the mentorshipSessionID
    doGetActionItemsByMentorshipSessionID = (mentorshipSessionID) => {
        return this.fs.collection('actionItem').where('mentorshipSessionID', '==', mentorshipSessionID).get();
    }

    // UPDATE actionItem object
    doUpdateActionItem = (actionItemDocID,
        description,
        dueDate,
        completedDate) => {
        return this.fs.collection('actionItem').doc(actionItemDocID).update({
            description: description,
            dueDate: dueDate,
            completedDate: completedDate
        });
    };


    // update the actionItem status
    doUpdateActionItemStatus = (actionItemDocID,
        status) => {
        this.validateValue(status, ['active', 'completed', 'archived'], "status");
        var updateMap = { status: status };

        if (status === "completed") {
            updateMap['completedDate'] = this.getDate(); // set the completed date
        } else if (status === "active") {
            updateMap['completedDate'] = firebase.firestore.FieldValue.delete(); // remove completed date if turned back to active
        }

        return this.fs.collection('actionItem').doc(actionItemDocID).update({ ...updateMap });
    };


    // REMOVE actionItem object
    doDeleteActionItem = (actionItemDocID) => {
        return this.fs.collection('actionItem').doc(actionItemDocID).delete();
    };



    // *********************************************** //
    // ******** Firestore - Organizations API ******** //
    // *********************************************** //

    userOrganizations = uid =>
        this.fs.collection('organizations')
            .where("uid", "==", uid)
            .where("archived", "==", false);

    // ********************************************** //
    // ********* Merge Auth and DB User API ********* //
    // ********************************************** //

    onAuthUserListener = (next, fallback) =>
        this.auth.onAuthStateChanged(authUser => {
            if (authUser) {
                new Promise(resolve => setTimeout(resolve, 500)); // set a delay for newly created users to be created in db
                this.doGetUserAccount(authUser.uid)
                    .then(userDoc => {
                        var dbUser = userDoc.data();

                        // default empty roles
                        if (dbUser.role === undefined) {
                            dbUser.role = '';
                        }

                        // merge auth and db user
                        authUser = {
                            uid: authUser.uid,
                            email: authUser.email,
                            ...dbUser,
                        };

                        next(authUser);
                    });
            } else {
                fallback();
            }
        });


    // **************************************************** //
    // ******** EXAMPLE :::: Firestore DB USER API ******** //
    // **************************************************** //

    user = uid => this.db.ref(`users/${uid}`);
    users = () => this.db.ref('users');
}


export default Firebase;
export { FirebaseContext, withFirebase };