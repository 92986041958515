import React, {Component} from 'react';
import {withFirebase} from '../Firebase/Firebase';

import {RenderAllOrgCards} from './Organization/Organization';

const INITIAL_STATE = {
    loading: true,
    organizationDocs: null,
    error: null
}

class ProjectsSummaryBase extends Component {
    constructor(props) {
        super(props);
        // uid = this.props.uid
        // email = this.props.email
        // didLoad

        this.state = INITIAL_STATE;
    }

    render() {
        return (
            <>
                <RenderAllOrgCards uid={this.props.uid} email={this.props.email} didLoad={this.props.didLoad} />
                {this.state.error && <p>{this.state.error.message}</p>}
            </>
        );
    }
}

const ProjectsSummary = withFirebase(ProjectsSummaryBase);

export default ProjectsSummary;