import React, {Component} from 'react';
import {withFirebase} from '../../Firebase/Firebase';
import moment from 'moment';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import {RenderAllProjectCards, ProjectAdd} from '../Project/Project';

import {
    Form, 
    FormGroup, 
    Label, 
    Input, 
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Card,
    CardHeader,
    CardBody,
    Collapse,
    FormFeedback,
    Button,
    UncontrolledTooltip
} from 'reactstrap';


/*********************************************************************************/
/***************************** Render All Org Card *******************************/
/*********************************************************************************/

const INITIAL_STATE_RENDER = {
    loading: true,
    rendering: true,
    positionDocs: null,
    error: null,
    numComponentsToRender: 0,
    componentsRendered: 0,
}

class RenderAllPositionCardsBase extends Component {
    constructor(props) {
        super(props);
        this.loadData = this.loadData.bind(this);
        this.childrenDidLoad = this.childrenDidLoad.bind(this);

        // props.uid
        // props.orgDocID
        // props.email
        // props.didLoad
        this.state = {...INITIAL_STATE_RENDER};
    }

        // Helper function to parse the return from firestore
        mapData = resultArray => {
            const returnArray = [];
            resultArray.forEach(doc => {
                returnArray.push({
                    id: doc.id,
                    data: doc.data(),
                });
            })
            return returnArray;
        };
    
        loadData = () => {
            this.setState({ loading: true });
    
            this.props.firebase.doGetPositionEntries(this.props.orgDocID)
            .then(QuerySnapshots => {
                var returnArray = [];
                QuerySnapshots.forEach(DocumentSnapshot => returnArray.push(DocumentSnapshot));
                this.setState({ positionDocs: this.mapData(returnArray), loading: false, numComponentsToRender: returnArray.length },
                    () => { this.isRenderComplete(); });
            }).catch(error => {
                console.log(error);
                this.setState({error});
            }); 
        }

        isRenderComplete() {
            var isRenderCompleted = !this.state.loading;
            isRenderCompleted &= this.state.componentsRendered >= this.state.numComponentsToRender;
            this.setState({ rendering: !isRenderCompleted });
            if (isRenderCompleted && this.props.didLoad !== undefined) {
                this.props.didLoad();
            }
        }
    
        childrenDidLoad() {
            var { componentsRendered } = this.state;
            componentsRendered++;
            this.setState( { componentsRendered: componentsRendered } 
                            ,() => { this.isRenderComplete();  })
        }    
    
        // Retrieve organization query snapshot
        componentDidMount() {
            this.loadData();
        }

        render() {
            if (this.state && !this.state.loading) {
    
                return (
                    <>
                        { this.state.positionDocs.map(doc => (<PositionCard key={"position_card_" + doc.id} 
                                                                            positionDocID={doc.id} 
                                                                            positionDoc={doc.data} 
                                                                            uid={this.props.uid} 
                                                                            email={this.props.email}
                                                                            refreshPage={this.loadData}
                                                                            didLoad={this.childrenDidLoad} />)) }
                        {this.state.error && <p>{this.state.error.message}</p>}
                    </>
                );
            }
    
            return "loading position records...";
        }

}


/*********************************************************************************/
/******************************* position Card *******************************/
/*********************************************************************************/

const INITIAL_STATE_POSITION_CARD = {
    collapse: true,
    error: null,
};

class PositionCardBase extends Component {
    constructor(props) {
        super(props);
        
        // props.refreshPage
        // props.positionDocID
        // props.positionDoc
        // props.uid
        // props.email
        // props.didLoad
        /* Required Fields:
            title
            description
            startDate
            endDate
        */
        this.state = {...INITIAL_STATE_POSITION_CARD};
    }

    // Toggle the visibility of the card body
    toggleCollapse = () => {
        this.setState(state => ({ collapse: !state.collapse }));
    }

    render() {
        
        return (
            <>
                <Card key={this.props.positionDocID}>
                    <CardHeader className="no-top-padding">
                        <Row>
                            <Col xs="10">
                                <h3 className="card-title">{this.props.positionDoc.title}</h3>
                                <h5 className="card-category">{moment(this.props.positionDoc.startDate).format("MMM YYYY")} {this.props.positionDoc.endDate? " to " + moment(this.props.positionDoc.endDate).format("MMM YYYY") : " to Present"}</h5>
                            </Col>
                            <Col xs="2" className="text-right">
                                <ProjectAdd uid={this.props.uid} 
                                            positionDocID={this.props.positionDocID} 
                                            refreshPage={this.props.refreshPage} />

                                <PositionEditModal positionDocID={this.props.positionDocID} 
                                                    positionDoc={this.props.positionDoc} 
                                                    refreshPage={this.props.refreshPage} />

                                <Button color="link"
                                        id={"achievementPositionToggle"+this.props.positionDocID}
                                        type="button" 
                                        className="btn-left-spacing"
                                        onClick={() => this.toggleCollapse()} >
                                    <i className={this.state.collapse? "tim-icons icon-minimal-up": "tim-icons icon-minimal-down" }/>
                                </Button>
                                <UncontrolledTooltip delay={0}
                                                    target={"achievementPositionToggle"+this.props.positionDocID}
                                                    placement="right" >
                                    {this.state.collapse? "Hide Content":"Show Content" }
                                </UncontrolledTooltip>

                            </Col>
                        </Row>
                    </CardHeader>
                    <Collapse isOpen={this.state.collapse}>
                        <CardBody className="no-top-padding">
                            <RenderAllProjectCards uid={this.props.uid}
                                                email={this.props.email}
                                                positionDocID={this.props.positionDocID}
                                                didLoad={this.props.didLoad} />
                        </CardBody>
                    </Collapse>
                </Card>
            </>
        )
    }
}


/*********************************************************************************/
/******************************* position Edit Modal *************************/
/*********************************************************************************/

const INITIAL_STATE_EDIT_POSITION = {
    changed: false,
    validToSubmit: false,

    isValidTitle: '',
    isValidStartDate: '',
    isValidEndDate: '',

    title: '',
    description: '',
    startDate: '',
    endDate: '',
    editPositionanizationModal: false,
    confirmationModal: null,
    error: null,
};

class PositionEditModalBase extends Component {
    constructor(props) {
        super(props);
        
        //props.refreshPage
        //positionDocID={this.props.positionDocID} 
        //positionDoc={this.props.positionDoc} 
        /* Required Fields:
            title
            description
            startDate
            endDate
        */

        INITIAL_STATE_EDIT_POSITION.title = props.positionDoc.title;
        INITIAL_STATE_EDIT_POSITION.description = props.positionDoc.description;
        INITIAL_STATE_EDIT_POSITION.startDate = props.positionDoc.startDate;
        INITIAL_STATE_EDIT_POSITION.endDate =  props.positionDoc.endDate;

        this.state = {...INITIAL_STATE_EDIT_POSITION};
    }

    onChange = event => {
        event.persist();
        
        this.setState({
            [event.target.name]: event.target.value
        }, () => {
            this.setState({validToSubmit: this.validateSubmission(event.target.name)});
            this.isChanged();
        });
    };

    // Validate whether the field value(s) are valid to submit
    validateSubmission = fieldToValidate => {
        var isValid = true; 

        if (fieldToValidate === "title" || fieldToValidate === "all") {
            if (this.state.title === '') { 
                this.setState({isValidTitle: "has-danger"});
                isValid = false;
            } else this.setState({isValidTitle: ""});
        }
        if (fieldToValidate === "startDate" || fieldToValidate === "all") {
            if (this.state.startDate === '') { 
                this.setState({isValidStartDate: "has-danger"});
                isValid = false;
            } else this.setState({isValidStartDate: ""});
        }
        if (fieldToValidate === "endDate" || fieldToValidate === "all") {
            if (this.state.endDate !== '' && this.state.endDate < this.state.startDate) { 
                this.setState({isValidEndDate: "has-danger"});
                isValid = false;
            } else this.setState({isValidEndDate: ""});
        }

        return isValid;
    }

    // Validate whether there are changes to the form
    isChanged() {
        var isChanged = INITIAL_STATE_EDIT_POSITION.title !== this.setState.title
        || INITIAL_STATE_EDIT_POSITION.description !== this.setState.description
        || INITIAL_STATE_EDIT_POSITION.startDate !== this.setState.startDate
        || INITIAL_STATE_EDIT_POSITION.endDate !== this.setStateendDate;

        this.setState({ changed: isChanged });
    }

    // Close button action for main edit modal
    closeEditModal = () => {
        if (this.state.changed) this.setState({confirmationModal: "cancel"});
        else this.setState({...INITIAL_STATE_EDIT_POSITION});
    } 

    // Delete confirmation button action for main edit modal
    deletePositionConfirmation = () => {
        this.setState({confirmationModal: "delete"});
    }

    // Delete position entry after confirmation
    deletePositionEntry = () => {
        this.props.firebase.doDeletePositionEntry(
            this.props.positionDocID
        ).then(() => {
            this.props.refreshPage();
            //this.setState({...INITIAL_STATE_EDIT_POSITION});
        }).catch(error => {
            console.log(error);
            this.setState({error});
        }); 
    }

    // Creates the position entry in 
    updatePositionEntry = () => {
        if (!this.state.changed) {
            this.closeEditModal();
        } else if (this.validateSubmission("all")){
            this.props.firebase.doUpdatePositionEntry(
                this.props.positionDocID, 
                this.state.title,
                this.state.description,
                this.state.startDate,
                this.state.endDate
            ).then(() => {
                this.props.refreshPage();
                //this.setState({...INITIAL_STATE_EDIT_POSITION});
            }).catch(error => {
                console.log(error);
                this.setState({error});
            }); 
        } else this.setState({error: new Error("There may be invalid values or missing required fields.")})
    }

    render() {
        
        return (
            <>
                <Button color="link"
                        id={"achievementEditPosition"+this.props.positionDocID}
                        type="button" 
                        className="btn-left-spacing"
                        onClick={() => this.setState({editPositionanizationModal: true})} >
                    <i className="tim-icons icon-pencil" />
                </Button>
                <UncontrolledTooltip delay={0}
                                    target={"achievementEditPosition"+this.props.positionDocID}
                                    placement="right" >
                    Edit Position
                </UncontrolledTooltip>

                

                <Modal isOpen={this.state.editPositionanizationModal} toggle={() => this.closeEditModal()} size="lg">
                    <ModalHeader tag="h4" toggle={() => this.closeEditModal()}>Edit Position</ModalHeader>
                    <ModalBody>
                        <Form id="position-edit">
                            <FormGroup className={this.state.isValidTitle}>
                                <Label for="editPosition-title">Title</Label>
                                <Input
                                    id="editPosition-title"
                                    className="form-control"
                                    name = "title"
                                    value = {this.state.title}
                                    onChange = {e => this.onChange(e)}
                                    onBlur = {e => this.onChange(e)}
                                    type = "text"
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="editPosition-description">Description</Label>
                                <Input
                                    id="editPosition-description"
                                    className="form-control"
                                    name = "description"
                                    value = {this.state.description}
                                    onChange = {e => this.onChange(e)}
                                    type = "textarea"
                                    required
                                />
                            </FormGroup>
                            <Row>
                                <Col xs="6">
                                    <FormGroup className={this.state.isValidStartDate}>
                                        <Label for="editPosition-startDate">Start Date</Label>
                                        <Input 
                                            type="date" 
                                            name="startDate" 
                                            value = {this.state.startDate}
                                            onChange = {e => this.onChange(e)}
                                            onBlur = {e => this.onChange(e)}
                                            id="editPosition-startDate"
                                            />
                                    </FormGroup>
                                </Col>
                                <Col xs="6">
                                    <FormGroup className={this.state.isValidEndDate}>
                                        <Label for="editPosition-endDate">End Date</Label>
                                        <Input 
                                            type="date" 
                                            name="endDate" 
                                            value = {this.state.endDate}
                                            onChange = {e => this.onChange(e)}
                                            onBlur = {e => this.onChange(e)}
                                            id="editPosition-endDate"
                                            invalid={this.state.isValidEndDate !== ''}
                                            />
                                        <FormFeedback>Cannot be before start date</FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>

                        <Modal isOpen={this.state.confirmationModal !== null} toggle={() => this.setState({confirmationModal: null})} size="sm">
                            <ModalHeader tag="h4">{this.state.confirmationModal === "cancel"? "Cancel": "Delete"} Confirmation</ModalHeader>
                            <ModalBody>
                                <center>
                                    {this.state.confirmationModal === "cancel"? 
                                        <>There are unsaved changes.<br />Are you sure you would like to cancel?</>
                                        : <>Are you sure you would like to delete this position?<br />This change can not be undone.</>}
                                </center>
                            </ModalBody>
                            <ModalFooter>
                                {this.state.confirmationModal === "cancel"? 
                                    ( <i className="tim-icons icon-check-2" onClick={() => this.setState({...INITIAL_STATE_ADD_POSITION})} /> )
                                    :( <i className="tim-icons icon-check-2" onClick={() => this.deletePositionEntry()} /> )}
                                    <i className="tim-icons icon-simple-remove" onClick={() => this.setState({confirmationModal: null})} />
                            </ModalFooter>
                        </Modal>
                        
                        {this.state.error && <p>{this.state.error.message}</p>}
                    </ModalBody>
                    <ModalFooter>
                        <i className="tim-icons icon-trash-simple" onClick={() => this.deletePositionConfirmation()}/>
                        <i className="tim-icons icon-check-2" onClick={() => this.updatePositionEntry()}/>
                        <i className="tim-icons icon-simple-remove" onClick={() => this.closeEditModal()}/>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}

/*********************************************************************************/
/****************************** Add position Modal ***************************/
/*********************************************************************************/

const INITIAL_STATE_ADD_POSITION = {
    addPositionModal: false,
    changed: false,

    validToSubmit: false,
    isValidTitle: '',
    isValidStartDate: '',
    isValidEndDate: '',

    title: '',
    description: '',
    startDate: '',
    endDate: '',
    confirmationModal: false,
    error: null,
};

class PositionAddModalBase extends Component {
    constructor(props) {
        super(props);
        
        //props.refreshPage
        //uid={this.props.uid}
        //orgDocID={this.props.orgDocID}

        this.state = {...INITIAL_STATE_ADD_POSITION};
    }

    // On change of the fields, update the state
    onChange = event => {
        event.persist();
        
        this.setState({
            [event.target.name]: event.target.value
        }, () => {
            this.setState({validToSubmit: this.validateSubmission(event.target.name)});
            this.isChanged();
        });
    };

    // Validate whether the field value(s) are valid to submit
    validateSubmission = fieldToValidate => {
        var isValid = true; 

        if (fieldToValidate === "title" || fieldToValidate === "all") {
            if (this.state.title === '') { 
                this.setState({isValidTitle: "has-danger"});
                isValid = false;
            } else this.setState({isValidTitle: ""});
        }
        if (fieldToValidate === "startDate" || fieldToValidate === "all") {
            if (this.state.startDate === '') { 
                this.setState({isValidStartDate: "has-danger"});
                isValid = false;
            } else this.setState({isValidStartDate: ""});
        }
        if (fieldToValidate === "endDate" || fieldToValidate === "all") {
            if (this.state.endDate !== '' && this.state.endDate < this.state.startDate) { 
                this.setState({isValidEndDate: "has-danger"});
                isValid = false;
            } else this.setState({isValidEndDate: ""});
        }

        return isValid;
    }

    // Validate whether there are changes to the form
    isChanged() {
        var isChanged = INITIAL_STATE_ADD_POSITION.title !== this.setState.title
        || INITIAL_STATE_ADD_POSITION.description !== this.setState.description
        || INITIAL_STATE_ADD_POSITION.startDate !== this.setState.startDate
        || INITIAL_STATE_ADD_POSITION.endDate !== this.setStateendDate;

        this.setState({ changed: isChanged });
    }

    // Close button action for main feedback modal
    closeCreateModal = () => {
        if (this.state.changed) this.setState({confirmationModal: true});
        else this.setState({...INITIAL_STATE_ADD_POSITION});
    } 

    // Creates the position entry in 
    createPositionEntry = () => {
        if (this.validateSubmission("all")) {
            this.props.firebase.doAddPositionEntry(
                this.props.uid, 
                this.props.orgDocID,
                this.state.title, 
                this.state.description,
                this.state.startDate,
                this.state.endDate
            ).then(() => {
                this.props.refreshPage();
                //this.setState({...INITIAL_STATE_ADD_POSITION});
            }).catch(error => {
                console.log(error);
                this.setState({error});
            }); 
        } else this.setState({error: new Error("There may be invalid values or missing required fields.")})
    }

    render() {
        
        return (
            <>
                <Button color="link"
                        id={"addPosition"+this.props.orgDocID}
                        type="button" 
                        className="btn-left-spacing"
                        onClick={() => this.setState({addPositionModal: true})} >
                    <i className="tim-icons icon-simple-add" />
                </Button>
                <UncontrolledTooltip delay={0}
                                    target={"addPosition"+this.props.orgDocID}
                                    placement="right" >
                    Add Position
                </UncontrolledTooltip>
                
                

                <Modal isOpen={this.state.addPositionModal} toggle={() => this.closeCreateModal()} size="lg">
                    <ModalHeader tag="h4" toggle={() => this.closeCreateModal()}>Add position</ModalHeader>
                    <ModalBody>
                        <Form id="position-addNew">
                            <FormGroup className={this.state.isValidTitle}>
                                <Label for="addPosition-title">Position</Label>
                                <Input
                                    id="addPosition-title"
                                    className="form-control"
                                    name = "title"
                                    value = {this.state.title}
                                    onChange = {e => this.onChange(e)}
                                    onBlur = {e => this.onChange(e)}
                                    type = "text"
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="addPosition-description">Description</Label>
                                <Input
                                    id="addPosition-description"
                                    className="form-control"
                                    name = "description"
                                    value = {this.state.description}
                                    onChange = {e => this.onChange(e)}
                                    type = "textarea"
                                />
                            </FormGroup>
                            <Row>
                                <Col xs="6">
                                    <FormGroup className={this.state.isValidStartDate}>
                                        <Label for="addPosition-startDate">Start Date</Label>
                                        <Input 
                                            type="date" 
                                            name="startDate" 
                                            value = {this.state.startDate}
                                            onChange = {e => this.onChange(e)}
                                            onBlur = {e => this.onChange(e)}
                                            id="addPosition-startDate"
                                            />
                                    </FormGroup>
                                </Col>
                                <Col xs="6">
                                    <FormGroup className={this.state.isValidEndDate}>
                                        <Label for="addPosition-endDate">End Date (or expected)</Label>
                                        <Input 
                                            type="date" 
                                            name="endDate" 
                                            value = {this.state.endDate}
                                            onChange = {e => this.onChange(e)}
                                            onBlur = {e => this.onChange(e)}
                                            id="addPosition-endDate"
                                            invalid={this.state.isValidEndDate !== ''}
                                            />
                                        <FormFeedback>Cannot be before start date</FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>

                        <Modal isOpen={this.state.confirmationModal} size="sm" toggle={() => this.setState({confirmationModal: false})}>
                            <ModalHeader tag="h4">Cancel Confirmation</ModalHeader>
                            <ModalBody>
                                <center>There are unsaved changes.<br />Are you sure you would like to cancel?</center>
                            </ModalBody>
                            <ModalFooter>
                                <i className="tim-icons icon-check-2" onClick={() => this.setState({...INITIAL_STATE_ADD_POSITION})} />
                                <i className="tim-icons icon-simple-remove" onClick={() => this.setState({confirmationModal: false})} />
                            </ModalFooter>
                        </Modal>
                        
                        {this.state.error && <p>{this.state.error.message}</p>}
                    </ModalBody>
                    <ModalFooter>
                        <i className="tim-icons icon-simple-add" onClick={() => this.createPositionEntry()} />
                        <i className="tim-icons icon-simple-remove" onClick={() => this.closeCreateModal()} />
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}


/************** Exports *****************/
const PositionAdd = withFirebase(PositionAddModalBase);
const PositionCard = withFirebase(PositionCardBase);
const PositionEditModal = withFirebase(PositionEditModalBase);
const RenderAllPositionCards = withFirebase(RenderAllPositionCardsBase);

export {RenderAllPositionCards, PositionCard, PositionAdd};