import HomeView from "../views/Home/Home";
import EducationView from "../views/Education/Education";
//import ProjectView from "../views/Project/Project";
import AchievementsView from "../views/Achievements/Achievements"
import PerformanceView from "../views/Performance/Performance"
//import FeedbackView from "../views/Feedback/Feedback";
//import ReviewView from "../views/Review/Review";
import MentorshipView from "../views/Mentorship/Mentorship"
import AccountView from "../views/Account/Account";
import SupportView from "../views/Support/Support";
import AdminView from "../views/Admin/Admin";

import * as ROLES from "../constants/roles";

// routes common for all users
var routesCommon = [
  {
    path: "/home",
    name: "Home",
    component: HomeView,
    icon: "tim-icons icon-chart-pie-36",
  }
]

// routes common for all 
var routesNonAdmin = [
  {
    path: "/account",
    name: "Account",
    component: AccountView,
    icon: "tim-icons icon-settings",
  },
  {
    path: "/support",
    name: "Support",
    component: SupportView,
    icon: "tim-icons icon-support-17",
  }
]

// routes for admin
var routesAdmin = [
  {
    path: "/admin",
    name: "Admin",
    component: AdminView,
    icon: "tim-icons icon-support-17",
  }
]

// routes that are accessible by the user type: individual
var routesIndividual = [
  {
    path: "/achievements",
    name: "Achievements",
    component: AchievementsView,
    icon: "tim-icons icon-spaceship",
  },
  {
    path: "/performance",
    name: "Performance",
    component: PerformanceView,
    icon: "tim-icons icon-notes",
  },
  {
    path: "/mentorship",
    name: "Mentorship",
    component: MentorshipView,
    icon: "tim-icons icon-user-run",
  },
  {
    path: "/qualifications",
    name: "Qualifications",
    component: EducationView,
    icon: "tim-icons icon-book-bookmark",
  }
]

// full map of compiled routes
var dashboardRoutesMap = { 
  [ROLES.ADMIN]: [
    ...routesCommon,
    ...routesAdmin
  ],
  [ROLES.IND]: [
    ...routesCommon,
    ...routesIndividual,
    ...routesNonAdmin
  ],
  [ROLES.ORG]: [
    ...routesCommon,
    ...routesNonAdmin
  ],
  [ROLES.EDU]: [
    ...routesCommon,
    ...routesNonAdmin
  ]
};

// full list of ALL routes for switch
var dashboardRoutesFull = [ 
    ...routesCommon,
    ...routesAdmin,
    ...routesNonAdmin,
    ...routesIndividual,
];


export default dashboardRoutesMap;
export {dashboardRoutesFull};
