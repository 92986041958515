import React, {Component} from 'react';

class LoadingDiv extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state={
    //        loading: false,
    //     }
    // }

    render() {
        return (
            <div id="loading">
                    <div className="lds-grid"><div></div><div></div><div></div></div>
            </ div>
        );
    };
};

export default LoadingDiv;