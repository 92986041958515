import React, {Component} from 'react';

import {
  Jumbotron,
  Container
} from "reactstrap";

class SupportView extends Component {
  render() {
    
    return (
      <div className="content">
        <Jumbotron>
          <Container>
            <h1 className="display-3">Under Construction</h1>
            <p className="lead">This feature is coming soon!</p>
          </Container>
        </Jumbotron>
      </div>
    );
  }
};

export default SupportView;