import React, {Component} from 'react';
import {AuthUserContext, withAuthorization} from '../../Components/Session/Session';
import {
  Row,
  Col
} from "reactstrap";

import LoadingDiv from '../../Components/Misc/Loading'
import ProjectsSummary from '../../Components/Projects/Projects';

const INITIAL_STATE = {
  rendering: false,
  childrenToLoad: {
    ProjectsSummary: false
  },
};

class AchievementsView extends Component {
  constructor (props) {
      super(props);
      this.childrenDidLoad = this.childrenDidLoad.bind(this)
      this.state = {...INITIAL_STATE};
  };

  isRenderComplete() {
    var isRenderCompleted = true;
    for (var key in this.state.childrenToLoad) {
        isRenderCompleted &= this.state.childrenToLoad[key];
        //console.log(key + " : " + this.state.childrenToLoad[key]);
    }
    this.setState({ rendering: !isRenderCompleted });
    if (isRenderCompleted && this.props.didLoad !== undefined) {
      this.props.didLoad();
    }
  }

  childrenDidLoad(component) {
      var { childrenToLoad } = this.state;
      childrenToLoad[component] = true;
      this.setState( { childrenToLoad: childrenToLoad } 
                      ,() => { this.isRenderComplete();  })
  }

  render () {
    return (
      <div className="content">
        <Row>
          <Col md="12">
            <AuthUserContext.Consumer>
              {authUser => (
                <>
                  <LoadingDiv />
                  <ProjectsSummary uid={authUser.uid} email={authUser.email} didLoad={() => this.childrenDidLoad("ProjectsSummary")}/>
                </>
              )}
            </AuthUserContext.Consumer>
          </Col>
        </Row>
      </div>
    );
  };
};

const condition = authUser => !!authUser;

export default withAuthorization(condition)(AchievementsView);