import React from "react";
//import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import {withAuthentication} from './Components/Session/Session';

import SignInPage from './views/SignIn/SignIn';
import DashboardLayout from "./layouts/Dashboard";
import StandaloneFeedbackLayout from "./layouts/StandaloneFeedback"

class MainRoutes extends React.Component {
    // constructor(props){
    //   super(props);
    // }

    render() {
        return (
            <Router history={this.props.hist}>
                <Switch>
                    <Redirect exact from="/" to="/home" />
                    <Route path="/feedback/:docid" render={props => <StandaloneFeedbackLayout {...props} />} />
                    <Route path="/signin" render={props => <SignInPage {...props} />} />
                    <Route path="/:sub" render={props => <DashboardLayout {...props} />} />
                </Switch>
            </Router>
        )
    }
}

// <Route path="/dashboard/:sub" component={DashboardLayout} />

export default withAuthentication(MainRoutes);