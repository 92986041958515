import React, {Component} from 'react';
import {withAuthorization} from '../../Components/Session/Session';
import * as ROLES from '../../constants/roles';

import {
  Row,
  Col,
  Jumbotron,
  Container,
} from "reactstrap";


const INITIAL_STATE = {

};

class AdminView extends Component {
  constructor (props) {
      super(props);

      this.state = {...INITIAL_STATE};
  };

  render () {
    return (
      <div className="content">
        <Row>
          <Col md="12">
            <Jumbotron>
              <Container>
                <h1 className="display-3">Admin Page</h1>
                <p className="lead">Under Construction</p>
              </Container>
            </Jumbotron>
          </Col>
        </Row>
      </div>
    );
  };
};

const condition = authUser =>
  !!authUser && authUser.role === ROLES.ADMIN;

export default withAuthorization(condition)(AdminView);