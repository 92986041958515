import React, {Component} from 'react';
import SignInForm from '../../Components/SignIn/SignIn'
import SignUpForm from '../../Components/SignUp/SignUp'
import DashTopNav from "../../Components/Navigation/DashTopNav";
import PasswordForgetForm from '../../Components/PasswordForget/PasswordForgot'

import {AuthUserContext} from '../../Components/Session/Session';

import {withRouter} from 'react-router-dom';
import {withFirebase} from '../../Components/Firebase/Firebase';

import * as ROUTES from '../../constants/routes';

import {
    Jumbotron,
    Container,
    Collapse,
} from 'reactstrap';

import './signin.css';

class SignInPageBase extends Component {
    constructor(props){
        super(props);
    
        this.state = {
        backgroundColor: "blue",
        whatsOpen: "login",
        };
    
        this.activateLightMode();
    }

    // light or dark mode
    activateLightMode = () => {
        document.body.classList.add("white-content");
    };
    

    toggleLogin = () => {
        this.setState(state => ({ whatsOpen: "login" }));
    }
    
    toggleSignup = () => {
        this.setState(state => ({ whatsOpen: "signup" }));
    }

    toggleForgotPassword = () => {
        this.setState(state => ({ whatsOpen: "pwdreset" }));
    }

    render() {
        return (
            <>
                <AuthUserContext.Consumer>
                            {authUser => {
                                if (!!authUser) {
                                    this.props.history.push(ROUTES.DASHBOARD);
                                }
                            }}
                </AuthUserContext.Consumer>

                <div className="wrapper">
                    <div
                        className="main-panel"
                        ref="mainPanel"
                        data={this.state.backgroundColor}
                    >
                        <DashTopNav
                        {...this.props}
                        brandText="CMD"
                        sidebarExists={false}
                        />

                        <div className="content-wide">
                            <Jumbotron>
                                <Container>
                                    <center>           
                                        <Collapse isOpen={this.state.whatsOpen === "login"}>
                                            <SignInForm /><br/>
                                            <p onClick={this.toggleForgotPassword}>Forgot your password?</p>
                                            <i>- or -</i><br/>
                                            <p onClick={this.toggleSignup}>Don't have an account?</p>
                                        </Collapse>
                                                                
                                        <Collapse isOpen={this.state.whatsOpen === "signup"}>
                                            <SignUpForm /><br/>
                                            <p onClick={this.toggleLogin}>Already have an account?</p>
                                        </Collapse>

                                        <Collapse isOpen={this.state.whatsOpen === "pwdreset"}>
                                            <PasswordForgetForm /><br/>
                                            <p onClick={this.toggleLogin}>Retry login?</p>
                                        </Collapse>
                                    </center>
                                </Container>
                            </Jumbotron>
                        </div>
                    </div>
                </div>
            </>
        )
    }
};

const SignInPage = withRouter(withFirebase(SignInPageBase))

export default SignInPage;