import React, {Component} from 'react';
import {withFirebase} from '../../Firebase/Firebase';

import {
    Form, 
    FormGroup, 
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    UncontrolledTooltip,
    Button
} from 'reactstrap';


/*********************************************************************************/
/****************************** Add Achievement Modal ****************************/
/*********************************************************************************/

const INITIAL_STATE_ADD_ACHIEVEMENT = {
    addAchievementModal: false,
    changed: false,
    validToSubmit: false,
    isValidAchievementEntry: '',
    modalHeader: 'Add Achievement',
    icon: 'tim-icons icon-simple-add',
    uniqueButtonID: '',
    loading: true,

    achievementEntry: '',
    confirmationModal: false,
    error: null,
};

class AchievementAddBase extends Component {
    constructor(props) {
        super(props);
        //props.uid
        //props.projectDocID
        //props.goalDocID
        //props.goalDescription
        //props.key
        //props.onSubmit

        this.state = {...INITIAL_STATE_ADD_ACHIEVEMENT};
    }

    componentDidMount () {
        this.setAchievementAddDefaults(); // set defaults
    }

    setAchievementAddDefaults () {
        let uniqueButtonTemp = this.props.uid;
        if (this.props.projectDocID !== undefined) uniqueButtonTemp += this.props.projectDocID;
        if (this.props.goalDocID !== undefined) {
            uniqueButtonTemp += this.props.goalDocID;
            this.setState({
                modalHeader: 'Convert Goal To Achievement',
                icon: 'tim-icons icon-spaceship',
                achievementEntry: this.props.goalDescription
            })                  
        }

        this.setState({
            uniqueButtonID: uniqueButtonTemp,
            loading: false
        })    
    }

    // On change of the fields, update the state
    onChange = event => {
        event.persist();
        
        this.setState({
            [event.target.name]: event.target.value
        }, () => {
            this.setState({validToSubmit: this.validateSubmission(event.target.name)});
            this.isChanged();
        });
    };

    // Validate whether the field value(s) are valid to submit
    validateSubmission = fieldToValidate => {
        var isValid = true; 

        if (fieldToValidate === "achievementEntry" || fieldToValidate === "all") {
            if (this.state.achievementEntry === '') { 
                this.setState({isValidAchievementEntry: "has-danger"});
                isValid = false;
            } else this.setState({isValidAchievementEntry: ""});
        }

        return isValid;
    }

    // Validate whether there are changes to the form
    isChanged() {
        var isChanged = this.props.achievementEntry !== this.setState.achievementEntry;
        this.setState({ changed: isChanged });
    }

    // Close button action for main feedback modal
    closeCreateModal() {
        if (this.state.changed) this.setState({confirmationModal: true});
        else this.setState({...INITIAL_STATE_ADD_ACHIEVEMENT});
    } 

    createAchievementEntry() {
        this.props.firebase.doAddAchievement(this.props.uid,
                                            this.state.achievementEntry,
                                            this.props.projectDocID,
                                            this.props.goalDocID
        ).then(ref => {
            if (this.props.onSubmit !== undefined || this.props.onSubmit !== null) this.props.onSubmit(ref.id); // onSubmit must accept achivementDocID
            this.setState({...INITIAL_STATE_ADD_ACHIEVEMENT});
        }).catch(error => {
            console.log(error);
            this.setState({error});
        }); 
    }

    render() {
        if (this.state && !this.state.loading) {
            return (
                <>
                    <Button color="link"
                            id={"addAchievement"+this.state.uniqueButtonID}
                            title=""
                            type="button" 
                            className="btn-no-spacing"
                            onClick={() => this.setState({addAchievementModal: true})}>
                        <p className="text-muted"><i className={this.state.icon} /></p>
                        {this.props.buttonText? " "+this.props.buttonText : null }
                    </Button>
                    <UncontrolledTooltip delay={0}
                                        target={"addAchievement"+this.state.uniqueButtonID}
                                        placement="right" >
                        Add Achievement
                    </UncontrolledTooltip>

                    <Modal isOpen={this.state.addAchievementModal} toggle={() => this.closeCreateModal()}>
                        <ModalHeader tag="h4" toggle={() => this.closeCreateModal()}>{this.state.modalHeader}</ModalHeader>
                        <ModalBody>
                            <Form id="Achievement-addNew">
                                {this.props.goalDescription !== undefined && this.props.goalDescription !== null?
                                    <FormGroup>
                                        <Input
                                            id="addAchievement-goalDescription"
                                            className="form-control"
                                            name = "goalDescription"
                                            value = {this.props.goalDescription}
                                            disabled
                                        />
                                    </FormGroup>
                                :null}
                                <FormGroup className={this.state.isValidAchievementEntry}>
                                    <Input
                                        id="addAchievement-achievementEntry"
                                        className="form-control"
                                        name = "achievementEntry"
                                        value = {this.state.achievementEntry}
                                        onChange = {e => this.onChange(e)}
                                        onBlur = {e => this.onChange(e)}
                                        type = "text"
                                        required
                                    />
                                </FormGroup>
                            </Form>

                            <Modal isOpen={this.state.confirmationModal}>
                                <ModalHeader tag="h4">Cancel Confirmation</ModalHeader>
                                <ModalBody>
                                    <center>Are you sure you would like to cancel?</center>
                                </ModalBody>
                                <ModalFooter>
                                    <i className="tim-icons icon-check-2" onClick={() => this.setState({...INITIAL_STATE_ADD_ACHIEVEMENT})}/>
                                    <i className="tim-icons icon-simple-remove" onClick={() => this.setState({confirmationModal: false})}/>
                                </ModalFooter>
                            </Modal>
                            
                            {this.state.error && <p>{this.state.error.message}</p>}
                        </ModalBody>
                        <ModalFooter>
                            <i className="tim-icons icon-simple-add" onClick={() => this.createAchievementEntry()}/>
                            <i className="tim-icons icon-simple-remove" onClick={() => this.closeCreateModal()}/>
                        </ModalFooter>
                    </Modal>
                </>
            )
        } else return null // still loading
    }
}

/************** Exports *****************/
const AchievementAddModal = withFirebase(AchievementAddBase);

export default AchievementAddModal;