import React, {Component} from 'react';
import {
    Button, 
    UncontrolledTooltip
} from 'reactstrap';


/***************************************************************************************/
/*************************** Buttons - Action Icon with ToolTip ************************/
/***************************************************************************************/

class ActionIcon extends Component {
    // constructor(props) {
    //     super(props);
    // //props.id
    // //props.iconClassName
    // //props.iconText // optional
    // //props.toolTipText // optional, if included, render UncontrolledTooltip
    // //props.onClick; // action
    // }

    render() {
        return (
            <>
                <Button color="link"
                        id={this.props.id}
                        type="button" 
                        className="btn-no-spacing"
                        onClick={() => this.props.onClick()}>
                    <p className="text-muted">
                        <i className={this.props.iconClassName} />
                        {this.props.iconText? " "+this.props.iconText : null }
                    </p>
                </Button>
                {this.props.toolTipText?
                    <UncontrolledTooltip delay={0}
                                        target={this.props.id}
                                        placement="right" >
                        {this.props.toolTipText}
                    </UncontrolledTooltip>
                : null }
            </>
        )
    }
}

export {ActionIcon}