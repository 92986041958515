import React, {Component} from 'react';
import {withFirebase} from '../../Firebase/Firebase';

import {
    Row,
    Col,
    Form, 
    FormGroup, 
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    UncontrolledTooltip,
    Button,
} from 'reactstrap';



/*********************************************************************************/
/******************************* Achievement Item Component *************************/
/*********************************************************************************/

const INITIAL_STATE_EDIT_ACHIEVEMENT = {
    loading: true,
    changed: false,
    validToSubmit: false,
    isValidAchievementEntry: '',

    achievementEntry: null,
    originalAchievementEntry: null,
    editAchievementModal: false,
    confirmationModal: null,
    error: null,
};

class AchievementItemBase extends Component {
    constructor(props) {
        super(props);
        
        // props.achievementDocID // mandatory
        // props.achievementData // optional (must contain .description)
        // props.index
        // props.onDelete = deleteAchievementEntry
        // props.didLoad
        this.state={...INITIAL_STATE_EDIT_ACHIEVEMENT}
    }

    // Loads data from Firebase and sets loading state to false
    loadData () {
        this.setState({ loading: true });

        if (this.props.achievementData === null || this.props.achievementData === undefined) {
            // load achievement from firebase if achivementDoc no provided
            this.props.firebase.doGetAchievementByID(this.props.achievementDocID)
            .then(doc => {
                this.setState({achievementEntry: doc.data().description,
                                originalAchievementEntry: doc.data().description});
            })
            .then(() => {
                this.setState({ loading: false });
                if (this.props.didLoad !== undefined) this.props.didLoad();
            });
        } else {
            this.setState({achievementEntry: this.props.achievementData.description,
                                originalAchievementEntry: this.props.achievementData.description});
            this.setState({ loading: false });
            if (this.props.didLoad !== undefined) this.props.didLoad();
        }
    }

    reloadOriginalValues () {
        let oAE_temp = this.state.originalAchievementEntry; // store this value to prevent retrieval again from firestore
        this.setState({...INITIAL_STATE_EDIT_ACHIEVEMENT});
        this.setState({achievementEntry: oAE_temp,
                        originalAchievementEntry: oAE_temp,
                        loading: false});
    }

    // Load achievement data from database
    componentDidMount() {
        this.loadData();
    }

    onChange = event => {
        event.persist();
        
        this.setState({
            [event.target.name]: event.target.value
        }, () => {
            this.setState({validToSubmit: this.validateSubmission(event.target.name)});
            this.isChanged();
        });
    };

    // Validate whether the field value(s) are valid to submit
    validateSubmission = fieldToValidate => {
        var isValid = true; 

        if (fieldToValidate === "achievementEntry" || fieldToValidate === "all") {
            if (this.state.achievementEntry === '') { 
                this.setState({isValidAchievementEntry: "has-danger"});
                isValid = false;
            } else this.setState({isValidAchievementEntry: ""});
        }

        return isValid;
    }

    // Validate whether there are changes to the form
    isChanged() {
        var isChanged = this.state.originalAchievementEntry !== this.state.achievementEntry;
        this.setState({ changed: isChanged });
    }

    // Close button action for main edit modal
    closeEditModal = () => {
        if (this.state.changed) this.setState({confirmationModal: "cancel"});
        else this.reloadOriginalValues();
    } 

    // close the cancel confirmation modal
    confirmCancelModal () {
        this.reloadOriginalValues();
    }

    // closes the cancel/delete confirmation modal
    closeConfirmationModal() {
        this.setState({confirmationModal: null});
    }

    // Delete confirmation button action for main edit modal
    deleteAchievementConfirmation = () => {
        this.setState({confirmationModal: "delete"});
    }

    // Update Achievement entry
    updateAchievementEntry = () => {
        if (!this.state.changed) {
            this.closeEditModal();
        } else if (this.validateSubmission("all")) {
            this.props.firebase.doUpdateAchievementDescription(this.props.achievementDocID,
                                                                this.state.achievementEntry
            ).then(() => {
                this.setState({ originalAchievementEntry: this.state.achievementEntry, 
                                editAchievementModal: false});
                this.loadData(); // reload but retrieve from FS to ensure the save was successful
            }).catch(error => {
                console.log(error);
                this.setState({error});
            }); 
        } else this.setState({error: new Error("Please enter an achivement.")})
    }

    // Delete Achievement entry after confirmation
    deleteAchievementEntry() {
        this.props.firebase.doDeleteAchievement(this.props.achievementDocID
        ).then(() => {
            this.props.onDelete(this.props.index)
            this.setState({...INITIAL_STATE_EDIT_ACHIEVEMENT});
        }).catch(error => {
            console.log(error);
            this.setState({error});
        }); 
    }

    render() {
        if (this.state && !this.state.loading) {
            return (
                <>
                    <Row className="list-row">
                        <Col xs="11" className="list-col">
                            <ul className=""><li>{this.state.originalAchievementEntry}</li></ul>
                        </Col>
                        <Col xs="1" className="text-right no-padding list-col">
                            <Button color="link btn-action-icon"
                                    id={"addEditAchievement"+this.props.achievementDocID}
                                    title=""
                                    type="button" 
                                    onClick={() => this.setState({editAchievementModal: true})} >
                                <i className="tim-icons icon-pencil" />
                            </Button>
                            <UncontrolledTooltip delay={0}
                                                target={"addEditAchievement"+this.props.achievementDocID}
                                                placement="right" >
                                Edit Achievement   
                            </UncontrolledTooltip>
                        </Col>
                    </Row>

                    <Modal isOpen={this.state.editAchievementModal} toggle={() => this.closeEditModal()}>
                        <ModalHeader>Edit Achievement</ModalHeader>
                        <ModalBody>
                            <Form id="Achievement-edit">
                                <FormGroup className={this.state.isValidAchievementEntry}>
                                    <Input
                                        id="editAchievement-achievementEntry"
                                        className="form-control"
                                        name = "achievementEntry"
                                        value = {this.state.achievementEntry}
                                        onChange = {e => this.onChange(e)}
                                        onBlur = {e => this.onChange(e)}
                                        type = "textarea"
                                        required
                                    />
                                </FormGroup>
                            </Form>

                            <Modal isOpen={this.state.confirmationModal !== null} toggle={() => this.closeConfirmationModal()}>
                                <ModalHeader>{this.state.confirmationModal === "cancel"? "Cancel": null }{this.state.confirmationModal === "delete"? "Delete": null } Confirmation</ModalHeader>
                                <ModalBody>
                                    <center>
                                        {this.state.confirmationModal === "cancel"? 
                                            <>There are unsaved changes.<br />Are you sure you would like to cancel?</> : null}
                                        {this.state.confirmationModal === "delete"? 
                                            <>Are you sure you would like to delete this achievement?<br/>This change can not be undone.</>: null}
                                    </center>
                                </ModalBody>
                                <ModalFooter>
                                    {this.state.confirmationModal === "cancel"? 
                                        (<i className="tim-icons icon-check-2" onClick={() => this.confirmCancelModal()} />) : null}
                                    {this.state.confirmationModal === "delete"? 
                                        (<i className="tim-icons icon-check-2" onClick={() => this.deleteAchievementEntry()} />) : null}
                                    <i className="tim-icons icon-simple-remove" onClick={() => this.closeConfirmationModal()} />
                                </ModalFooter>
                            </Modal>
                            
                            {this.state.error && <p>{this.state.error.message}</p>}
                        </ModalBody>
                        <ModalFooter>
                            <i className="tim-icons icon-trash-simple" onClick={() => this.deleteAchievementConfirmation()}/>
                            <i className="tim-icons icon-check-2" onClick={() => this.updateAchievementEntry()}/>
                            <i className="tim-icons icon-simple-remove" onClick={() => this.closeEditModal()}/>
                        </ModalFooter>
                    </Modal>
                    {this.state.error && <p>{this.state.error.message}</p>}
                </>
            )
        }

        // While data is being loaded from database
        return "loading...";
    }
}



/************** Exports *****************/
const LineItem = withFirebase(AchievementItemBase);

export default LineItem;