import React, {Component} from 'react';
import {withFirebase} from '../Firebase/Firebase';
import moment from 'moment';
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle
} from 'reactstrap';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import {FeedbackRequest} from './Feedback';


/********************************************************************************/
/******************************* Feedback Summary *******************************/
/********************************************************************************/

const INITIAL_STATE_SUMMARY = {
    loading: true,
    rendering: true,
    changed: false,
    feedbackRequestorDocs: null,
    feedbackModalCurrentDoc: null,
    feedbackModalState: null,

    formVersion: '',
    feedbackGeneral: '',

    confirmationModal: null,
    error: null,
    errorConfirmModal: null,
    errorCancelConfirmModal: null,
    errorViewFeedbackModal: null,
    errorCompleteFeedbackModal: null,
    
    numComponentsToRender: 0,
    componentsRendered: 0,
};

class ProjectFeedbackSummaryBase extends Component {
    constructor(props) {
        super(props);
        this.state = {...INITIAL_STATE_SUMMARY};
        this.loadData = this.loadData.bind(this);

        // props.uid; // UID of user provided by parent component through auth context
        // props.email; // email of user provided by parent component through auth context
        // props.projectDocID
        // props.projectDoc
        // props.didLoad
    }

    // close view feedback modal
    onCloseFeedbackModal = () => {
        this.setState({ feedbackModalCurrentDoc: null });
    }
    
    // Counts how many row items there are for the specific doc parameters
    countFeedbackItems = (doc, docType) => {
        var itemsCount = 0;
        docType.map(status => {
            doc.map(doc => {
                if (doc.status === status) itemsCount++;
                return null;
            })
            return null;
        })
        return itemsCount;
    }

    // renders the card for each of the feedback doc types
    renderFeedbackList = (doc, header, noDocMessage, docType, renderAddButton, renderEmpty) => {
        var numDocs = this.countFeedbackItems(doc, docType);
        if (renderEmpty || numDocs !== 0) {
            return (
                <Card>
                    <CardHeader className="list-header">
                        <Row>
                            <Col xs="10"><CardTitle tag="h5">{header}</CardTitle></Col>
                            { renderAddButton? (
                                <Col xs="2" className="text-right">
                                    <FeedbackRequest action="create"
                                                    uid={this.props.uid} 
                                                    email={this.props.email} 
                                                    refreshPage={this.loadData}
                                                    projectID={this.props.projectDocID} 
                                                    projectName={this.props.projectDoc.name} 
                                                    projectRole={this.props.projectDoc.role} 
                                                    projectSummary={this.props.projectDoc.description}
                                                    renderCompact={true} 
                                                        />
                                </Col>
                                ): null}
                        </Row>
                    </CardHeader>
                    <CardBody className="list-body">
                        { numDocs !== 0? docType.map(status => doc.map(doc => {
                            if (doc.status === status) {
                                return (
                                    <Row className="list-row" key={doc.id}>
                                        <Col xs="10">
                                            <ul className=""><li>{moment(doc.data.startDate).format("MMM Do YYYY")} to {moment(doc.data.endDate).format("MMM Do YYYY")}</li></ul>
                                        </Col>
                                        <Col xs="2" className="text-right no-padding">
                                            <FeedbackRequest
                                                action="view"
                                                doc={doc}
                                            />
                                        </Col>
                                    </Row>
                                )
                            } return null;
                        })):<p className="list-notice">{noDocMessage}</p>}
                    </CardBody>
                </Card>
            )
        }
        return null;
    }


    // Helper function to parse the return from firestore
    mapData = resultArray => {
        const returnArray = [];
        resultArray.forEach(doc => {
            returnArray.push({
                id: doc.id,
                status: doc.data().status,
                data: doc.data(),
            });
        })
        return returnArray;
    };

    // Loads data from Firebase and sets loading state to false
    loadData = () => {
        this.setState({ loading: true });

        var tagsRequestor = ["new", "draft", "completed"];
        var fsPromisesRequestor = [];
        tagsRequestor.map(tag =>
            fsPromisesRequestor.push(this.props.firebase.doGetFeedbackForProject(this.props.projectDocID, tag)));

        Promise.all(fsPromisesRequestor)
        .then(QuerySnapshots => {
            var returnArray = [];
            QuerySnapshots.forEach(QuerySnapshot => 
                QuerySnapshot.forEach(DocumentSnapshot => returnArray.push(DocumentSnapshot)));
            this.setState({ feedbackRequestorDocs: this.mapData(returnArray), loading: false, numComponentsToRender: returnArray.length },
                () => { this.isRenderComplete(); });
        })
        .catch(error => {
            console.log(error);
            this.setState({error});
        }); 
    }
    
    isRenderComplete() {
        var isRenderCompleted = !this.state.loading;
        isRenderCompleted &= this.state.componentsRendered >= this.state.numComponentsToRender;
        this.setState({ rendering: !isRenderCompleted });
        if (isRenderCompleted && this.props.didLoad !== undefined) {
            this.props.didLoad();
        }
    }

    childrenDidLoad() {
        var { componentsRendered } = this.state;
        componentsRendered++;
        this.setState( { componentsRendered: componentsRendered } 
                        ,() => { this.isRenderComplete();  })
    }    

    // Load feedback data from database
    componentDidMount() {
        //console.log(props.uid);
        this.loadData();
    }

    onChange = event => {
        this.setState({changed: true, [event.target.name]: event.target.value});
    };

    render() {
        if (this.state && !this.state.loading) {
            return (
                <>
                    { this.renderFeedbackList( this.state.feedbackRequestorDocs, "Feedback", "No feedback yet!", ["completed"], true, true) }
                    { this.renderFeedbackList( this.state.feedbackRequestorDocs, "Pending Feedback", null,  ["new", "draft"], false, false) }

                    {this.state.error && <p>{this.state.error.message}</p>}
                </>
            );
        } 
        
        // While data is being loaded from database
        return "loading...";
    };
};

const ProjectFeedbackSummary = withFirebase(ProjectFeedbackSummaryBase);

export default ProjectFeedbackSummary;