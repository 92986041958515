import React, {Component} from 'react';
import {withFirebase} from '../Firebase/Firebase';
import {UserLookupFeedback} from '../Search/UserLookup';

import moment from 'moment';
import {
    Button, 
    Form, 
    FormGroup, 
    Label, 
    Input, 
    Row,
    Col,
    Modal, 
    CardTitle,
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    Card, 
    CardHeader,
    CardBody,
    Table,
    Collapse,
    FormFeedback,
    Alert
} from 'reactstrap';
import {ActionIcon} from '../GlobalElements/Button';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';


/**********************************************************************************/
/******************************* Feedback Summary *******************************/
/**********************************************************************************/
/* This renders the various cards for the feedback summary page                   */
/**********************************************************************************/

const INITIAL_STATE_FEEDBACK_SUMMARY = {
    loading: true,
    changed: false,
    error: null,
};

class FeedbackSummaryBase extends Component {
    constructor(props) {
        super(props);
        this.state = {...INITIAL_STATE_FEEDBACK_SUMMARY};
        this.loadData = this.loadData.bind(this);

        // props.uid; // UID of user provided by parent component through auth context
        // props.email; // email of user provided by parent component through auth context
        // props.didLoad;
    }

    onFeedbackSubmitConfirm = status => {
        this.props.firebase.doUpdateFeedbackRequest(
            this.state.feedbackModalCurrentDoc.id,
            status,
            this.state.feedbackGeneral
        ).then (() => {
            this.setState({...INITIAL_STATE_FEEDBACK_SUMMARY});
            this.loadData();
        }).catch(error => {
            this.setState({errorCompleteFeedbackModal: error});
        })
    }

    // close view feedback modal
    onCloseFeedbackModal = () => {
        if (!this.state.changed) {
            this.setState({ feedbackModalCurrentDoc: null });
        } else {
            this.setState({ confirmationModal: "cancel" })
        }
    }

    // Renders each row for the table based on the doc provided
    renderFeedbackTableRow = (doc, status) => {
        if (doc.status === status) {
            return (
                <tr key={doc.id}>
                    <td>{doc.data.projectName}</td>
                    <td>{moment(doc.data.startDate).format("MMM Do YYYY") + "  >>  " + moment(doc.data.endDate).format("MMM Do YYYY")}</td>
                    <td>{doc.data.projectRole}</td>
                    <td>{doc.data.projectHours}</td>
                    <td className="text-center">
                        <FeedbackRequest
                            action="view"
                            doc={doc}
                            uid={this.props.uid} 
                            email={this.props.email} 
                            refreshPage={this.loadData}
                        />
                    </td>
                </tr>
            )
        } 
        return null;
    }

    // Counts how many row items there are for the specific doc parameters
    countFeedbackItems = (doc, docType) => {
        var itemsCount = 0;
        docType.map(status => {
            doc.map(doc => {
                if (doc.status === status) itemsCount++;
                return null;
            })
            return null;
        })
        return itemsCount;
    }

    // renders the card for each of the feedback doc types
    renderFeedbackSummaryCard = (doc, header, docType, incAddButton) => {
        var numDocs = this.countFeedbackItems(doc, docType);

        return (
            <>
                <Card key={"feedback summary "+header.trim()+"_"+docType}>
                    <CardHeader>
                        <CardTitle tag="h4">{header}</CardTitle>
                    </CardHeader>
                    <CardBody>
                        { numDocs !== 0? (
                            <Table className="tablesorter" hover>
                                <thead className="text-primary">
                                    <tr>
                                        <th>Project Name</th>
                                        <th>Date Range</th>
                                        <th>Role</th>
                                        <th>Hours</th>
                                        { docType[0] !== "cancelled"? <th className="text-center">Action</th>: null }
                                    </tr>
                                </thead>
                                <tbody>
                                    { docType.map(value => doc.map(doc => this.renderFeedbackTableRow(doc, value)))}
                                </tbody>
                            </Table>
                        ): (
                            <center><div>Nothing to see here! <i className="tim-icons icon-spaceship" /></div></center>
                        )}
                    </CardBody>
                </Card>
                
                { incAddButton? (
                    <FeedbackRequest 
                        action="create"
                        renderFull={true}
                        uid={this.props.uid} 
                        email={this.props.email} 
                        refreshPage={this.loadData}
                        buttonText="New Feedback Request"
                    />
                ):null}
            </>
        )
    }


    // Helper function to parse the return from firestore
    mapData = resultArray => {
        const returnArray = [];
        resultArray.forEach(doc => {
            returnArray.push({
                id: doc.id,
                status: doc.data().status,
                data: doc.data(),
            });
        })
        return returnArray;
    };

    // Loads data from Firebase and sets loading state to false
    loadData = () => {
        this.setState({ loading: true });

        var tagsRequestor = ["new", "draft", "completed", "cancelled"];
        var fsPromisesRequestor = [];
        tagsRequestor.map(tag =>
            fsPromisesRequestor.push(this.props.firebase.doGetFeedbackForRequestor(this.props.uid, tag)));

        Promise.all(fsPromisesRequestor)
        .then(QuerySnapshots => {
            var returnArray = [];
            QuerySnapshots.forEach(QuerySnapshot => 
                QuerySnapshot.forEach(DocumentSnapshot => returnArray.push(DocumentSnapshot)));
            this.setState({ feedbackRequestorDocs: this.mapData(returnArray), loading: false }
                ,() => {if (this.props.didLoad !== undefined) this.props.didLoad();} );
        }).catch(error => {
            console.log(error);
            this.setState({error});
        }); 
    }
    
    // Load feedback data from database
    componentDidMount() {
        //console.log(props.uid);
        this.loadData();
    }

    onChange = event => {
        this.setState({changed: true, [event.target.name]: event.target.value});
    };

    render() {
        if (this.state && !this.state.loading) {
    
            //const isInvalid = this.state === INITIAL_STATE;

            return (
                <>
                    { this.renderFeedbackSummaryCard( this.state.feedbackRequestorDocs, "Pending Requests", ["new", "draft"], true) }
                    { this.renderFeedbackSummaryCard( this.state.feedbackRequestorDocs, "Completed Requests", ["completed"], false) }
                    { this.renderFeedbackSummaryCard( this.state.feedbackRequestorDocs, "Cancelled Requests", ["cancelled"], false) }
                    {this.state.error && <Alert color="danger">{this.state.error.message}</Alert>}
                </>
            );
        }

        // While data is being loaded from database
        return null;
    };
};



/**********************************************************************************/
/***************** Create/Delete/Remind/View Feeback Request Modal ****************/
/**********************************************************************************/
/* This the modal that pops up on the Feedback summary page for the requestor to  */
/* request feedback and view pending and completed feedback.                      */
/**********************************************************************************/

const INITIAL_STATE_FEEDBACK_REQUEST = {
    loading: true,
    changed: false,
    validToSubmit: false,
    mainModal: false, // true | false
    confirmationModal: null, // submit | cancel

    errorMainModal: null,
    errorConfirmModal: null,

    formVersion: '',
    feedbackGeneral: '',

    isValidProjectName: '',
    isValidProjectRole: '',
    isValidStartDate: '',
    isValidEndDate: '',
    isValidReviewerFirstName: '',
    isValidReviewerLastName: '',
    isValidReviewerRole: '',
    isValidReviewerEmail: '',

    projectID: '',
    projectName: '',
    projectRole: '',
    projectSummary: '',
    reviewerUID: '',
    reviewerFirstName: '',
    reviewerLastName: '',
    reviewerRole: '',
    reviewerEmail: '',
    startDate: '',
    endDate: '',
    projectHours: '',
    reviewerEmailValidated: false,
  
};


class FeedbackRequestBase extends Component {
    constructor(props) {
        super(props);
        this.state = {...INITIAL_STATE_FEEDBACK_REQUEST};

        this.onSubmit = this.onSubmit.bind(this);
        this.onSubmitConfirm = this.onSubmitConfirm.bind(this);
        this.openMainModal = this.openMainModal.bind(this);
        this.closeMainModal = this.closeMainModal.bind(this);
        this.closeConfirmationModal = this.closeConfirmationModal.bind(this);
        this.closeMainModalConfirmation = this.closeMainModalConfirmation.bind(this);
        this.setReviewerDetails = this.setReviewerDetails.bind(this);
        this.resetReviewerDetails = this.resetReviewerDetails.bind(this);

        // props.action // CREATE | VIEW
        // props.refreshPage
        // props.uid; // UID of user provided by parent component through auth context
        // props.email; // email of user provided by parent component through auth context
        // props.buttonText // optional parameter to add text after the + symbol
        // props.renderFull // Renders the full button version

        // FOR Action === VIEW
        // props.doc
        
        // FOR Action === CREATE
        // props.projectID
        // props.projectName
        // props.projectRole
        // props.projectSummary
    }
    
    // setup when the component initially loads
    componentDidMount() {
        this.loadInitialData();
    }

    // reset the initial state
    loadInitialData () {
        this.setState({...INITIAL_STATE_FEEDBACK_REQUEST});

        if (this.props.action.toLowerCase() === "create") {
            if (this.props.projectID) this.setState({ projectID: this.props.projectID });
            if (this.props.projectName) this.setState({ projectName: this.props.projectName });
            if (this.props.projectRole) this.setState({ projectRole: this.props.projectRole }); // if inititated from projects page, should include the role on project
            if (this.props.projectSummary) this.setState({ projectSummary: this.props.projectSummary });
        } else if (this.props.action.toLowerCase() !== "create") {
            this.setState({ projectName: this.props.doc.data.projectName });
            this.setState({ projectRole: this.props.doc.data.projectRole });
            this.setState({ projectSummary: this.props.doc.data.projectSummary });
            this.setState({ reviewerFirstName: this.props.doc.data.reviewerFirstName });
            this.setState({ reviewerLastName: this.props.doc.data.reviewerLastName });
            this.setState({ reviewerRole: this.props.doc.data.reviewerRole });
            this.setState({ reviewerEmail: this.props.doc.data.reviewerEmail });
            this.setState({ startDate: this.props.doc.data.startDate });
            this.setState({ endDate: this.props.doc.data.endDate });
            this.setState({ projectHours: this.props.doc.data.projectHours });
        }
        
        this.setState({ loading: false });
    }

    // set the default values for project details if it is passed into component


    // save state of form when typing
    onChange = event => {
        if (this.props.action.toLowerCase() === "create") {
            this.setState({ [event.target.name]: event.target.value });
            this.isChanged();
        }
    };

    // Validate whether there are changes to the form
    isChanged() {
        let isChanged = this.state.projectName !== ''
                    || this.state.projectRole !== ''
                    || this.state.projectSummary !== ''
                    || this.state.projectHours !== ''
                    || this.state.startDate !== ''
                    || this.state.endDate !== ''
                    || this.state.reviewerEmail !== '';

        this.setState({ changed: isChanged });
    }

    // Validate whether the values of the form are valid
    validateSubmission(fieldToValidate) {
        var isValid = true; 

        if (fieldToValidate === "projectName" || fieldToValidate === "all") {
            if (this.state.projectName === '') { 
                this.setState({isValidProjectName: "has-danger"});
                isValid = false;
            } else this.setState({isValidProjectName: ""});
        }

        if (fieldToValidate === "projectRole" || fieldToValidate === "all") {
            if (this.state.projectRole === '') { 
                this.setState({isValidProjectRole: "has-danger"});
                isValid = false;
            } else this.setState({isValidProjectRole: ""});
        }

        if (fieldToValidate === "startDate" || fieldToValidate === "all") {
            if (this.state.startDate === '') { 
                this.setState({isValidStartDate: "has-danger"});
                isValid = false;
            } else this.setState({isValidStartDate: ""});
        }
        
        if (fieldToValidate === "endDate" || fieldToValidate === "all") {
            if (this.state.endDate === '' || this.state.endDate < this.state.startDate ) { 
                this.setState({isValidEndDate: "has-danger"});
                isValid = false;
            } else this.setState({isValidEndDate: ""});
        }
        
        if (fieldToValidate === "reviewerFirstName" || fieldToValidate === "all") {
            if (this.state.reviewerFirstName === '') { 
                this.setState({isValidReviewerFirstName: "has-danger"});
                isValid = false;
            } else this.setState({isValidReviewerFirstName: ""});
        }
        
        if (fieldToValidate === "reviewerLastName" || fieldToValidate === "all") {
            if (this.state.reviewerLastName === '') { 
                this.setState({isValidReviewerLastName: "has-danger"});
                isValid = false;
            } else this.setState({isValidReviewerLastName: ""});
        }
        
        if (fieldToValidate === "reviewerRole" || fieldToValidate === "all") {
            if (this.state.reviewerRole === '') { 
                this.setState({isValidReviewerRole: "has-danger"});
                isValid = false;
            } else this.setState({isValidReviewerRole: ""});
        }
        
        if (fieldToValidate === "reviewerEmail" || fieldToValidate === "all") {
            if (this.state.reviewerEmail === '') { 
                this.setState({isValidReviewerEmail: "has-danger"});
                isValid = false;
            } else this.setState({isValidReviewerEmail: ""});
        }

        return isValid;
    }

    //opens the main review modal
    openMainModal() {
        this.setState({ mainModal: true })
    }

    //action for the main modal close action. Opens confirmation if there are changes
    closeMainModal() {
        if (this.state.isChanged) this.setState({ confirmationModal: 'cancel'}); // open the confirmation modal
        else {
            this.closeConfirmationModal();
            this.closeMainModalConfirmation();
            this.loadInitialData();
        }
    }

    //closes the main review modal
    closeMainModalConfirmation() {
        // Do something?
        this.setState({ mainModal: false });
    }

    // closes the confirmation modal
    closeConfirmationModal() {
        this.setState({ confirmationModal: null });
    }

    //on submit of main review modal, if action is "create" or "delete" it opens the confirmation modal
    onSubmit(action) {
        // action = create | delete | reminder
        if (action === "create") {
            let isValidToSubmit = this.validateSubmission("all");
            this.setState({ validToSubmit: isValidToSubmit });
            if ( !isValidToSubmit ) this.setState({ errorMainModal: new Error("There may be invalid values or missing required fields.")});
            else this.setState({ confirmationModal: 'create'}); 
        } else if (action === "delete") {
            this.setState({ confirmationModal: 'delete' })
        } else if (action !== "create") {
            this.closeMainModal();
        } else if (action === "reminder") {
            // TODO: trigger a reminder email to be sent
            this.setState({ errorMainModal: "Feature not yet implemented"});
        }
    }

    //when the user confirms they want to submit
    onSubmitConfirm(action) {
        if (action === "create") {
            this.props.firebase.doCreateFeedbackRequest(
                this.state.projectID,
                this.state.projectName,
                this.props.uid,
                this.props.email,
                this.state.reviewerUID,
                this.state.reviewerFirstName,
                this.state.reviewerLastName,
                this.state.reviewerRole,
                this.state.reviewerEmail,
                this.state.projectRole,
                this.state.projectSummary,
                this.state.projectHours,
                this.state.startDate,
                this.state.endDate,
                this.state.formVersion
            ).then (() => {
                this.closeConfirmationModal();
                this.loadInitialData();
                this.props.refreshPage();
            }).catch(error => {
                this.setState({errorConfirmModal: error});
            });
        } else if (action === "delete") {
            this.props.firebase.doCancelFeedbackRequest(this.props.doc.id)
            .then(() => {
                this.closeConfirmationModal();
                this.loadInitialData();
                this.props.refreshPage();
            }).catch(error => {
                this.setState({ errorConfirmModal: error });
            }); 
        } else {
            console.error( "feedback.createDeleteViewModal: invalid action provided to 'onSubmitConfirm'" );
        }
    }

    // Pass this function to the lookup component to set the record
    setReviewerDetails = reviewerRecord => {
        if (reviewerRecord !== undefined) {
            if (reviewerRecord.uid === null) { // if passed back with uid==null then no record was found
                this.setState({
                    reviewerEmail: reviewerRecord.email,
                    reviewerFound: false,
                });
            } else {
                this.setState({
                    reviewerUID: reviewerRecord.uid,
                    reviewerFirstName: reviewerRecord.firstName,
                    reviewerLastName: reviewerRecord.lastName,
                    reviewerRole: '',
                    reviewerEmail: reviewerRecord.email,
                    reviewerFound: true,
                });
            }
        }

        // console.log(reviewerRecord);
        // reviewerEmail state variable is used for confirming whether it is validated
    }

    // Pass this function to the lookup component to reset the user record
    resetReviewerDetails = () => {
        this.setState({
            reviewerUID: '',
            reviewerFirstName: '',
            reviewerLastName: '',
            reviewerRole: '',
            reviewerEmail: '',
            reviewerFound: false,
        });
    }

    render() {
        if (this.state && !this.state.loading) {
            var actionIcon;
            var actionIconTooltipText;
            var actionIconUniqueID;
            var renderFeedback = false;
            var isCompleted = false;
            if (this.props.action.toLowerCase() !== "create") {
                actionIcon = "tim-icons icon-zoom-split";
                actionIconTooltipText = "View Request";
                actionIconUniqueID = this.props.doc.id;
                renderFeedback = this.props.doc.data.status.toLowerCase() === "completed";
                isCompleted = !["completed", "cancelled"].includes(this.props.doc.data.status.toLowerCase());
            } else if (this.props.action.toLowerCase() === "create") {
                actionIcon = "tim-icons icon-simple-add";
                actionIconTooltipText = "Create New Feedback Request";
                actionIconUniqueID = "create"
            }

            return (
                <>
                    {this.props.renderFull? 
                        <>
                            <Button block 
                                    color="primary"
                                    id={"NewFeedbackRequestButton"}
                                    title=""
                                    type="button" 
                                    onClick={this.openMainModal} >
                                <i className="tim-icons icon-simple-add" />
                                { this.props.buttonText? " "+this.props.buttonText : null }
                            </Button>
                            <br />
                        </>
                        : <ActionIcon 
                            id={"feedbackRequestModal"+actionIconUniqueID}
                            iconClassName={actionIcon}
                            toolTipText={actionIconTooltipText}
                            onClick={this.openMainModal}
                            iconText={this.props.buttonText}
                        />
                    }

                    {/* This modal is for the requestor to view the details of completed feedback */ }
                    <Modal isOpen={ this.state.mainModal } toggle={ this.closeMainModal }>
                        <ModalHeader tag="h4" toggle={ this.closeMainModal }>{actionIconTooltipText}</ModalHeader>
                        <ModalBody>
                            <Form className="form-newFeedbackRequest">
                                <b>Project Details</b>
                                <FormGroup className={this.state.isValidProjectName}>
                                    <Label for="feedback-projectName">Project</Label>
                                    <Input
                                        id="feedback-projectName"
                                        className="form-control"
                                        name = "projectName"
                                        value = {this.state.projectName}
                                        onChange = {e => this.onChange(e)}
                                        onBlur = {e => this.onChange(e)}
                                        type = "text"
                                        disabled = {this.props.action.toLowerCase() !== "create"}
                                    />
                                </FormGroup>
                                <FormGroup className={this.state.isValidProjectRole}>
                                    <Label for="feedback-projectRole">Project Role</Label>
                                    <Input
                                        id="feedback-projectRole"
                                        className="form-control"
                                        name = "projectRole"
                                        value = {this.state.projectRole}
                                        onChange = {e => this.onChange(e)}
                                        onBlur = {e => this.onChange(e)}
                                        type = "text"
                                        disabled = {this.props.action.toLowerCase() !== "create"}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="feedback-projectSummary">Project / Accomplishment Summary</Label>
                                    <Input
                                        id="feedback-projectSummary"
                                        className="form-control"
                                        name = "projectSummary"
                                        value = {this.state.projectSummary}
                                        onChange = {e => this.onChange(e)}
                                        onBlur = {e => this.onChange(e)}
                                        type = "textarea"
                                        disabled = {this.props.action.toLowerCase() !== "create"}
                                    />
                                </FormGroup>
                                {this.props.action === "create"?
                                    <Row>
                                        <Col xs="6">
                                            <FormGroup className={this.state.isValidStartDate}>
                                                <Label for="feedback-startDate">Start Date</Label>
                                                <Input 
                                                    type="date"  
                                                    name="startDate" 
                                                    value = {this.state.startDate}
                                                    onChange = {e => this.onChange(e)}
                                                    onBlur = {e => this.onChange(e)}
                                                    id="feedback-startDate"
                                                    disabled = {this.props.action.toLowerCase() !== "create"}
                                                    />
                                            </FormGroup>
                                        </Col>
                                        <Col xs="6">
                                            <FormGroup className={this.state.isValidEndDate}>
                                                <Label for="feedback-endDate">End Date</Label>
                                                <Input 
                                                    type="date" 
                                                    name="endDate" 
                                                    value = {this.state.endDate}
                                                    onChange = {e => this.onChange(e)}
                                                    onBlur = {e => this.onChange(e)}
                                                    id="feedback-endDate"
                                                    disabled = {this.props.action.toLowerCase() !== "create"}
                                                    />
                                                <FormFeedback>Cannot be before start date</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col xs="12">              
                                            <FormGroup>
                                                <Label for="feedback-projectHours">Captured Hours</Label>
                                                <Input
                                                    id="feedback-projectHours"
                                                    className="form-control"
                                                    name = "projectHours"
                                                    value = {this.state.projectHours}
                                                    onChange = {e => this.onChange(e)}
                                                    onBlur = {e => this.onChange(e)}
                                                    type = "text"
                                                    disabled = {this.props.action.toLowerCase() !== "create"}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>  
                                    :
                                    <Row>
                                        <Col xs="4">
                                            <FormGroup className={this.state.isValidStartDate}>
                                                <Label for="feedback-startDate">Start Date</Label>
                                                <Input 
                                                    type="text"  
                                                    name="startDate" 
                                                    value = {moment(this.state.startDate).format("MMM Do YYYY")}
                                                    id="feedback-startDate"
                                                    disabled = {this.props.action.toLowerCase() !== "create"}
                                                    />
                                            </FormGroup>
                                        </Col>
                                        <Col xs="4">
                                            <FormGroup className={this.state.isValidEndDate}>
                                                <Label for="feedback-endDate">End Date</Label>
                                                <Input 
                                                    type="text" 
                                                    name="endDate" 
                                                    value = {moment(this.state.endDate).format("MMM Do YYYY")}
                                                    id="feedback-endDate"
                                                    disabled = {this.props.action.toLowerCase() !== "create"}
                                                    />
                                                <FormFeedback>Cannot be before start date</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col xs="4">              
                                            <FormGroup>
                                                <Label for="feedback-projectHours">Captured Hours</Label>
                                                <Input
                                                    id="feedback-projectHours"
                                                    className="form-control"
                                                    name = "projectHours"
                                                    value = {this.state.projectHours}
                                                    type = "text"
                                                    disabled = {this.props.action.toLowerCase() !== "create"}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>  
                                }
                                <hr />
                                <b>Reviewer</b>
                                {this.props.action === "create"? // only render for create
                                    <UserLookupFeedback 
                                        setReviewer = {retObj => this.setReviewerDetails(retObj)}
                                        clearReviewer = {this.resetReviewerDetails}
                                        divClassName = {this.state.isValidReviewerEmail}
                                    />
                                :null}
                                <Collapse isOpen={this.state.reviewerEmail !== '' || this.props.action.toLowerCase() !== "create"}>
                                    <Row>
                                        <Col md="6">
                                            <FormGroup className={this.state.isValidReviewerFirstName}>
                                                <Label for="feedback-reviewerFirstName">First Name</Label>
                                                <Input
                                                    id="feedback-reviewerFirstName"
                                                    className="form-control"
                                                    name = "reviewerFirstName"
                                                    value = {this.state.reviewerFirstName}
                                                    onChange = {e => this.onChange(e)}
                                                    onBlur = {e => this.onChange(e)}
                                                    type = "text"
                                                    disabled = {this.state.reviewerFound || this.props.action.toLowerCase() !== "create"}
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup className={this.state.isValidReviewerLastName}>
                                                <Label for="feedback-reviewerLastName">Last Name</Label>
                                                <Input
                                                    id="feedback-reviewerLastName"
                                                    className="form-control"
                                                    name = "reviewerLastName"
                                                    value = {this.state.reviewerLastName}
                                                    onChange = {e => this.onChange(e)}
                                                    onBlur = {e => this.onChange(e)}
                                                    type = "text"
                                                    disabled = {this.state.reviewerFound || this.props.action.toLowerCase() !== "create"}
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <FormGroup className={this.state.isValidReviewerRole}>
                                        <Label for="feedback-reviewerRole">Role</Label>
                                        <Input
                                            id="feedback-reviewerRole"
                                            className="form-control"
                                            name = "reviewerRole"
                                            value = {this.state.reviewerRole}
                                            onChange = {e => this.onChange(e)}
                                            onBlur = {e => this.onChange(e)}
                                            type = "text"
                                            disabled = {this.props.action.toLowerCase() !== "create"}
                                            required
                                        />
                                    </FormGroup>
                                </Collapse>   
                            </Form>

                            {/* TODO: IMPLEMENT SHOW FEEDBACK FIELDS WHEN FEEDBACK IS COMPLEteD */}
                            {renderFeedback? 
                                <>
                                    <hr />
                                    { /* DEV NOTE: How will we accomodate different form version for various organizations? */}
                                    <Form className="form-newFeedbackRequest">
                                        <b>Reviewer's Feedback</b>
                                        <FormGroup>
                                            <Label for="feedback-feedbackGeneral">General Feedback</Label>
                                            <Input
                                                id="feedback-feedbackGeneral"
                                                className="form-control"
                                                name = "feedbackGeneral"
                                                value = {this.props.doc.data.feedbackGeneral}
                                                type = "textarea"
                                                disabled
                                            />
                                        </FormGroup>
                                    </Form>
                                </> : null
                            }
                            
                            { /* Submit confirmation modal */ }
                            <Modal isOpen={ this.state.confirmationModal === "create" } toggle={ this.closeConfirmationModal } size="sm">
                                <ModalHeader tag="h4" toggle={ this.closeConfirmationModal }>Submit Confirmation</ModalHeader>
                                <ModalBody>
                                    <center><p>Are you sure you would like to submit?</p></center>
                                    {this.state.errorConfirmModal && <><br /><Alert color="danger">{this.state.errorConfirmModal.message}</Alert></>}
                                </ModalBody>
                                <ModalFooter>
                                    <ActionIcon 
                                        id={"feedbackRequestModalSubmitConfirm"+actionIconUniqueID}
                                        iconClassName="tim-icons icon-check-2"
                                        toolTipText="Confirm submission"
                                        onClick={() => this.onSubmitConfirm("create")}
                                    />
                                    <ActionIcon 
                                        id={"feedbackRequestModalSubmitCancel"+actionIconUniqueID}
                                        iconClassName="tim-icons icon-simple-remove"
                                        toolTipText="Continue editing"
                                        onClick={this.closeConfirmationModal}
                                    />
                                </ModalFooter>
                            </Modal>

                            { /* Cancel confirmation modal */ }
                            <Modal isOpen={this.state.confirmationModal === "delete" } toggle={ this.closeConfirmationModal } size="sm">
                                <ModalHeader tag="h4" toggle={ this.closeConfirmationModal }>Cancel Confirmation</ModalHeader>
                                <ModalBody>
                                    <center><p>Are you sure you would like to cancel? You unsaved changed will be lost.</p></center>
                                    {this.state.errorConfirmModal && <><br /><Alert color="danger">{this.state.errorConfirmModal.message}</Alert></>}
                                </ModalBody>
                                <ModalFooter>
                                    <ActionIcon 
                                        id={"feedbackRequestModalCancelConfirm"+actionIconUniqueID}
                                        iconClassName="tim-icons icon-trash-simple"
                                        toolTipText="Cancel request"
                                        onClick={() => this.onSubmitConfirm("delete")}
                                    />
                                    <ActionIcon 
                                        id={"feedbackRequestModalCancelCancel"+actionIconUniqueID}
                                        iconClassName="tim-icons icon-simple-remove"
                                        toolTipText="Don't cancel request"
                                        onClick={this.closeConfirmationModal}
                                    />
                                </ModalFooter>
                            </Modal>

                            {this.state.errorMainModal && <><br /><Alert color="danger">{this.state.errorMainModal.message}</Alert></>}
                        </ModalBody>
                        <ModalFooter>
                            { this.props.action.toLowerCase() === "create" ?
                                    <>
                                        <ActionIcon 
                                            id={"feedbackRequestModalSubmit"+actionIconUniqueID}
                                            iconClassName="tim-icons icon-check-2"
                                            toolTipText="Submit feedback"
                                            onClick={() => this.onSubmit("create")}
                                        />
                                    </>
                                : 
                                    <>
                                        {isCompleted?
                                            <>
                                                <ActionIcon 
                                                    id={"feedbackRequestModalSubmit"+actionIconUniqueID}
                                                    iconClassName="tim-icons icon-trash-simple"
                                                    toolTipText="Cancel request"
                                                    onClick={() => this.onSubmit("delete")}
                                                />
                                                {/* TODO: Implement Reminder email */}
                                                <ActionIcon 
                                                    id={"feedbackRequestModalReminder"+actionIconUniqueID}
                                                    iconClassName="tim-icons icon-email-85"
                                                    toolTipText="Send reminder"
                                                    onClick={() => this.onSubmit("reminder")}
                                                /> 
                                            </>
                                        : null }
                                    </>
                            }
                            <ActionIcon 
                                id={"feedbackRequestModalCancel"+actionIconUniqueID}
                                iconClassName="tim-icons icon-simple-remove"
                                toolTipText="Cancel and close"
                                onClick={this.closeMainModal}
                            />
                        </ModalFooter>
                    </Modal>
                </>
            )
        } return null;
    }
}



const FeedbackSummary = withFirebase(FeedbackSummaryBase);
const FeedbackRequest = withFirebase(FeedbackRequestBase);

export default FeedbackSummary;
export {FeedbackRequest};