import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import { withFirebase } from '../Firebase/Firebase';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';

import {
    Container,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    FormFeedback,
} from 'reactstrap';

const INITIAL_STATE = {
    validToSubmit: false,
    isValidEmail: '',
    isValidFirstName: '',
    isValidLastName: '',
    isValidPasswordOne: '',
    isValidPasswordTwo: '',

    email: '',
    firstName: '',
    lastName: '',
    passwordOne: '',
    passwordTwo: '',
    isOrganization: false,
    error: null,
};

const SignUpLink = () => (
    <Link to={ROUTES.SIGN_UP} className="nav-link">Don't have an account?</Link>
)

class SignUpLinkCustom extends Component {
    // constructor (props) {
    //     super(props);
    //     // props.phrase
    // };

    render() {
        return <Link to={ROUTES.SIGN_UP}>{this.props.phrase}</Link>
    }
}

class SignUpFormBase extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    };

    onSubmit = event => {
        var role = '';

        // set the role for organization or individaul
        if (this.state.isOrganization)
            role = ROLES.ORG;
        else
            role = ROLES.IND;

        this.props.firebase
            .doCreateUserWithEmailAndPassword(
                this.state.email,
                this.state.passwordOne,
                this.state.firstName,
                this.state.lastName,
                role)
            .then(() => {
                this.props.history.push(ROUTES.DASHBOARD);
            })
            .catch(error => {
                console.log(error)
                this.setState({ error });
            });

        event.preventDefault();
    };

    onChange = event => {
        event.persist();

        this.setState({
            [event.target.name]: event.target.value
        }, () => {
            this.setState({ validToSubmit: this.validateSubmission(event.target.name) });
        });
    };

    validateEmail = email => {
        let isValid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(email);
        return isValid;
    }

    // Validate whether the field value(s) are valid to submit. If the field is invalid, a className is set to individually identify the fields with issues.
    validateSubmission = fieldToValidate => {
        var isValid = this.state.email !== ''
            && this.validateEmail(this.state.email)
            && this.state.firstName !== ''
            && this.state.lastName !== ''
            && this.state.passwordOne !== ''
            && this.state.passwordTwo === this.state.passwordOne;

        if (fieldToValidate === "email" || fieldToValidate === "all") {
            if (this.state.email === '' || !this.validateEmail(this.state.email)) this.setState({ isValidEmail: "has-danger" });
            else this.setState({ isValidEmail: "" });
        }
        if (fieldToValidate === "firstName" || fieldToValidate === "all") {
            if (this.state.firstName === '') this.setState({ isValidFirstName: "has-danger" });
            else this.setState({ isValidFirstName: "" });
        }
        if (fieldToValidate === "lastName" || fieldToValidate === "all") {
            if (this.state.lastName === '') this.setState({ isValidLastName: "has-danger" });
            else this.setState({ isValidLastName: "" });
        }
        if (fieldToValidate === "passwordOne" || fieldToValidate === "all") {
            if (this.state.passwordOne === '') this.setState({ isValidPasswordOne: "has-danger" });
            else this.setState({ isValidPasswordOne: "" });
        }
        if (fieldToValidate === "passwordTwo" || fieldToValidate === "all") {
            if (this.state.passwordTwo !== this.state.passwordOne) this.setState({ isValidPasswordTwo: "has-danger" });
            else this.setState({ isValidPasswordTwo: "" });
        }

        return isValid;
    }

    render() {
        return (
            <>
                <Container>
                    <Form onSubmit={this.onSubmit} className="form-signin">
                        <h2>Join Us!</h2>
                        <FormGroup className={this.state.isValidFirstName}>
                            <Label for="reg-firstName" className="sr-only">First Name</Label>
                            <Input
                                id="reg-firstName"
                                name="firstName"
                                value={this.state.firstName}
                                onChange={e => this.onChange(e)}
                                onBlur={e => this.onChange(e)}
                                type="text"
                                placeholder="First Name"
                                required
                            />
                        </FormGroup>
                        <FormGroup className={this.state.isValidLastName}>
                            <Label for="reg-lastName" className="sr-only">Last Name</Label>
                            <Input
                                id="reg-lastName"
                                name="lastName"
                                value={this.state.lastName}
                                onChange={e => this.onChange(e)}
                                onBlur={e => this.onChange(e)}
                                type="text"
                                placeholder="Last Name"
                                required
                            />
                        </FormGroup>
                        <FormGroup className={this.state.isValidEmail}>
                            <Label for="reg-email" className="sr-only">Email</Label>
                            <Input
                                id="reg-email"
                                name="email"
                                value={this.state.email}
                                onChange={e => this.onChange(e)}
                                onBlur={e => this.onChange(e)}
                                type="text"
                                placeholder="Email Address"
                                required
                            />
                        </FormGroup>
                        <FormGroup className={this.state.isValidPasswordOne}>
                            <Label for="reg-passwordone" className="sr-only">Password</Label>
                            <Input
                                id="reg-passwordone"
                                name="passwordOne"
                                value={this.state.passwordOne}
                                onChange={e => this.onChange(e)}
                                onBlur={e => this.onChange(e)}
                                type="password"
                                placeholder="Password"
                                required
                            />
                        </FormGroup>
                        <FormGroup className={this.state.isValidPasswordTwo}>
                            <Label for="reg-passwordtwo" className="sr-only">Confirm Password</Label>
                            <Input
                                id="reg-passwordtwo"
                                name="passwordTwo"
                                value={this.state.passwordTwo}
                                onChange={e => this.onChange(e)}
                                onBlur={e => this.onChange(e)}
                                type="password"
                                placeholder="Confirm Password"
                                invalid={this.state.isValidPasswordTwo !== ''}
                                required
                            />
                            <FormFeedback>The passwords do not match.</FormFeedback>
                        </FormGroup>
                        <Button block color="primary" disabled={!this.state.validToSubmit}>Sign up</Button>
                    </Form>
                </Container>
                {this.state.error && <><br /><p>{this.state.error.message}</p></>}
            </>
        );
    };
};

const SignUpForm = withRouter(withFirebase(SignUpFormBase));

export default SignUpForm;

export { SignUpLink, SignUpLinkCustom };