import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import {withFirebase} from '../Firebase/Firebase';
import * as ROUTES from '../../constants/routes';

import {
    Container,
    Button, 
    Form, 
    FormGroup, 
    Label, 
    Input,
} from 'reactstrap';

const INITIAL_STATE = {
    email: '',
    password: '',
    validToSubmit: false,
    error: null,
};

class SignInFormBase extends Component {
    constructor(props) {
        super(props);

        this.state = {...INITIAL_STATE};
    };

    onSubmit = event => {
        const {email, password} = this.state;

        this.props.firebase
            .doSignInWithEmailAndPassword(email, password)
            .then(() => {
                this.setState({...INITIAL_STATE});
                this.props.history.push(ROUTES.DASHBOARD);
            })
            .catch(error => {
                this.setState({error});
            });

            event.preventDefault();
    };

    validateEmail() {
        let isValid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(this.state.email);
        return isValid;
    }

    validateSubmission() {
        this.setState({ validToSubmit: this.state.password !== ''
                        && this.validateEmail()})
    }

    onChange = event => {
        event.persist();

        this.setState({ 
            [event.target.name]: event.target.value 
        }, () => {
            this.validateSubmission()
        });
    };

    render() {
        return (
            <>
                <Container>
                    <Form onSubmit={this.onSubmit}>
                        <h2>Log In</h2>
                        <FormGroup>
                            <Label for="inputEmail" className="sr-only">Email address</Label>
                            <Input
                                id="inputEmail"
                                className="form-control"
                                name="email"
                                value={this.state.email}
                                onChange={e => this.onChange(e)}
                                type="email"
                                placeholder="Email Address"
                                required
                                autoFocus
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="inputPassword" className="sr-only">Password</Label>
                            <Input
                                id="inputPassword"
                                className="form-control"
                                name="password"
                                value={this.state.password}
                                onChange={e => this.onChange(e)}
                                type="password"
                                placeholder="Password"
                                required
                            />
                        </FormGroup>
                        {/* <FormGroup>
                            <Label><Input type="checkbox" value="remember-me" /> Remember Me</Label>
                        </FormGroup> */}
                        <Button block disabled={!this.state.validToSubmit} color="primary">Sign in</Button>
                    </Form>
                </Container>
                {this.state.error && <><br/><p>{this.state.error.message}</p></>}
            </>
        );
    };
};

const SignInForm = withRouter(withFirebase(SignInFormBase));

export default SignInForm;