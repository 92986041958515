import React, {Component} from "react";

import {AuthUserContext} from '../../Components/Session/Session';
import {
  Jumbotron,
  Container
} from "reactstrap";

import "./home.css";

class HomeView extends Component {
  // constructor(props) {
  //   super(props);
  // }
  

  render() {
    
    return (
      <div className="content">
        <AuthUserContext.Consumer>
          {authUser => (
            <Jumbotron>
              <Container>
                <h1 className="display-3">Under Construction</h1>
                <p className="lead">This feature is coming soon!</p>
              </Container>
            </Jumbotron>
          )}
        </AuthUserContext.Consumer>
      </div>
    );
  }
}

export default HomeView;
