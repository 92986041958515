import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";

// reactstrap components
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Modal
} from "reactstrap";

import SignOutButton from '../SignOut/SignOut';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import {AuthUserContext} from '../Session/Session';
import {Link, withRouter} from 'react-router-dom';
import logo from "../../assets/img/plane-logo-sm.png";


class DashTopNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      modalSearch: false,
      color: "navbar-transparent"
    };

    //this.props.brandText="CMD"
    //this.props.isDashboard={true}
    //this.props.toggleSidebar={this.toggleSidebar}
    //this.props.sidebarOpened={this.state.sidebarOpened}
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateColor);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateColor);
  }
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.collapseOpen) {
      this.setState({
        color: "bg-white"
      });
    } else {
      this.setState({
        color: "navbar-transparent"
      });
    }
  };
  // this function opens and closes the collapse on small devices
  toggleCollapse = () => {
    if (this.state.collapseOpen) {
      this.setState({
        color: "navbar-transparent"
      });
    } else {
      this.setState({
        color: "bg-white"
      });
    }
    this.setState({
      collapseOpen: !this.state.collapseOpen
    });
  };
  // this function is to open the Search modal
  toggleModalSearch = () => {
    this.setState({
      modalSearch: !this.state.modalSearch
    });
  };

  renderSidebarToggleButton = () => {
    return (
      <>
        {this.props.sidebarExists &&
          <div
            className={classNames("navbar-toggle d-inline", {
              toggled: this.props.sidebarOpened
            })}
          >
            <button
              className="navbar-toggler"
              type="button"
              onClick={this.props.toggleSidebar}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
        }
      </>
    )
  }
 
  render() {
    return (
      <>
        <Navbar
          className={classNames("navbar-absolute", this.state.color)}
          expand="lg"
        >
          <Container fluid>
            <div className="navbar-wrapper">
              {this.props.isDashboard &&
                <div
                  className={classNames("navbar-toggle d-inline", {
                    toggled: this.props.sidebarOpened
                  })}
                >
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={this.props.toggleSidebar}
                  >
                    <span className="navbar-toggler-bar bar1" />
                    <span className="navbar-toggler-bar bar2" />
                    <span className="navbar-toggler-bar bar3" />
                  </button>
                </div>
              }
              <NavbarBrand href={ROUTES.LANDING}>
                  <img src={logo} alt="logo" />
                  {this.props.brandText}
              </NavbarBrand>
            </div>
            <button
              aria-expanded={false}
              aria-label="Toggle navigation"
              className="navbar-toggler"
              data-target="#navigation"
              data-toggle="collapse"
              id="navigation"
              type="button"
              onClick={this.toggleCollapse}
            >
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
            </button>
            <Collapse navbar isOpen={this.state.collapseOpen}>
              <Nav className="ml-auto" navbar>
                <AuthUserContext.Consumer>
                  {authUser => authUser? <NavAuth isDash={this.props.isDashboard} authUser={authUser}/> : <NavNonAuth />}
                </AuthUserContext.Consumer>
                <li className="separator d-lg-none" />
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
        <Modal
          modalClassName="modal-search"
          isOpen={this.state.modalSearch}
          toggle={this.toggleModalSearch}
        >
          <div className="modal-header">
            <Input id="inlineFormInputGroup" placeholder="SEARCH" type="text" />
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={this.toggleModalSearch}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
          </div>
        </Modal>
      </>
    );
  }
}

// Not logged in menu
const NavNonDash = () => {
  return (
    <NavItem nav>
      <NavLink tag="li">
        <Link to={ROUTES.DASHBOARD} className="nav-link">
          <i className="tim-icons icon-components" />
          <p className="d-lg-none">Dashboard</p>
        </Link>
      </NavLink>
    </NavItem>
  );
};

// Not logged in menu
const NavNonAuth = () => {
  return (
    <NavItem nav>
      <NavLink tag="li">
        <Link to={ROUTES.SIGN_IN} className="nav-link">Sign In</Link>
      </NavLink>
    </NavItem>
  );
};

const NavAdmin = () => {
  return (
    <NavItem nav>
      <NavLink tag="li">
        <Link to={ROUTES.ADMIN} className="nav-link">
          <i className="tim-icons icon-atom" />
          <p className="d-lg-none">Admin</p>
        </Link>
      </NavLink>
    </NavItem>
  );
}

// Logged in menu
const NavAuth = (props) => {
  return (
    <>
      {!props.isDash && <NavNonDash />}
      {!!props.authUser.role === ROLES.ADMIN && <NavAdmin />}
      <UncontrolledDropdown nav>
        <DropdownToggle
          caret
          color="default"
          data-toggle="dropdown"
          nav
        >
          <div className="notification d-none d-lg-block d-xl-block" />
          <i className="tim-icons icon-bell-55" />
          <p className="d-lg-none">Notifications</p>
        </DropdownToggle>
        <DropdownMenu className="dropdown-navbar" right tag="ul">
          <NavLink tag="li">
            <DropdownItem className="nav-item">
              Mike John responded to your email
            </DropdownItem>
          </NavLink>
          <NavLink tag="li">
            <DropdownItem className="nav-item">
              You have 5 more tasks
            </DropdownItem>
          </NavLink>
          <NavLink tag="li">
            <DropdownItem className="nav-item">
              Fake Notifications
            </DropdownItem>
          </NavLink>
        </DropdownMenu>
      </UncontrolledDropdown>
      <UncontrolledDropdown nav>
        <DropdownToggle
          caret
          color="default"
          data-toggle="dropdown"
          nav
          onClick={e => e.preventDefault()}
        >
          <i className="tim-icons icon-settings-gear-63" />
          <b className="caret d-none d-lg-block d-xl-block" />
          <p className="d-lg-none">Settings</p>
        </DropdownToggle>
        <DropdownMenu className="dropdown-navbar" right tag="ul">
          <NavLink tag="li">
            <DropdownItem className="nav-item">
              <Link to={ROUTES.ACCOUNT} className="nav-link">Account</Link>
            </DropdownItem>
          </NavLink>
          <DropdownItem divider tag="li" />
          <NavLink tag="li">
            <DropdownItem className="nav-item">
              <SignOutButton />
            </DropdownItem>
          </NavLink>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
};


export default withRouter(DashTopNav);
