import React, {Component} from 'react';
import {withFirebase} from '../Firebase/Firebase';
import {UserLookupFeedback} from '../Search/UserLookup';
import {NextSessionDetails,
        LastSessionSummary,
        SessionHistory} from './SessionDetails';
import {GoalItemCreateEditModal, ActiveGoals, CompletedGoals} from './Goal';
import { ActiveActionItems, CompletedActionItems,ActionItemCreateEditModal } from './ActionItem';
import {
    Button, 
    Form, 
    FormGroup, 
    Label, 
    Input, 
    Row,
    Col,
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    Card, 
    CardHeader,
    CardTitle,
    CardBody,
    Collapse,
    FormFeedback,
} from 'reactstrap';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';



/*************************************************************************************/
/******************************* Feedback Create Modal *******************************/
/*************************************************************************************/

const INITIAL_STATE_CREATEMODAL = {
    loading: true,
    changed: false,
    validToSubmit: false,
    mainCreateModal: false,
    confirmationModal: null,
    requestor: '',

    isValidStartDate: '',
    isValidEndDate: '',
    isValidSessionFrequency: '',
    isValidMentorFirstName: '',
    isValidMentorEmail: '',
    isValidMenteeFirstName: '',
    isValidMenteeEmail: '',

    mentorUID: '',
    mentorFirstName: '',
    mentorLastName: '',
    mentorEmail: '',
    menteeUID: '',
    menteeFirstName: '',
    menteeLastName: '',
    menteeEmail: '',
    isPendingApproval: '',
    startDate: '',
    endDate: '',
    sessionFrequency: '',

    mentorFound: false,
    menteeFound: false,

    errorCreateModal: null,
    errorConfirmModal: null,
};
  
class MentorshipRelationshipCreateModalBase extends Component {
    constructor(props) {
        super(props);
        // props.refreshPage
        // props.uid; // UID of user provided by parent component through auth context
        // props.email; // email of user provided by parent component through auth context
        // props.buttonText
        // props.requestor; // [ 'mentee', 'mentor', 'org' ] // required
        // props.didLoad()

        if (props.requestor === "mentee") {
            INITIAL_STATE_CREATEMODAL["isPendingApproval"] = "mentor";
        } else if (props.requestor === "mentor") {
            INITIAL_STATE_CREATEMODAL["isPendingApproval"] = "mentee";
        } else if (props.requestor === "org") {
            INITIAL_STATE_CREATEMODAL["isPendingApproval"] = "approved";
        } else {
            throw new Error("Unexpected value passed for requestor. Expecting values: ['mentee','mentor','org']");
        }
        INITIAL_STATE_CREATEMODAL["requestor"] = props.requestor;

        this.state = {...INITIAL_STATE_CREATEMODAL};
    }

    componentDidMount() {
        if (this.props.didLoad !== undefined) this.props.didLoad();
    }
    
    // Launches the main feedback modal
    openCreateModal = () => {
        this.setState({mainCreateModal: true});
    }

    // Submit button for the main feedback modal
    onSubmit = () => {
        if (this.validateSubmission("all")) this.setState({confirmationModal: "submit"});
        else this.setState({ errorCreateModal: new Error("There may be invalid values or missing required fields.")});
    }

    // call to firestore to create the FS entry
    submitCreateMentorshipRequest = () => {
        if (this.state.mentorUID === this.state.menteeUID) {
            this.onConfirmationCancel();
            this.setState({errorCreateModal: new Error("Mentor and Mentee can not be the same individual.")});
        } else {
            return this.props.firebase.doCreateMentorshipRelationship( // then create the new mentorship relationship in firestore
                this.state.mentorUID,
                this.state.mentorEmail,
                this.state.mentorFirstName,
                this.state.mentorLastName,
                this.state.menteeUID,
                this.state.menteeEmail,
                this.state.menteeFirstName,
                this.state.menteeLastName,
                this.state.startDate,
                this.state.endDate,
                this.state.isPendingApproval
            ).then (() => {
                this.setState({...INITIAL_STATE_CREATEMODAL});
                this.props.refreshPage();
            }).catch(error => {
                this.setState({errorConfirmModal: error});
            })
        }
        return false;
    }

    // Confirm button on the submit/cancel confirmation modal
    onConfirmationConfirm = () => {
        if (this.state.confirmationModal === "cancel") this.setState({...INITIAL_STATE_CREATEMODAL});
        else if (this.state.confirmationModal === "submit") {
            if (this.state.requestor === "org") {
                return this.submitCreateMentorshipRequest(); // if org, all details should be in the state
            } else {
                return this.props.firebase.doGetUserAccount(this.props.uid) // if mentor or mentee requesting, retrieve the user's details from the db
                    .then(userRecord => {
                        if (this.state.requestor === "mentee") {
                            this.setState({
                                menteeUID: this.props.uid,
                                menteeEmail: this.props.email,
                                menteeFirstName: userRecord.data().firstName,
                                menteeLastName: userRecord.data().lastName
                            })
                        } else if (this.state.requestor === "mentor") {
                            this.setState({
                                mentorUID: this.props.uid,
                                mentorEmail: this.props.email,
                                mentorFirstName: userRecord.data().firstName,
                                mentorLastName: userRecord.data().lastName
                            })
                        }
                        return this.submitCreateMentorshipRequest(); // then submit request
                    })  
            }
        } else this.setState({ errorConfirmModal: new Error("Invalid action provided for confirmation") });
    }

    // Cancels the confirmation model, returns back to main modal
    onConfirmationCancel = () => {
        this.setState({confirmationModal: null});
    }

    // Close button action for main feedback modal
    closeCreateModal = () => {
        if (this.state.changed) this.setState({confirmationModal: "cancel"});
        else this.setState({...INITIAL_STATE_CREATEMODAL});
    } 

    onChange = event => {
        event.persist(); // allows event to be available to the callback

        this.setState({
            [event.target.name]: event.target.value
        }, () => {
            this.setState({ validToSubmit: this.validateSubmission(event.target.name) });
            this.isChanged();
        });
    };

    // Validate whether there are changes to the form
    isChanged() {
        let isChanged = this.state.startDate !== INITIAL_STATE_CREATEMODAL["startDate"]
                        || this.state.endDate !== INITIAL_STATE_CREATEMODAL["endDate"]
                        || this.state.sessionFrequency !== INITIAL_STATE_CREATEMODAL["sessionFrequency"]
                        || this.state.mentorName !== INITIAL_STATE_CREATEMODAL["mentorName"]
                        || this.state.mentorEmail !== INITIAL_STATE_CREATEMODAL["mentorEmail"]
                        || this.state.menteeName !== INITIAL_STATE_CREATEMODAL["menteeName"]
                        || this.state.menteeEmail !== INITIAL_STATE_CREATEMODAL["menteeEmail"]

        this.setState({ changed: isChanged });
    }

    // Validate whether the values of the form are valid
    validateSubmission = fieldToValidate => {
        var isValid = true; 

        // if (fieldToValidate === "startDate" || fieldToValidate === "all") {
        //     if (this.state.startDate !== '' && this.state.endDate < this.state.startDate) { 
        //         this.setState({isValidStartDate: "has-danger"});
        //         isValid = false;
        //     } else this.setState({isValidStartDate: ""});
        // }
        
        if (fieldToValidate === "endDate" || fieldToValidate === "all") {
            if (this.state.endDate !== '' && this.state.endDate < this.state.startDate ) { 
                this.setState({isValidEndDate: "has-danger"});
                isValid = false;
            } else this.setState({isValidEndDate: ""});
        }

        if (this.props.requestor !== "mentor" && (fieldToValidate === "mentorFirstName" || fieldToValidate === "all")) {
            if (this.state.mentorFirstName === '') { 
                this.setState({isValidMentorFirstName: "has-danger"});
                isValid = false;
            } else this.setState({isValidMentorFirstName: ""});
        }

        if (this.props.requestor !== "mentor" && (fieldToValidate === "mentorEmail" || fieldToValidate === "all")) {
            if (this.state.mentorEmail === '') { 
                this.setState({isValidMentorEmail: "has-danger"});
                isValid = false;
            } else this.setState({isValidMentorEmail: ""});
        }

        if (this.props.requestor !== "mentee" && (fieldToValidate === "menteeFirstName" || fieldToValidate === "all")) {
            if (this.state.menteeFirstName === '') { 
                this.setState({isValidMenteeFirstName: "has-danger"});
                isValid = false;
            } else this.setState({isValidMenteeFirstName: ""});
        }

        if (this.props.requestor !== "mentee" && (fieldToValidate === "menteeEmail" || fieldToValidate === "all")) {
            if (this.state.menteeEmail === '') { 
                this.setState({isValidMenteeEmail: "has-danger"});
                isValid = false;
            } else this.setState({isValidMenteeEmail: ""});
        }

        return isValid;
    }


    // Pass this function to the lookup component for Mentee to set the record
    setMenteeDetails = menteeRecord => {
        if (menteeRecord !== undefined) {
            if (menteeRecord.uid === null) { // if passed back with uid==null then no record was found
                this.setState({
                    menteeEmail: menteeRecord.email,
                    menteeFound: false,
                });
            } else {
                this.setState({
                    menteeUID: menteeRecord.uid,
                    menteeFirstName: menteeRecord.firstName,
                    menteeLastName: menteeRecord.lastName,
                    menteeEmail: menteeRecord.email,
                    menteeFound: true,
                });
            }
        }
    }

    // Pass this function to the lookup component for Mentee to reset the user record
    resetMenteeDetails = () => {
        this.setState({
            menteeUID: '',
            menteeName: '',
            menteeEmail: '',
            menteeFound: true,
        });
    }


    // Pass this function to the lookup component for Mentor to set the record
    setMentorDetails = mentorRecord => {
        if (mentorRecord !== undefined) {
            if (mentorRecord.uid === null) { // if passed back with uid==null then no record was found
                this.setState({
                    mentorEmail: mentorRecord.email,
                    mentorFound: false,
                });
            } else {
                this.setState({
                    mentorUID: mentorRecord.uid,
                    mentorFirstName: mentorRecord.firstName,
                    mentorLastName: mentorRecord.lastName,
                    mentorEmail: mentorRecord.email,
                    mentorFound: true,
                });
            }
        }
    }

    // Pass this function to the lookup component for Mentee to reset the user record
    resetMentorDetails = () => {
        this.setState({
            mentorUID: '',
            mentorName: '',
            mentorEmail: '',
            mentorFound: true,
        });
    }


    render() {
        return (
            <div>
                <Button block 
                        color="primary"
                        id={"MentorshipRelationshipButton"}
                        title=""
                        type="button" 
                        onClick={() => this.openCreateModal()} >
                    <i className="tim-icons icon-simple-add" />
                    { this.props.buttonText? " "+this.props.buttonText : null }
                </Button>

                <Modal isOpen={this.state.mainCreateModal} toggle={() => this.closeCreateModal()} size="lg">
                    <ModalHeader tag="h4" toggle={() => this.closeCreateModal()}>New Mentorship Relationship</ModalHeader>
                    <ModalBody>
                        <Form className="form-newMentorshipRequest">
                            {this.props.requestor !== 'mentee' ?
                                <>
                                    <b>Mentee Details</b>
                                    <UserLookupFeedback 
                                        setReviewer = {retObj => this.setMenteeDetails(retObj)}
                                        clearReviewer = {() => this.resetMenteeDetails()}
                                        divClassName = {this.state.isValidMenteeEmail}
                                        alertMessage = "It seems like your mentor doesn't have an account yet. Don't worry, we'll send a signup link after you submit."
                                    />
                                    <Collapse isOpen={this.state.menteeEmail !== ''}>
                                        <Row>
                                            <Col md="6">
                                                <FormGroup className={this.state.isValidMenteeFirstName}>
                                                    <Label for="mentorship-menteeFirstName">First Name</Label>
                                                    <Input
                                                        id="mentorship-menteeFirstName"
                                                        className="form-control"
                                                        name = "menteeFirstName"
                                                        value = {this.state.menteeFirstName}
                                                        onChange = {e => this.onChange(e)}
                                                        onBlur = {e => this.onChange(e)}
                                                        type = "text"
                                                        disabled = {this.state.menteeFound}
                                                        required
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup className={this.state.isValidMenteeLastName}>
                                                    <Label for="mentorship-menteeLastName">Last Name</Label>
                                                    <Input
                                                        id="mentorship-menteeLastName"
                                                        className="form-control"
                                                        name = "menteeLastName"
                                                        value = {this.state.menteeLastName}
                                                        onChange = {e => this.onChange(e)}
                                                        onBlur = {e => this.onChange(e)}
                                                        type = "text"
                                                        disabled = {this.state.menteeFound}
                                                        required
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        {/* {this.state.menteeFound ? null : 
                                            <Row>
                                                <Col md="12">
                                                    <div className="alert alert-info fade show" role="alert"><span>There isn't an account associated with this email. A signup link will be sent upon submission.</span></div>
                                                </Col>
                                            </Row>
                                        } */}
                                    </Collapse>
                                </> : null
                            }

                            {this.props.requestor !== 'mentor' ? 
                                <>
                                    <b>Mentor Details</b>
                                    <UserLookupFeedback 
                                        setReviewer = {retObj => this.setMentorDetails(retObj)}
                                        clearReviewer = {() => this.resetMentorDetails()}
                                        divClassName = {this.state.isValidMentorEmail}
                                        alertMessage = "It seems like your mentor doesn't have an account yet. Don't worry, we'll send a signup link after you submit."
                                    />
                                    <Collapse isOpen={this.state.mentorEmail !== ''}>
                                        <Row>
                                            <Col md="6">
                                                <FormGroup className={this.state.isValidMentorFirstName}>
                                                    <Label for="mentorship-mentorFirstName">First Name</Label>
                                                    <Input
                                                        id="mentorship-mentorFirstName"
                                                        className="form-control"
                                                        name = "mentorFirstName"
                                                        value = {this.state.mentorFirstName}
                                                        onChange = {e => this.onChange(e)}
                                                        onBlur = {e => this.onChange(e)}
                                                        type = "text"
                                                        disabled = {this.state.mentorFound}
                                                        required
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup className={this.state.isValidMentorLastName}>
                                                    <Label for="mentorship-mentorLastName">Last Name</Label>
                                                    <Input
                                                        id="mentorship-mentorLastName"
                                                        className="form-control"
                                                        name = "mentorLastName"
                                                        value = {this.state.mentorLastName}
                                                        onChange = {e => this.onChange(e)}
                                                        onBlur = {e => this.onChange(e)}
                                                        type = "text"
                                                        disabled = {this.state.mentorFound}
                                                        required
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        {/* {this.state.mentorFound ? null : 
                                            <Row>
                                                <Col md="12">
                                                    <div class="alert alert-info fade show" role="alert"><span>There isn't an account associated with this email. A signup link will be sent upon submission.</span></div>
                                                </Col>
                                            </Row>
                                        } */}
                                    </Collapse>
                                </> : null
                            }

                            <hr />
                            <b>Mentorship Details</b>
                            <Row>
                                <Col xs="6">
                                    <FormGroup className={this.state.isValidStartDate}>
                                        <Label for="mentorship-startDate">Start Date</Label>
                                        <Input 
                                            type="date" 
                                            name="startDate" 
                                            value = {this.state.startDate}
                                            onChange = {e => this.onChange(e)}
                                            onBlur = {e => this.onChange(e)}
                                            id="mentorship-startDate"
                                            />
                                    </FormGroup>
                                </Col>
                                <Col xs="6">
                                    <FormGroup className={this.state.isValidEndDate}>
                                        <Label for="mentorship-endDate">End Date</Label>
                                        <Input 
                                            type="date" 
                                            name="endDate" 
                                            value = {this.state.endDate}
                                            onChange = {e => this.onChange(e)}
                                            onBlur = {e => this.onChange(e)}
                                            id="mentorship-endDate"
                                            invalid = {this.state.isValidEndDate !== ''}
                                            />
                                        <FormFeedback>Cannot be before start date</FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>     
                        </Form>

                        <Modal isOpen={this.state.confirmationModal !== null} toggle={() => this.onConfirmationCancel()} size="sm">
                            <ModalHeader tag="h4" toggle={() => this.onConfirmationCancel()}>{this.state.confirmationModal === "submit"? "Submit" : "Cancel"} Confirmation</ModalHeader>
                            <ModalBody>
                                <center>
                                    Are you sure you would like to {this.state.confirmationModal === "submit"? "submit?" : "cancel?"}
                                </center>
                            </ModalBody>
                            <ModalFooter>
                                <i className="tim-icons icon-check-2" onClick={() => this.onConfirmationConfirm()}/>
                                <i className="tim-icons icon-simple-remove" onClick={() => this.onConfirmationCancel()}/>
                                {this.state.errorConfirmModal && <p>{this.state.errorConfirmModal.message}</p>}
                            </ModalFooter>
                        </Modal>
                        {this.state.errorCreateModal && <>{this.state.errorCreateModal.message}</>}
                    </ModalBody>
                    <ModalFooter>
                        <i className="tim-icons icon-check-2" onClick={() => this.onSubmit()}/>
                        <i className="tim-icons icon-simple-remove" onClick={() => this.closeCreateModal()}/>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}


/***************************************************************************************/
/*************************** Mentorship Relationship Delete ****************************/
/***************************************************************************************/

class MentorshipRelationshipDeleteModalBase extends Component {
    constructor(props) {
        super(props);

        this.state={cancelDocID: null}
    }

    // Confirm the feedback cancellation
    onCancelConfirmationConfirm = () => {
        this.props.firebase.doDeleteMentorshipRelationshipByID(this.state.cancelDocID)
        .then(() => {
            this.setState({ cancelDocID: null });
            this.props.refreshPage();
        })
    }

    // Close the feedback cancellation modal
    onCancelConfirmationCancel = () => {
        this.setState({ cancelDocID: null });
    }

    render() {

        return (
            <>
                <i className="tim-icons icon-trash-simple" 
                    alt="Delete Mentorship Relationship"
                    onClick={() => {
                        this.setState({ cancelDocID: this.props.feedbackDocID });
                }} />

                <Modal isOpen={ this.state.cancelDocID !== null } toggle={() => this.onCancelConfirmationCancel()} size="sm">
                    <ModalHeader tag="h4" toggle={() => this.onCancelConfirmationCancel()}>Confirmation Cancellation</ModalHeader>
                    <ModalBody>
                        <center>
                            Are you sure you would like to cancel this mentorship relationship?
                        </center>
                    </ModalBody>
                    <ModalFooter>
                        <i className="tim-icons icon-check-2" onClick={() => this.onCancelConfirmationConfirm()} />
                        <i className="tim-icons icon-simple-remove" onClick={() => this.onCancelConfirmationCancel()} />
                        {this.state.errorCancelConfirmModal && <p>{this.state.errorCancelConfirmModal.message}</p>}
                    </ModalFooter>
                </Modal>
            </>
        );
    };
};

/***************************************************************************************/
/*************************** Mentorship Render Single Mentor ***************************/
/***************************************************************************************/

class RenderSingleMentor extends Component {
    constructor(props) {
        super(props);
        //props.uid
        //props.mDoc
        //props.refreshPage
        //props.isCurrent
        //props.didLoad
        this.state={
            mentorFirstName: props.mDoc.data.mentorFirstName,
            mentorLastName: props.mDoc.data.mentorLastName,
            isPendingApproval: props.mDoc.data.isPendingApproval,
            startDate: props.mDoc.data.startDate,
            endDate: props.mDoc.data.endDate,
            sessionFrequency: props.mDoc.data.sessionFrequency,
            generalNotesMentee: props.mDoc.data.generalNotesMentee,
            nextSessionID: props.mDoc.data.nextSessionID,
            lastSessionID: props.mDoc.data.lastSessionID,
            childrenToLoad: {
                NextSessionDetails: props.mDoc.data.nextSessionID === undefined,
                LastSessionSummary: props.mDoc.data.lastSessionID === undefined,
                SessionHistory: false
            },
        }

        this.childrenDidLoad = this.childrenDidLoad.bind(this);
    }

    isRenderComplete() {
        var isRenderCompleted = !this.state.loading;
        for (var key in this.state.childrenToLoad) {
            isRenderCompleted &= this.state.childrenToLoad[key];
            //console.log(key + " : " + this.state.childrenToLoad[key]);
        }
        this.setState({ rendering: !isRenderCompleted });
        if (isRenderCompleted && this.props.didLoad !== undefined) {
            //console.log("load complete, calling didLoad()");
            this.props.didLoad();
        }
    }

    childrenDidLoad(component) {
        var { childrenToLoad } = this.state;
        childrenToLoad[component] = true;
        this.setState( { childrenToLoad: childrenToLoad } 
                        ,() => { this.isRenderComplete();  })
    }

    render() {
        return (
            <>
                <Card key={"mentorship_relationship_mentor_"+this.props.mDoc.id}>
                    <CardHeader>
                        <h5 className="card-category">Your Mentor</h5>
                        <CardTitle tag="h2">{this.state.mentorFirstName + " " + this.state.mentorLastName}</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col xs="12" md="6">
                                <NextSessionDetails 
                                    renderFor="mentor" 
                                    uid={this.props.uid} 
                                    sessionID={this.state.nextSessionID} 
                                    mentorshipID={this.props.mDoc.id} 
                                    refreshPage={this.props.refreshPage} 
                                    didLoad={() => this.childrenDidLoad("NextSessionDetails")} />
                                <LastSessionSummary 
                                    renderFor="mentor" 
                                    uid={this.props.uid} 
                                    sessionID={this.state.lastSessionID} 
                                    refreshPage={this.props.refreshPage} 
                                    didLoad={() => this.childrenDidLoad("LastSessionSummary")} />
                            </Col>
                            <Col xs="12" md="6">
                                <SessionHistory 
                                    renderFor="mentor" 
                                    uid={this.props.uid} 
                                    lastSessionID={this.state.lastSessionID} 
                                    mentorshipID={this.props.mDoc.id} 
                                    refreshPage={this.props.refreshPage} 
                                    numSessionsToLoad={10} 
                                    didLoad={() => this.childrenDidLoad("SessionHistory")} />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </>
        );
    };
};


/***************************************************************************************/
/*************************** Mentorship Render Single Mentor ***************************/
/***************************************************************************************/
class RenderSingleMentee extends Component {
    constructor(props) {
        super(props);
        //props.uid
        //props.mDoc
        //props.isCurrent
        //props.didLoad
        this.state={
            menteeFirstName: props.mDoc.data.menteeFirstName,
            menteeLastName: props.mDoc.data.menteeLastName,
            isPendingApproval: props.mDoc.data.isPendingApproval,
            startDate: props.mDoc.data.startDate,
            endDate: props.mDoc.data.endDate,
            sessionFrequency: props.mDoc.data.sessionFrequency,
            generalNotesMentee: props.mDoc.data.generalNotesMentee,
            nextSessionID: props.mDoc.data.nextSessionID,
            lastSessionID: props.mDoc.data.lastSessionID,
            childrenToLoad: {
                NextSessionDetails: props.mDoc.data.nextSessionID === undefined,
                LastSessionSummary: props.mDoc.data.lastSessionID === undefined,
                SessionHistory: false
            },
        }
        
        this.childrenDidLoad = this.childrenDidLoad.bind(this);
    }

    isRenderComplete() {
        var isRenderCompleted = !this.state.loading;
        for (var key in this.state.childrenToLoad) {
            isRenderCompleted &= this.state.childrenToLoad[key];
            //console.log(key + " : " + this.state.childrenToLoad[key]);
        }
        this.setState({ rendering: !isRenderCompleted });
        if (isRenderCompleted && this.props.didLoad() !== undefined) this.props.didLoad();
    }

    childrenDidLoad(component) {
        var { childrenToLoad } = this.state;
        childrenToLoad[component] = true;
        this.setState( { childrenToLoad: childrenToLoad } 
                        ,() => { this.isRenderComplete();  })
    }

    render() {
        console.log("start")
        return (
            <>
                <Card key={"mentorship_relationship_mentee_"+this.props.mDoc.id}>
                    <CardHeader>
                        <h5 className="card-category">Your Mentee</h5>
                        <CardTitle tag="h2">{this.state.menteeFirstName + " " + this.state.menteeLastName}</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col xs="12" md="6">
                                <NextSessionDetails 
                                    renderFor="mentee" 
                                    uid={this.props.uid} 
                                    sessionID={this.state.nextSessionID} 
                                    mentorshipID={this.props.mDoc.id} 
                                    refreshPage={this.props.refreshPage}
                                    didLoad={() => this.childrenDidLoad("NextSessionDetails")} />
                                <LastSessionSummary 
                                    renderFor="mentee" 
                                    uid={this.props.uid} 
                                    sessionID={this.state.lastSessionID} 
                                    refreshPage={this.props.refreshPage}
                                    didLoad={() => this.childrenDidLoad("LastSessionSummary")} />
                            </Col>
                            <Col xs="12" md="6">
                                <SessionHistory 
                                    renderFor="mentee" 
                                    uid={this.props.uid} 
                                    lastSessionID={this.state.lastSessionID} 
                                    mentorshipID={this.props.mDoc.id} 
                                    refreshPage={this.props.refreshPage} 
                                    numSessionsToLoad={10}
                                    didLoad={() => this.childrenDidLoad("SessionHistory")} />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </>
        );
    };
};

/********************************************************************************/
/******************************* Mentee Summary *******************************/
/********************************************************************************/

const INITIAL_STATE_SUMMARY_MENTEE = {
    loading: true,
    rendering: true,
    changed: false,
    mDocs: null,
    error: null,
    childrenToLoad: {
        ActiveGoals: false,
        CompletedGoals: false,
        RenderSingleMentor: false,
        MentorshipRelationshipCreateModal: false
    },
};

class MentorshipMenteeSummaryBase extends Component {
    constructor(props) {
        super(props);
        this.state = {...INITIAL_STATE_SUMMARY_MENTEE};
        this.loadData = this.loadData.bind(this);
        this.childrenDidLoad = this.childrenDidLoad.bind(this);

        // props.uid; // UID of user provided by parent component through auth context
        // props.email; // email of user provided by parent component through auth context
        // props.didLoad;
    }

    // Loads data from Firebase and sets loading state to false
    loadData = () => {
        this.setState({ loading: true });

        this.props.firebase.doGetMentorshipRelationshipsByMenteeID(this.props.uid)
        .then(QuerySnapshot => {
            let mappedQS = this.mapData(QuerySnapshot);
            this.setState({ mDocs: mappedQS, loading: false }
            , () => {this.isRenderComplete()});
            if ( mappedQS.length === 0 ) {
                let tmpCTL = this.state.childrenToLoad;
                tmpCTL.RenderSingleMentor = true;
                this.setState({ childrenToLoad: tmpCTL });
            }
        }).catch(error => {
            console.log(error);
            this.setState({error});
        }); 
    }
    
    isRenderComplete() {
        var isRenderCompleted = !this.state.loading;
        for (var key in this.state.childrenToLoad) {
            isRenderCompleted &= this.state.childrenToLoad[key];
            //console.log(key + " : " + this.state.childrenToLoad[key]);
        }
        this.setState({ rendering: !isRenderCompleted });
        if (isRenderCompleted && this.props.didLoad !== undefined) {
            //console.log("load complete, calling didLoad()");
            this.props.didLoad();
        }
    }

    childrenDidLoad(component) {
        var { childrenToLoad } = this.state;
        childrenToLoad[component] = true;
        this.setState( { childrenToLoad: childrenToLoad } 
                        ,() => { this.isRenderComplete();  })
    }

    // Helper function to parse the return from firestore
    mapData = resultArray => {
        const returnArray = [];
        resultArray.forEach(doc => {
            returnArray.push({
                id: doc.id,
                data: doc.data(),
            });
        })
        return returnArray;
    };

    
    // Load mentorship data from fs on load
    componentDidMount() {
        this.loadData();
    }

    onChange = event => {
        this.setState({changed: true, [event.target.name]: event.target.value});
    };

    render() {
        if (this.state && !this.state.loading) {
            const {
                error,
            } = this.state;
    
            //const isInvalid = this.state === INITIAL_STATE;

            return (
                <>
                    {/* TODO: Summary Box */ }
                    <Row>
                        <Col md="6" sm="12">
                            <Card className="card-tasks">
                                <CardHeader>
                                    <h5 className="card-category">Summary Of Your. . .</h5>
                                    {/* <CardTitle tag="h2">Active Goals</CardTitle> */}
                                    <Row>
                                        <Col xs="10">
                                            <CardTitle tag="h4">Active Goals</CardTitle>
                                        </Col>
                                        <Col xs="2" className="text-right">
                                            <GoalItemCreateEditModal uid={this.props.uid} refreshPage={this.loadData} />
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <ActiveGoals 
                                        uid={this.props.uid}
                                        didLoad={() => this.childrenDidLoad("ActiveGoals")} />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="6" sm="12">
                            <Card className="card-tasks">
                                <CardHeader>
                                    <h5 className="card-category">Summary Of Your. . .</h5>
                                    {/* <CardTitle tag="h2">Completed Goals</CardTitle> */}
                                    <CardTitle tag="h4">Completed Goals</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <CompletedGoals 
                                        uid={this.props.uid}
                                        didLoad={() => this.childrenDidLoad("CompletedGoals")} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {/* TODO: Current Mentors */ }
                    { this.state.mDocs.map(dS =>
                        <RenderSingleMentor 
                            uid={this.props.uid}
                            mDoc={dS}
                            refreshPage={this.loadData}
                            key={"menteesummary_rendersinglementor_"+dS.id}
                            didLoad={() => this.childrenDidLoad("RenderSingleMentor")} />
                    )}

                    {/* TODO: Add Mentor Button */ }

                    <MentorshipRelationshipCreateModal 
                        uid={this.props.uid} 
                        email={this.props.email} 
                        refreshPage={this.loadData}
                        requestor="mentee"
                        buttonText="New Mentor Request"
                        didLoad={() => this.childrenDidLoad("MentorshipRelationshipCreateModal")} />

                    {/* TODO: Past Mentor */ }


                    {error && <p>{error.message}</p>}
                </>
            );
        }

        // if state not set yet
        return null;
    };
};


/********************************************************************************/
/******************************* Mentor Summary *******************************/
/********************************************************************************/

const INITIAL_STATE_SUMMARY_MENTOR = {
    rendering: true,
    loading: true,
    changed: false,
    childrenToLoad: {
        ActiveActionItems: false,
        CompletedActionItems: false,
        RenderSingleMentee: false,
        MentorshipRelationshipCreateModal: false
    },
    feedbackRequestorDocs: null,
    feedbackModalCurrentDoc: null,
    feedbackModalState: null,

    formVersion: '',
    feedbackGeneral: '',

    confirmationModal: null,
    cancelDocID: null,
    error: null,
    errorConfirmModal: null,
    errorCancelConfirmModal: null,
    errorViewFeedbackModal: null,
    errorCompleteFeedbackModal: null,
};

class MentorshipMentorSummaryBase extends Component {
    constructor(props) {
        super(props);
        this.state = {...INITIAL_STATE_SUMMARY_MENTOR};
        
        this.loadData = this.loadData.bind(this);
        this.childrenDidLoad = this.childrenDidLoad.bind(this);

        // props.uid; // UID of user provided by parent component through auth context
        // props.email; // email of user provided by parent component through auth context
    }

    // Loads data from Firebase and sets loading state to false
    loadData = () => {
        this.setState({ loading: true, rendering: true });

        this.props.firebase.doGetMentorshipRelationshipsByMentorID(this.props.uid)
        .then(QuerySnapshot => {
            let mappedQS = this.mapData(QuerySnapshot);
            this.setState({ mDocs: mappedQS, loading: false }
                            , () => {this.isRenderComplete()});
            if (mappedQS.length === 0) {
                let tmpCTL = this.state.childrenToLoad;
                tmpCTL.RenderSingleMentee = true;
                this.setState({ childrenToLoad: tmpCTL });
            }
        }).catch(error => {
            console.log(error);
            this.setState({error});
        }); 
    }

    // Helper function to parse the return from firestore
    mapData = resultArray => {
        const returnArray = [];
        resultArray.forEach(doc => {
            returnArray.push({
                id: doc.id,
                data: doc.data(),
            });
        })
        return returnArray;
    };

    isRenderComplete() {
        var isRenderCompleted = !this.state.loading;
        for (var key in this.state.childrenToLoad) {
            isRenderCompleted &= this.state.childrenToLoad[key];
            //console.log(key + "-mentor : " + this.state.childrenToLoad[key]);
        }
        this.setState({ rendering: !isRenderCompleted });
        if (isRenderCompleted && this.props.didLoad !== undefined) this.props.didLoad();
    }

    childrenDidLoad(component) {
        var { childrenToLoad } = this.state;
        childrenToLoad[component] = true;
        this.setState( { childrenToLoad: childrenToLoad } 
                        ,() => { this.isRenderComplete();  })
    }
    
    // Load mentorship data from fs on load
    componentDidMount() {
        this.loadData();
    }

    onChange = event => {
        this.setState({changed: true, [event.target.name]: event.target.value});
    };

    render() {
        if (this.state && !this.state.loading) {
            const {
                error,
            } = this.state;
    
            //const isInvalid = this.state === INITIAL_STATE;

            return (
                <>
                    {/* TODO: Summary Box */ }

                    <Row>
                        <Col md="6" sm="12">
                            <Card className="card-tasks">
                                <CardHeader>
                                    <h5 className="card-category">Summary Of Your. . .</h5>
                                    <Row>
                                        <Col xs="10">
                                            <CardTitle tag="h4">Active Action Items</CardTitle>
                                        </Col>
                                        <Col xs="2" className="text-right">
                                            <ActionItemCreateEditModal 
                                                uid={this.props.uid} 
                                                refreshPage={this.loadData} />
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <ActiveActionItems 
                                        uid={this.props.uid}
                                        didLoad={() => this.childrenDidLoad("ActiveActionItems")} />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="6" sm="12">
                            <Card className="card-tasks">
                                <CardHeader>
                                    <h5 className="card-category">Summary Of Your. . .</h5>
                                    <CardTitle tag="h4">Completed Action Items</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <CompletedActionItems 
                                        uid={this.props.uid} 
                                        didLoad={() => this.childrenDidLoad("CompletedActionItems")} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {/* TODO: Current Mentees */ }
                    { this.state.mDocs.map(dS =>
                        <RenderSingleMentee 
                            uid={this.props.uid}
                            mDoc={dS}
                            refreshPage={this.loadData}
                            key={"mentorsummary_rendersinglementee_"+dS.id}
                            didLoad={() => this.childrenDidLoad("RenderSingleMentee")} />
                    )}

                    {/* TODO: Add Mentee Button */ }

                    
                    <MentorshipRelationshipCreateModal 
                        uid={this.props.uid} 
                        email={this.props.email} 
                        refreshPage={this.loadData}
                        requestor="mentor"
                        buttonText="New Mentee Request"
                        didLoad={() => this.childrenDidLoad("MentorshipRelationshipCreateModal")} />

                    {/* TODO: Past Mentees */ }

                    {error && <p>{error.message}</p>}
                </>
            );
        }

        // While data is being loaded from database
        return null;
    };
};

const MentorshipMenteeSummary = withFirebase(MentorshipMenteeSummaryBase);
const MentorshipMentorSummary = withFirebase(MentorshipMentorSummaryBase);
const MentorshipRelationshipCreateModal = withFirebase(MentorshipRelationshipCreateModalBase);
const MentorshipRelationshipDeleteModal = withFirebase(MentorshipRelationshipDeleteModalBase);

export {MentorshipMenteeSummary, 
        MentorshipMentorSummary, 
        MentorshipRelationshipCreateModal, 
        MentorshipRelationshipDeleteModal};