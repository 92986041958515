import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import {withFirebase} from '../Firebase/Firebase';
import * as ROUTES from '../../constants/routes';

import {
    Container,
    Button, 
    Form, 
    FormGroup, 
    Label, 
    Input,
} from 'reactstrap';

const INITIAL_STATE = {
    email: '',
    error: null,
    validToSubmit: false,
}

class PasswordForgetFormBase extends Component {
    state = {...INITIAL_STATE};

    onSubmit = event => {
        const {email} = this.state;

        this.props.withFirebase
            .doPasswordReset(email)
            .then(() => {
                this.setState({...INITIAL_STATE});
            })
            .catch(error => {
                this.setState({error});
            });

            event.preventDefault();
    }

    validateEmail() {
        let isValid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(this.state.email);
        this.setState({ validToSubmit: isValid });
    }

    onChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        }, () => {
            this.validateEmail();
        });
    };

    render() {
        const {email, error} = this.state;
        return (
            <>
                <Container>
                    <Form onSubmit={this.onSubmit} className="form-pwdreset">
                        <h2>Forgotten Password?</h2>
                        <FormGroup>
                            <Label for="pwdreset-email" className="sr-only">Email</Label>
                            <Input
                                id="pwdreset-email"
                                name = "email"
                                value = {email}
                                onChange = {e => this.onChange(e)}
                                type = "text"
                                placeholder = "Email Address"
                                required
                            />
                        </FormGroup>
                        <Button block color="primary" disabled={!this.state.validToSubmit}>Reset Now</Button>
                    </Form>
                </Container>
                {error && <><br/><p>{error.message}</p></>}
            </>
        );
    };
};

const PasswordForgetLink = () => (
    <Link to={ROUTES.PASSWORD_FORGET} className="nav-link">Forgot your password?</Link>
);

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export default PasswordForgetForm;
export {PasswordForgetLink};