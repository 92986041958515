import React, {Component} from 'react';
import {withFirebase} from '../../Firebase/Firebase';
import moment from 'moment';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import Achievements from '../Achievement/Achievements';
import ProjectFeedbackSummary from '../../Feedback/Project_Feedback';

import {
    Form, 
    FormGroup, 
    Label, 
    Input, 
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Card,
    CardHeader,
    CardBody,
    Collapse,
    FormFeedback,
    Button,
    UncontrolledTooltip
} from 'reactstrap';


/*********************************************************************************/
/***************************** Render All Project Card ***************************/
/*********************************************************************************/

const INITIAL_STATE_RENDER = {
    loading: true,
    rendering: true,
    ProjectDocs: null,
    error: null,
    numComponentsToRender: 0,
    componentsRendered: 0,
}

class RenderAllProjectCardsBase extends Component {
    constructor(props) {
        super(props);
        this.loadData = this.loadData.bind(this);
        this.childrenDidLoad = this.childrenDidLoad.bind(this);

        // props.uid
        // props.email
        // props.positionDocID
        // props.didLoad
        this.state = {...INITIAL_STATE_RENDER};
    }

        // Helper function to parse the return from firestore
        mapData = resultArray => {
            const returnArray = [];
            resultArray.forEach(doc => {
                returnArray.push({
                    id: doc.id,
                    data: doc.data(),
                });
            })
            return returnArray;
        };
        
        loadData = () => {
            this.setState({ loading: true });
    
            this.props.firebase.doGetProjectEntries(this.props.positionDocID)
            .then(QuerySnapshots => {
                var returnArray = [];
                QuerySnapshots.forEach(DocumentSnapshot => returnArray.push(DocumentSnapshot));
                this.setState({ ProjectDocs: this.mapData(returnArray), loading: false, numComponentsToRender: returnArray.length });
                this.isRenderComplete();
            }).catch(error => {
                console.log(error);
                this.setState({error});
            }); 
        }

        isRenderComplete() {
            var isRenderCompleted = !this.state.loading;
            isRenderCompleted &= this.state.componentsRendered >= this.state.numComponentsToRender;
            this.setState({ rendering: !isRenderCompleted });
            if (isRenderCompleted && this.props.didLoad !== undefined) {
                this.props.didLoad();
            }
        }
    
        childrenDidLoad() {
            var { componentsRendered } = this.state;
            componentsRendered++;
            this.setState( { componentsRendered: componentsRendered } 
                            ,() => { this.isRenderComplete();  })
        }
    
        // Retrieve organization query snapshot
        componentDidMount() {
            this.loadData();
        }

        render() {
            if (this.state && !this.state.loading) {
                if (this.state.ProjectDocs === undefined) return null;
                else {
                    return (
                        <div>
                            { this.state.ProjectDocs.map(doc => (<ProjectCard key={"Project_card_" + doc.id} 
                                                                            projectDocID={doc.id} 
                                                                            projectDoc={doc.data} 
                                                                            uid={this.props.uid}
                                                                            email={this.props.email}
                                                                            refreshPage={this.loadData}
                                                                            didLoad={this.childrenDidLoad} />)) }
                            {this.state.error && <p>{this.state.error.message}</p>}
                        </div>
                    );
                }
            }
    
            return "loading Project records...";
        }

}


/*********************************************************************************/
/******************************* Project Card *******************************/
/*********************************************************************************/

const INITIAL_STATE_PROJECT_CARD = {
    collapse: true,
    error: null,
    rendering: false,
    childrenToLoad: {
        Achievements: false,
        ProjectFeedbackSummary: false
    },
};

class ProjectCardBase extends Component {
    constructor(props) {
        super(props);
        this.childrenDidLoad = this.childrenDidLoad.bind(this);
        
        // props.refreshPage
        // props.projectDocID
        // props.projectDoc
        // props.uid
        // props.didLoad
        /* Required Fields:
            name
            description
            startDate
            endDate
        */
        this.state = {...INITIAL_STATE_PROJECT_CARD};
    }

    // Toggle the visibility of the card body
    toggleCollapse = () => {
        this.setState(state => ({ collapse: !state.collapse }));
    }

    isRenderComplete() {
        var isRenderCompleted = true;
        for (var key in this.state.childrenToLoad) {
            isRenderCompleted &= this.state.childrenToLoad[key];
            //console.log(key + " : " + this.state.childrenToLoad[key]);
        }
        this.setState({ rendering: !isRenderCompleted });
        if (isRenderCompleted && this.props.didLoad !== undefined) {
          this.props.didLoad();
        }
    }

    childrenDidLoad(component) {
        var { childrenToLoad } = this.state;
        childrenToLoad[component] = true;
        console.log(childrenToLoad)
        this.setState( { childrenToLoad: childrenToLoad } 
                        ,() => { this.isRenderComplete();  })
    }

    render() {
        
        return (
            <>
                <Card key={this.props.projectDocID}>
                    <CardHeader className="card-no-top-padding">
                        <Row>
                            <Col xs="10">
                                    <h4 className="card-title">{this.props.projectDoc.name}</h4>
                                    <h5 className="card-category">{moment(this.props.projectDoc.startDate).format("MMM YYYY")} {this.props.projectDoc.endDate? " to " + moment(this.props.projectDoc.endDate).format("MMM YYYY") : " to Present"}</h5>
                                    <p>{this.props.projectDoc.description}</p>
                            </Col>
                            <Col xs="2" className="text-right">
                                <ProjectEditModal projectDocID={this.props.projectDocID} 
                                                projectDoc={this.props.projectDoc} 
                                                refreshPage={this.props.refreshPage} />
                                {/* <br/>
                                <i className={this.state.collapse? "tim-icons icon-minimal-up": "tim-icons icon-minimal-down" }
                                    alt="Show/Hide"
                                    onClick={() => this.toggleCollapse()} /> */}
                            </Col>
                        </Row>
                    </CardHeader>          
                    <Collapse isOpen={this.state.collapse}>
                        <CardBody>
                            <Achievements uid={this.props.uid}
                                                projectDocID={this.props.projectDocID}
                                                projectDoc={this.props.projectDoc}
                                                didLoad={() => this.childrenDidLoad("Achievements")} />
                            
                            <ProjectFeedbackSummary uid={this.props.uid}
                                                    email={this.props.email}
                                                    projectDocID={this.props.projectDocID}
                                                    projectDoc={this.props.projectDoc}
                                                    didLoad={() => this.childrenDidLoad("ProjectFeedbackSummary")}  />
                        </CardBody>
                    </Collapse>
                </Card>
            </>
        )
    }
}


/*********************************************************************************/
/******************************* Project Edit Modal *************************/
/*********************************************************************************/

const INITIAL_STATE_EDIT_PROJECT = {
    changed: false,
    validToSubmit: false,
    isValidName: '',
    isValidRole: '',
    isValidStartDate: '',
    isValidEndDate: '',

    name: '',
    role: '',
    description: '',
    startDate: '',
    endDate: '',
    editProjectanizationModal: false,
    confirmationModal: null,
    error: null,
};

class ProjectEditModalBase extends Component {
    constructor(props) {
        super(props);
        
        //props.refreshPage
        //projectDocID={this.props.projectDocID} 
        //projectDoc={this.props.projectDoc} 
        /* Required Fields:
            name
            startDate
            endDate
            
            Optional Fields:
            role
            description
        */

        INITIAL_STATE_EDIT_PROJECT.name = props.projectDoc.name;
        INITIAL_STATE_EDIT_PROJECT.role = props.projectDoc.role;
        INITIAL_STATE_EDIT_PROJECT.description = props.projectDoc.description;
        INITIAL_STATE_EDIT_PROJECT.startDate = props.projectDoc.startDate;
        if (props.projectDoc.endDate !== null)
            INITIAL_STATE_EDIT_PROJECT.endDate = props.projectDoc.endDate;

        this.state = {...INITIAL_STATE_EDIT_PROJECT};
    }

    // On change of the fields, update the state
    onChange = event => {
        event.persist();
        
        this.setState({
            [event.target.name]: event.target.value
        }, () => {
            this.setState({validToSubmit: this.validateSubmission(event.target.name)});
            this.isChanged();
        });
    };

    // Validate whether the field value(s) are valid to submit
    validateSubmission = fieldToValidate => {
        var isValid = true; 

        if (fieldToValidate === "name" || fieldToValidate === "all") {
            if (this.state.name === '') { 
                this.setState({isValidName: "has-danger"});
                isValid = false;
            } else this.setState({isValidName: ""});
        }
        if (fieldToValidate === "role" || fieldToValidate === "all") {
            if (this.state.role === '') { 
                this.setState({isValidRole: "has-danger"});
                isValid = false;
            } else this.setState({isValidRole: ""});
        }
        if (fieldToValidate === "startDate" || fieldToValidate === "all") {
            if (this.state.startDate === '') { 
                this.setState({isValidStartDate: "has-danger"});
                isValid = false;
            } else this.setState({isValidStartDate: ""});
        }
        if (fieldToValidate === "endDate" || fieldToValidate === "all") {
            if (this.state.endDate !== '' && this.state.endDate < this.state.startDate) { 
                this.setState({isValidEndDate: "has-danger"});
                isValid = false;
            } else this.setState({isValidEndDate: ""});
        }

        return isValid;
    }

    // Validate whether there are changes to the form
    isChanged() {
        var isChanged = INITIAL_STATE_EDIT_PROJECT.name !== this.state.name
        || INITIAL_STATE_EDIT_PROJECT.role !== this.state.role
        || INITIAL_STATE_EDIT_PROJECT.description !== this.state.description
        || INITIAL_STATE_EDIT_PROJECT.startDate !== this.state.startDate
        || INITIAL_STATE_EDIT_PROJECT.endDate !== this.state.endDate;

        this.setState({ changed: isChanged });
    }

    // Close button action for main edit modal
    closeEditModal = () => {
        if (this.state.changed) this.setState({confirmationModal: "cancel"});
        else this.setState({...INITIAL_STATE_EDIT_PROJECT});
    } 

    closeEditModalConfirmation = () => {
        this.setState({...INITIAL_STATE_EDIT_PROJECT});
    }

    // Delete confirmation button action for main edit modal
    deleteProjectConfirmation = () => {
        this.setState({confirmationModal: "delete"});
    }

    // Delete Project entry after confirmation
    deleteProjectEntry = () => {
        this.props.firebase.doDeleteProjectEntry(
            this.props.projectDocID
        ).then(() => {
            this.props.refreshPage();
            //this.setState({...INITIAL_STATE_EDIT_PROJECT});
        }).catch(error => {
            console.log(error);
            this.setState({error});
        }); 
    }

    // Creates the Project entry in 
    updateProjectEntry = () => {
        if (!this.state.changed) {
            this.closeEditModal();
        } else if (this.validateSubmission("all")) {
            this.props.firebase.doUpdateProjectEntry(
                this.props.projectDocID, 
                this.state.name,
                this.state.role,
                this.state.description,
                this.state.startDate,
                this.state.endDate
            ).then(() => {
                this.props.refreshPage();
                //this.setState({...INITIAL_STATE_EDIT_PROJECT});
            }).catch(error => {
                console.log(error);
                this.setState({error});
            }); 
        } else this.setState({error: new Error("There may be invalid values or missing required fields.")})
    }

    render() {
        
        return (
            <>

                <Button color="link"
                        id={"editProject"+this.props.projectDocID}
                        type="button" 
                        className="btn-left-spacing"
                        onClick={() => this.setState({editProjectanizationModal: true})} >
                    <i className="tim-icons icon-pencil" />
                </Button>
                <UncontrolledTooltip delay={0}
                                    target={"editProject"+this.props.projectDocID}
                                    placement="right" >
                    Edit Project
                </UncontrolledTooltip>

                

                <Modal isOpen={this.state.editProjectanizationModal} toggle={() => this.closeEditModal()} size="lg">
                    <ModalHeader tag="h4" toggle={() => this.closeEditModal()}>Edit Project</ModalHeader>
                    <ModalBody>
                        <Form id="Project-edit">
                            <FormGroup className={this.state.isValidName} >
                                <Label for="editProject-name">Name</Label>
                                <Input
                                    id="editProject-name"
                                    className="form-control"
                                    name = "name"
                                    value = {this.state.name}
                                    onChange = {e => this.onChange(e)}
                                    onBlur = {e => this.onChange(e)}
                                    type = "text"
                                    required
                                />
                            </FormGroup>
                            <FormGroup className={this.state.isValidRole} >
                                <Label for="editProject-role">Role</Label>
                                <Input
                                    id="editProject-role"
                                    className="form-control"
                                    name = "role"
                                    value = {this.state.role}
                                    onChange = {e => this.onChange(e)}
                                    onBlur = {e => this.onChange(e)}
                                    type = "text"
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="editProject-description">Description</Label>
                                <Input
                                    id="editProject-description"
                                    className="form-control"
                                    name = "description"
                                    value = {this.state.description}
                                    onChange = {e => this.onChange(e)}
                                    onBlur = {e => this.onChange(e)}
                                    type = "textarea"
                                    required
                                />
                            </FormGroup>
                            <Row>
                                <Col xs="6">
                                    <FormGroup className={this.state.isValidStartDate} >
                                        <Label for="editProject-startDate">Start Date</Label>
                                        <Input 
                                            type="date" 
                                            name="startDate" 
                                            value = {this.state.startDate}
                                            onChange = {e => this.onChange(e)}
                                    onBlur = {e => this.onChange(e)}
                                            id="editProject-startDate"
                                            />
                                    </FormGroup>
                                </Col>
                                <Col xs="6">
                                    <FormGroup className={this.state.isValidEndDate} >
                                        <Label for="editProject-endDate">End Date</Label>
                                        <Input 
                                            type="date" 
                                            name="endDate" 
                                            value = {this.state.endDate}
                                            onChange = {e => this.onChange(e)}
                                    onBlur = {e => this.onChange(e)}
                                            id="editProject-endDate"
                                            invalid={this.state.isValidEndDate!==''}
                                            />
                                        <FormFeedback>Cannot be before start date</FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>

                        <Modal isOpen={this.state.confirmationModal !== null} toggle={() => this.setState({confirmationModal: null})} size="sm">
                            <ModalHeader tag="h4">{this.state.confirmationModal === "cancel"? "Cancel": "Delete"} Confirmation</ModalHeader>
                            <ModalBody>
                                <center>
                                    {this.state.confirmationModal === "cancel"? 
                                        <>There are unsaved changes.<br />Are you sure you would like to cancel?</>
                                        : <>Are you sure you would like to delete this Project?<br />This change can not be undone.</>}
                                </center>
                            </ModalBody>
                            <ModalFooter>
                                {this.state.confirmationModal === "cancel"? 
                                    (<i className="tim-icons icon-check-2" onClick={() => this.closeEditModalConfirmation()}/>)
                                    :(<i className="tim-icons icon-check-2" onClick={() => this.deleteProjectEntry()}/>)}
                                    <i className="tim-icons icon-simple-remove" onClick={() => this.setState({confirmationModal: null})}/>
                            </ModalFooter>
                        </Modal>
                        
                        {this.state.error && <p>{this.state.error.message}</p>}
                    </ModalBody>
                    <ModalFooter>
                        <i className="tim-icons icon-trash-simple" onClick={() => this.deleteProjectConfirmation()}/>
                        <i className="tim-icons icon-check-2" onClick={() => this.updateProjectEntry()}/>
                        <i className="tim-icons icon-simple-remove" onClick={() => this.closeEditModal()}/>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}

/*********************************************************************************/
/****************************** Add Project Modal ***************************/
/*********************************************************************************/

const INITIAL_STATE_ADD_PROJECT = {
    addProjectModal: false,
    changed: false,
    validToSubmit: false,
    isValidName: '',
    isValidRole: '',
    isValidStartDate: '',
    isValidEndDate: '',

    name: '',
    role: '',
    description: '',
    startDate: '',
    endDate: '',
    confirmationModal: false,
    error: null,
};

class ProjectAddModalBase extends Component {
    constructor(props) {
        super(props);
        
        //props.refreshPage
        //uid={this.props.uid}
        //positionDocID={this.props.positionDocID}

        this.state = {...INITIAL_STATE_ADD_PROJECT};
    }

    // On change of the fields, update the state
    onChange = event => {
        event.persist();
        
        this.setState({
            [event.target.name]: event.target.value
        }, () => {
            this.setState({validToSubmit: this.validateSubmission(event.target.name)});
            this.isChanged();
        });
    };

    // Validate whether the field value(s) are valid to submit
    validateSubmission = fieldToValidate => {
        var isValid = true; 

        if (fieldToValidate === "name" || fieldToValidate === "all") {
            if (this.state.name === '') { 
                this.setState({isValidName: "has-danger"});
                isValid = false;
            } else this.setState({isValidName: ""});
        }
        if (fieldToValidate === "role" || fieldToValidate === "all") {
            if (this.state.role === '') { 
                this.setState({isValidRole: "has-danger"});
                isValid = false;
            } else this.setState({isValidRole: ""});
        }
        if (fieldToValidate === "startDate" || fieldToValidate === "all") {
            if (this.state.startDate === '') { 
                this.setState({isValidStartDate: "has-danger"});
                isValid = false;
            } else this.setState({isValidStartDate: ""});
        }
        if (fieldToValidate === "endDate" || fieldToValidate === "all") {
            if (this.state.endDate !== '' && this.state.endDate < this.state.startDate) { 
                this.setState({isValidEndDate: "has-danger"});
                isValid = false;
            } else this.setState({isValidEndDate: ""});
        }

        return isValid;
    }

    // Validate whether there are changes to the form
    isChanged() {
        var isChanged = INITIAL_STATE_ADD_PROJECT.name !== this.setState.name
        || INITIAL_STATE_ADD_PROJECT.role !== this.setState.role
        || INITIAL_STATE_ADD_PROJECT.description !== this.setState.description
        || INITIAL_STATE_ADD_PROJECT.startDate !== this.setState.startDate
        || INITIAL_STATE_ADD_PROJECT.endDate !== this.setStateendDate;

        this.setState({ changed: isChanged });
    }

    // Close button action for main feedback modal
    closeCreateModal = () => {
        if (this.state.changed) this.setState({confirmationModal: true});
        else this.setState({...INITIAL_STATE_ADD_PROJECT});
    } 

    // Creates the Project entry in 
    createProjectEntry = () => {
        if (this.validateSubmission("all")) {
            this.props.firebase.doAddProjectEntry(
                this.props.uid, 
                this.props.positionDocID,
                this.state.name, 
                this.state.role,
                this.state.description,
                this.state.startDate,
                this.state.endDate
            ).then(() => {
                this.props.refreshPage();
                //this.setState({...INITIAL_STATE_ADD_PROJECT});
            }).catch(error => {
                console.log(error);
                this.setState({error});
            }); 
        } else this.setState({error: new Error("There may be invalid values or missing required fields.")})
    }

    render() {
        
        return (
            <>
                <Button color="link"
                        id={"achievementPosition"+this.props.positionDocID}
                        type="button" 
                        className="btn-left-spacing"
                        onClick={() => this.setState({addProjectModal: true})} >
                    <i className="tim-icons icon-simple-add"  />
                </Button>
                <UncontrolledTooltip delay={0}
                                    target={"achievementPosition"+this.props.positionDocID}
                                    placement="right" >
                    Add Project
                </UncontrolledTooltip>
                                
                                
                                
                                

                <Modal isOpen={this.state.addProjectModal} toggle={() => this.closeCreateModal()} size="lg">
                    <ModalHeader tag="h4" toggle={() => this.closeCreateModal()}>Add Project</ModalHeader>
                    <ModalBody>
                        <Form id="Project-addNew">
                            <FormGroup className={this.state.isValidName} >
                                <Label for="addProject-name">Project Name</Label>
                                <Input
                                    id="addProject-name"
                                    className="form-control"
                                    name = "name"
                                    value = {this.state.name}
                                    onChange = {e => this.onChange(e)}
                                    onBlur = {e => this.onChange(e)}
                                    type = "text"
                                    required
                                />
                            </FormGroup>
                            <FormGroup className={this.state.isValidRole} >
                                <Label for="addProject-role">Role</Label>
                                <Input
                                    id="addProject-role"
                                    className="form-control"
                                    name = "role"
                                    value = {this.state.role}
                                    onChange = {e => this.onChange(e)}
                                    onBlur = {e => this.onChange(e)}
                                    type = "text"
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="addProject-description">Description</Label>
                                <Input
                                    id="addProject-description"
                                    className="form-control"
                                    name = "description"
                                    value = {this.state.description}
                                    onChange = {e => this.onChange(e)}
                                    onBlur = {e => this.onChange(e)}
                                    type = "textarea"
                                />
                            </FormGroup>
                            <Row>
                                <Col xs="6">
                                    <FormGroup className={this.state.isValidStartDate} >
                                        <Label for="addProject-startDate">Start Date</Label>
                                        <Input 
                                            type="date" 
                                            name="startDate" 
                                            value = {this.state.startDate}
                                            onChange = {e => this.onChange(e)}
                                    onBlur = {e => this.onChange(e)}
                                            id="addProject-startDate"
                                            />
                                    </FormGroup>
                                </Col>
                                <Col xs="6">
                                    <FormGroup className={this.state.isValidEndDate} >
                                        <Label for="addProject-endDate">End Date (or expected)</Label>
                                        <Input 
                                            type="date" 
                                            name="endDate" 
                                            value = {this.state.endDate}
                                            onChange = {e => this.onChange(e)}
                                    onBlur = {e => this.onChange(e)}
                                            id="addProject-endDate"
                                            invalid={this.state.isValidEndDate !== ''}
                                            />
                                        <FormFeedback>Cannot be before start date</FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>

                        <Modal isOpen={this.state.confirmationModal} toggle={() => this.setState({confirmationModal: false})} size="sm">
                            <ModalHeader tag="h4">Cancel Confirmation</ModalHeader>
                            <ModalBody>
                                <center>There are unsaved changes.<br />Are you sure you would like to cancel?</center>
                            </ModalBody>
                            <ModalFooter>
                                <i className="tim-icons icon-check-2" onClick={() => this.setState({...INITIAL_STATE_ADD_PROJECT})}/>
                                <i className="tim-icons icon-simple-remove" onClick={() => this.setState({confirmationModal: false})}/>
                            </ModalFooter>
                        </Modal>
                        
                        {this.state.error && <p>{this.state.error.message}</p>}
                    </ModalBody>
                    <ModalFooter>
                        <i className="tim-icons icon-simple-add" onClick={() => this.createProjectEntry()}/>
                        <i className="tim-icons icon-simple-remove" onClick={() => this.closeCreateModal()}/>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}


/************** Exports *****************/
const ProjectAdd = withFirebase(ProjectAddModalBase);
const ProjectCard = withFirebase(ProjectCardBase);
const ProjectEditModal = withFirebase(ProjectEditModalBase);
const RenderAllProjectCards = withFirebase(RenderAllProjectCardsBase);

export {RenderAllProjectCards, ProjectCard, ProjectAdd};