import React from "react";
import { NavLink } from "react-router-dom";
import { Nav } from "reactstrap";
import dashboardRoutesMap from "../../routes/dashboard";
import logo from "../../assets/img/plane-logo-sm.png";

import {AuthUserContext} from '../../Components/Session/Session';

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.activeRoute = this.activeRoute.bind(this);
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }

  // Renders the top portion of the menu with the logo and the brand
  renderTopBrand = () => {
    return (
      <div className="logo">
        {/* Logo image */}
        <a
          href={logo.outterLink}
          className="simple-text logo-mini"
          target="_blank"
          rel="noopener noreferrer"
          onClick={this.props.toggleSidebar}
        >
          <div className="logo-img">
            <img src={logo} alt="react-logo" />
          </div>
        </a>
        {/* logo text */}
        <a
          href={logo.outterLink}
          className="simple-text logo-normal"
          target="_blank"
          rel="noopener noreferrer"
          onClick={this.props.toggleSidebar}
        >
          CMD
        </a>
      </div>
    )
  }

  render() {
    const { bgColor } = this.props;

    return (
      <div className="sidebar" data={bgColor}>
        <div className="sidebar-wrapper" ref="sidebar">
          { /* this.renderTopBrand() */ }

          <AuthUserContext.Consumer>
            {authUser => (
              <Nav>
                {dashboardRoutesMap[authUser.role].map((prop, key) => {
                  return (
                    <li
                      className={this.activeRoute(prop.path)}
                      key={key}
                    >
                      <NavLink
                        to={prop.path}
                        className="nav-link"
                        activeClassName="active"
                        onClick={this.props.toggleSidebar}
                      >
                        <i className={prop.icon} />
                        <p>{prop.name}</p>
                      </NavLink>
                    </li>
                  );
                })}
              </Nav>
            )}
          </AuthUserContext.Consumer>
          
        </div>
      </div>
    );
  }
}

export default Sidebar;
