import React, {Component} from 'react';
import {withFirebase} from '../Firebase/Firebase';
import moment from 'moment';

import {
    Alert,
    Form, 
    FormGroup, 
    Label, 
    Input, 
    Row,
    Col,
    Card, 
    CardHeader,
    CardTitle,
    CardBody,
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    FormFeedback
} from 'reactstrap';
import {ActionIcon} from '../GlobalElements/Button';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';


/**********************************************************************************/
/********************************* certification History ******************************/
/**********************************************************************************/
/* This the modal that pops up on the certification history page to modify or edit    */
/* certification details                                                              */
/**********************************************************************************/

const INITIAL_STATE_CERTIFICATION_HISTORY = {
    loading: true,
    error: null,
    certDocs: null,
  };

class CertificationHistoryBase extends Component {
    constructor(props) {
        super(props);
        this.state = {...INITIAL_STATE_CERTIFICATION_HISTORY};
        this.loadData = this.loadData.bind(this);

        // props.uid
        // props.didLoad
    }

    // Load certification data from database
    componentDidMount() {
        this.loadData();
    }

    // Loads data from Firebase and sets loading state to false
    loadData = () => {
        this.props.firebase.doGetCertificationEntries(this.props.uid)
        .then(querySnapshot => {
            const certDocs = [];

            querySnapshot.forEach(doc => {
                certDocs.push({
                    id: doc.id,
                    data: doc.data(),
                });
            });
            this.setState({ certDocs, loading: false });
        })
        .then(() => {
            if (this.props.didLoad() !== undefined) this.props.didLoad();
        })
        .catch(error => {
            console.log("Error getting documents: ", error);
            this.setState({error});
        });
    }
    
    render() {
        if (this.state && !this.state.loading) {

            var numDocs =  this.state.certDocs.size;
            return (
                <div>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
                    <Card key={"certification_summary_card"}>
                        <CardHeader>
                            <Row>
                                <Col xs="10">
                                    <CardTitle tag="h2">Certifications</CardTitle>
                                </Col>
                                <Col xs="2" className="text-right">
                                    <CertificationEntry action="create"
                                                    uid={this.props.uid}
                                                    refreshPage={this.loadData} />
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            { numDocs !== 0? (
                                this.state.certDocs.map(doc => 
                                    <Card key={doc.id}>
                                        <CardHeader className="card-no-top-padding">
                                            <Row>
                                                <Col xs="10">
                                                    <h3 className="card-title">{doc.data.name}</h3>
                                                    <h5 className="card-category">{moment(doc.data.startDate).format("MMM YYYY")} {doc.data.endDate? " to " + moment(doc.data.endDate).format("MMM YYYY") : " to Present"}</h5>
                                                </Col>
                                                <Col xs="2" className="text-right">
                                                    <CertificationEntry action="edit" 
                                                                        doc={doc}
                                                                        uid={this.props.uid}
                                                                        refreshPage={this.loadData} />
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                        <CardBody>
                                            <Card>
                                                <CardHeader className="card-no-top-padding">
                                                    <h4 className="card-title">Issued By: {doc.data.authority}</h4>
                                                </CardHeader>
                                                {/* <CardBody>
                                                    <h5 className="card-title">Relevant Courses</h5>
                                                    <p>{doc.data.courses}</p>
                                                    <h5 className="card-title">Relevant Activities</h5>
                                                    <p>{doc.data.activities}</p>
                                                </CardBody> */}
                                            </Card>
                                        </CardBody>
                                    </Card>
                            )): ( <center><div>Add New Certification Entry <i className="tim-icons icon-spaceship" /></div></center> )}
                        </CardBody>
                    </Card>
                    {this.state.error && <p>{this.state.error.message}</p>}
                </div>
            );
        }

        // While data is being loaded from database
        return null;
    };
};


/**********************************************************************************/
/*************** Create/Delete/Remind/View certification Entry Modal **************/
/**********************************************************************************/
/* This the modal that pops up on the certification history page to modify or edit*/
/* certification details                                                          */
/**********************************************************************************/

const INITIAL_STATE_CERTIFICATION_ENTRY = {
    loading: true,
    changed: false,
    validToSubmit: false,
    mainModal: false, // true | false
    confirmationModal: null, // submit | cancel

    errorMainModal: null,
    errorConfirmModal: null,

    IsInvalidName: '',              // stores the style id, 'has-danger' if inValid
    isInvalidAuthority: '',         // stores the style id, 'has-danger' if inValid
    isInvalidLicenseNumber: '',     // stores the style id, 'has-danger' if inValid
    isInvalidURL: '',               // stores the style id, 'has-danger' if inValid
    isInvalidStartDate: '',         // stores the style id, 'has-danger' if inValid
    isInvalidEndDate: '',           // stores the style id, 'has-danger' if inValid

    name: '',
    authority: '',
    licenseNumber: '',
    URL: '',
    doesNotExpire: false,
    startDate: '',
    endDate: '',
  };

class CertificationEntryBase extends Component {
    constructor(props) {
        super(props);
        this.state = {...INITIAL_STATE_CERTIFICATION_ENTRY};

        this.onSubmit = this.onSubmit.bind(this);
        this.onSubmitConfirm = this.onSubmitConfirm.bind(this);
        this.openMainModal = this.openMainModal.bind(this);
        this.closeMainModal = this.closeMainModal.bind(this);
        this.closeConfirmationModal = this.closeConfirmationModal.bind(this);
        this.closeMainModalConfirmation = this.closeMainModalConfirmation.bind(this);

        // props.action // CREATE | EDIT
        // props.refreshPage
        // props.uid; // UID of user provided by parent component through auth context
        // props.email; // email of user provided by parent component through auth context
        // props.buttonText // optional parameter to add text after the + symbol
        // props.renderFull // Renders the full button version

        // FOR Action === EDIT
        // props.doc
        
        // FOR Action === CREATE
        // props.uid
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.setState({ ...INITIAL_STATE_CERTIFICATION_ENTRY });
        if (this.props.action.toLowerCase() === "create"){
             // blank values if create
        } else if (this.props.action.toLowerCase() === "edit") {
            this.setState({ name: this.props.doc.data.name });
            this.setState({ authority: this.props.doc.data.authority });
            this.setState({ licenseNumber: this.props.doc.data.licenseNumber });
            this.setState({ URL: this.props.doc.data.URL });
            this.setState({ doesNotExpire: this.props.doc.data.doesNotExpire });
            this.setState({ startDate: this.props.doc.data.startDate });
            this.setState({ endDate: this.props.doc.data.endDate });
        } else throw new Error ("Invalid action provided to EduationEntry component")
        this.setState({ loading: false });
    }

    // opens the main modal
    openModal() {
        this.setState({ mainModal: true });
    }

    //opens the main review modal
    openMainModal() {
        this.setState({ mainModal: true })
    }

    //action for the main modal close action. Opens confirmation if there are changes
    closeMainModal() {
        if (this.state.changed) this.setState({ confirmationModal: 'cancel'}); // open the confirmation modal
        else {
            this.closeConfirmationModal();
            this.closeMainModalConfirmation();
            this.loadData();
        }
    }

    //closes the main review modal
    closeMainModalConfirmation() {
        this.closeConfirmationModal();
        this.setState({ mainModal: false });
        this.loadData();
    }

    // closes the confirmation modal
    closeConfirmationModal() {
        this.setState({ confirmationModal: null });
    }

    //when a user hits the submit action
    onSubmit(action) {
        // action = create | delete | reminder
        if (action === "create") {
            let isValidToSubmit = this.validateSubmission("all");
            this.setState({ validToSubmit: isValidToSubmit });
            if ( !isValidToSubmit ) {
                this.setState({ errorMainModal: new Error("There may be invalid values or missing required fields.")});
            } else {
                this.onSubmitConfirm("create");
            }
        } else if (action === "save") {
            if (!this.state.changed ) {
                this.closeConfirmationModal();
                this.closeMainModalConfirmation();
            } else {
                this.onSubmitConfirm("save");
            }
        } else if (action === "delete") {
            this.setState({ confirmationModal: 'delete' })
        } else {
            this.closeMainModal();
        }
    }
    // when a user confirms they want to submit
    onSubmitConfirm(action) {
        if (action === "save") {
            this.props.firebase.doUpdateCertificationEntry(
                this.props.doc.id,
                this.props.uid, 
                this.state.name,
                this.state.authority,
                this.state.licenseNumber,
                this.state.URL,
                this.state.doesNotExpire,
                this.state.startDate,
                this.state.endDate,
            )
            .then(() => {
                this.closeConfirmationModal();
                this.closeMainModalConfirmation();
                this.props.refreshPage();
            })
            .catch(error => {
                this.setState({errorMainModal: error});
            });
        } else if (action === "delete") {
            this.props.firebase.doDeleteCertificationEntry(this.props.doc.id)
            .then(() => {
                this.closeConfirmationModal();
                this.closeMainModalConfirmation();
                this.props.refreshPage();
            })
            .catch(error => {
                this.setState({errorConfirmModal: error});
            });
        } else if (action === "create") {
            this.props.firebase.doAddCertificationEntry(
                this.props.uid, 
                this.state.name,
                this.state.authority,
                this.state.licenseNumber,
                this.state.URL,
                this.state.doesNotExpire,
                this.state.startDate,
                this.state.endDate,
            )
            .then(() => {
                this.closeConfirmationModal();
                this.closeMainModalConfirmation();
                this.props.refreshPage();
            })
            .catch(error => {
                this.setState({errorConfirmModal: error});
            });
        }
    }

    onChange = event => {
        event.persist(); // allows event to be available to the callback

        this.setState({ 
            [event.target.name]: event.target.value
        }, () => {
            this.setState({validToSubmit: this.validateSubmission(event.target.name) });
            this.isChanged();
        });
    };

    // onChange handler for changing the state of the 'doesNotExpire' form button
    handleChangeDoesNotExpire() {
        this.setState({ doesNotExpire: !this.state.doesNotExpire })
    }

    // Validate whether the values of the form are valid
    validateSubmission = fieldToValidate => {
        var isValid = true;

        if (fieldToValidate === "name" || fieldToValidate === "all") {
            if (this.state.name === '') { 
                this.setState({IsInvalidName: "has-danger"});
                isValid = false;
            } else this.setState({IsInvalidName: ""});
        }
        
        if (fieldToValidate === "authority" || fieldToValidate === "all") {
            if (this.state.authority === '') { 
                this.setState({isInvalidAuthority: "has-danger"});
                isValid = false;
            } else this.setState({isInvalidAuthority: ""});
        }
        
        if (fieldToValidate === "startDate" || fieldToValidate === "all") {
            if (this.state.startDate === '') { 
                this.setState({isInvalidStartDate: "has-danger"});
                isValid = false;
            } else this.setState({isInvalidStartDate: ""});
        }
        
        if (fieldToValidate === "endDate" || fieldToValidate === "all") {
            if ((this.state.endDate === '' && !this.state.doesNotExpire) || (this.state.endDate !== '' && this.state.endDate<this.state.startDate)) { 
                this.setState({isInvalidEndDate: "has-danger"});
                isValid = false;
            } else this.setState({isInvalidEndDate: ""});
        }

        return isValid;
    }

    // Validate whether there are changes to the form
    isChanged() {
        let isChanged = false;
        if (this.props.action.toLowerCase() === "create"){
            isChanged = this.state.name !== ''
                    || this.state.authority !== ''
                    || this.state.licenseNumber !== ''
                    || this.state.URL !== ''
                    || this.state.startDate !== ''
                    || this.state.endDate !== '';
        } else if (this.props.action.toLowerCase() === "edit") {
            isChanged = this.state.name !== this.props.doc.data.name
                    || this.state.authority !== this.props.doc.data.authority
                    || this.state.licenseNumber !== this.props.doc.data.licenseNumber
                    || this.state.URL !== this.props.doc.data.URL
                    || this.state.startDate !== this.props.doc.data.startDate
                    || this.state.endDate !== this.props.doc.data.endDate;
        }
        this.setState({ changed: isChanged });
    }

    render() {
        if (this.state && !this.state.loading) {

            var actionIcon;
            var actionIconTooltipText;
            var actionIconUniqueID;
            if (this.props.action.toLowerCase() === "edit") {
                actionIcon = "tim-icons icon-pencil";
                actionIconTooltipText = "Edit Certification Entry";
                actionIconUniqueID = this.props.doc.id;
            } else if (this.props.action.toLowerCase() === "create") {
                actionIcon = "tim-icons icon-simple-add";
                actionIconTooltipText = "Add New Certification Entry";
                actionIconUniqueID = "create"
            }

            return (
                <>
                    <ActionIcon 
                        id={"certificationEntryBase"+actionIconUniqueID}
                        iconClassName={actionIcon}
                        toolTipText={actionIconTooltipText}
                        onClick={this.openMainModal}
                        iconText={this.props.buttonText}
                        />
                    
                    <Modal isOpen={this.state.mainModal} toggle={() => this.closeMainModal()}>
                        <ModalHeader tag="h4" toggle={() => this.closeMainModal()}>
                            { actionIconTooltipText }
                        </ModalHeader>
                        <ModalBody>
                        <Form className="form-modalForm">
                                <FormGroup className={this.state.IsInvalidName}>
                                    <Label for="cert-name">Certification Name</Label>
                                    <Input
                                        id="cert-name"
                                        className="form-control"
                                        name = "name"
                                        value = {this.state.name}
                                        onChange = {e => this.onChange(e)}
                                        onBlur = {e => this.onChange(e)}
                                        type = "text"
                                        required
                                    />
                                </FormGroup>
                                <FormGroup className={this.state.isInvalidAuthority}>
                                    <Label for="cert-authority">Certification Authority</Label>
                                    <Input
                                        id="cert-authority"
                                        className="form-control"
                                        name = "authority"
                                        value = {this.state.authority}
                                        onChange = {e => this.onChange(e)}
                                        onBlur = {e => this.onChange(e)}
                                        type = "type"
                                        required>
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="cert-URL">Certification URL</Label>
                                    <Input
                                        id="cert-URL"
                                        className="form-control"
                                        name = "URL"
                                        value = {this.state.URL}
                                        onChange = {e => this.onChange(e)}
                                        onBlur = {e => this.onChange(e)}
                                        type = "text"
                                    />
                                </FormGroup>
                                <FormGroup check>
                                    <Label for="cert-doesNotExpire" check>
                                        Certification does not expire
                                        <Input
                                            id="cert-doesNotExpire"
                                            name = "doesNotExpire"
                                            defaultChecked = {this.state.doesNotExpire}
                                            onClick = {() => this.handleChangeDoesNotExpire()}
                                            type = "checkbox"
                                        />
                                        {' '}
                                        <span className="form-check-sign">
                                            <span className="check"></span>
                                        </span>
                                    </Label>
                                </FormGroup>
                                <Row>
                                    <Col xs="6">
                                        <FormGroup className={this.state.isInvalidStartDate}>
                                            <Label for="cert-startDate">Start Date</Label>
                                            <Input 
                                                id="cert-startDate"
                                                type="month" 
                                                name="startDate" 
                                                value = {this.state.startDate}
                                                onChange = {e => this.onChange(e)}
                                                onBlur = {e => this.onChange(e)}
                                                />
                                        </FormGroup>
                                    </Col>
                                    <Col xs="6">
                                        {!this.state.doesNotExpire? 
                                            <FormGroup className={this.state.isInvalidEndDate}>
                                                <Label for="cert-endDate">End Date</Label>
                                                <Input 
                                                    id="cert-endDate"
                                                    type="month" 
                                                    name="endDate" 
                                                    value = {this.state.endDate}
                                                    onChange = {e => this.onChange(e)}
                                                    onBlur = {e => this.onChange(e)}
                                                    invalid = {this.state.isInvalidEndDate !== ''}
                                                    />
                                                <FormFeedback>Cannot be before start date</FormFeedback>
                                            </FormGroup>
                                            : null
                                        }
                                    </Col>
                                </Row>
                                <FormGroup className={this.state.isInvalidLicenseNumber}>
                                    <Label for="cert-licenseNumber">Certificate Number</Label>
                                    <Input
                                        id="cert-licenseNumber"
                                        className="form-control"
                                        name = "licenseNumber"
                                        value = {this.state.licenseNumber}
                                        onChange = {e => this.onChange(e)}
                                        onBlur = {e => this.onChange(e)}
                                        type = "text"
                                    />
                                </FormGroup>
                            </Form>

                            <Modal isOpen={this.state.confirmationModal === "delete"} toggle={this.closeConfirmationModal} size="sm">
                                <ModalHeader tag="h4" toggle={ this.closeConfirmationModal }>Delete Confirmation</ModalHeader>
                                <ModalBody>
                                    <center>Are you sure you want to delete this entry?<br />This action can not be undone.</center>
                                    {this.state.errorConfirmModal && <><br /><Alert color="danger">{this.state.errorConfirmModal.message}</Alert></>}
                                </ModalBody>
                                <ModalFooter>
                                <ActionIcon 
                                    id={"certificationModalSubmitConfirm"+actionIconUniqueID}
                                    iconClassName="tim-icons icon-check-2"
                                    toolTipText="Confirm deletion"
                                    onClick={() => this.onSubmitConfirm("delete")}
                                />
                                <ActionIcon 
                                    id={"certificationModalSubmitCancel"+actionIconUniqueID}
                                    iconClassName="tim-icons icon-simple-remove"
                                    toolTipText="Continue editing"
                                    onClick={this.closeConfirmationModal}
                                />
                                    
                                </ModalFooter>
                            </Modal>

                            <Modal isOpen={this.state.confirmationModal === "cancel"} toggle={this.closeConfirmationModal} size="sm">
                                <ModalHeader tag="h4" toggle={ this.closeConfirmationModal }>Cancel Confirmation</ModalHeader>
                                <ModalBody>
                                    <center>Are you sure you want to cancel?<br />There are unsaved changed.</center>
                                    {this.state.errorConfirmModal && <><br /><Alert color="danger">{this.state.errorConfirmModal.message}</Alert></>}
                                </ModalBody>
                                <ModalFooter>
                                    <ActionIcon 
                                        id={"certificationModalCancelConfirm"+actionIconUniqueID}
                                        iconClassName="tim-icons icon-trash-simple"
                                        toolTipText="Discard changes"
                                        onClick={this.closeMainModalConfirmation}
                                    />
                                    <ActionIcon 
                                        id={"certificationModalCancelCancel"+actionIconUniqueID}
                                        iconClassName="tim-icons icon-simple-remove"
                                        toolTipText="Continue editing"
                                        onClick={this.closeConfirmationModal}
                                    />
                                </ModalFooter>
                            </Modal>

                            {this.state.errorMainModal && <><br /><Alert color="danger">{this.state.errorMainModal.message}</Alert></>}
                        </ModalBody>
                        <ModalFooter>
                            { this.props.action.toLowerCase() === "create" ?
                                    <>
                                        <ActionIcon 
                                            id={"certificationModalSubmit"+actionIconUniqueID}
                                            iconClassName="tim-icons icon-check-2"
                                            toolTipText="Create certification entry"
                                            onClick={() => this.onSubmit("create")}
                                        />
                                    </>
                                : null
                            }
                            { this.props.action.toLowerCase() === "edit" ?
                                <>
                                    <ActionIcon 
                                        id={"certificationModalSave"+actionIconUniqueID}
                                        iconClassName="tim-icons icon-check-2"
                                        toolTipText="Save changes"
                                        onClick={() => this.onSubmit("save")}
                                    /> 
                                    <ActionIcon 
                                        id={"certificationModalSubmit"+actionIconUniqueID}
                                        iconClassName="tim-icons icon-trash-simple"
                                        toolTipText="Cancel request"
                                        onClick={() => this.onSubmit("delete")}
                                    />
                                </>
                                : null
                            }
                            <ActionIcon 
                                id={"certificationModalCancel"+actionIconUniqueID}
                                iconClassName="tim-icons icon-simple-remove"
                                toolTipText="Cancel and close"
                                onClick={this.closeMainModal}
                            />
                        </ModalFooter>
                    </Modal>
                </>

                // Dynamically geneate the list of year options for dropdown
                // let endYearOffset = 10;
                // let currentYear = (new Date()).getFullYear();
                // let startYears = [];
                // let endYears = [];
                // for(let x = currentYear; x >= 1950; x--) {
                //     startYears.push(x);
                // }
                // for(let x = (currentYear + endYearOffset); x >= 1950; x--) {
                //     endYears.push(x);
                // }
                // const startYearsList = startYears.map((x) => {return(<option key={x}>{x}</option>)});
                // const endYearsList = endYears.map((x) => {return(<option key={x}>{x}</option>)});


            )
        } else return null;
    }


}




const CertificationHistory = withFirebase(CertificationHistoryBase);
const CertificationEntry = withFirebase(CertificationEntryBase)

export default CertificationHistory;
export {CertificationEntry};