import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import {withFirebase} from '../Firebase/Firebase';
import * as ROUTES from '../../constants/routes';




class SignOutButtonBase extends Component {
    // constructor(props) {
    //     super(props);
    // };

    onSubmit = event => {
        this.props.firebase
            .doSignOut()
            .then(() => {
                this.props.history.push(ROUTES.SIGN_IN);
            })
            .catch(error => {
                this.setState({error});
            });
    
            event.preventDefault();
    };

    render() {
        return(
            <p onClick={this.onSubmit} className="nav-link">
                Sign Out
            </p>
        )
    }
}

const SignOutButton = withRouter(withFirebase(SignOutButtonBase));

export default SignOutButton;