import React, {Component} from 'react';
import {withFirebase} from '../Firebase/Firebase';
import moment from 'moment';

import {
    Alert,
    Form, 
    FormGroup, 
    Label, 
    Input, 
    Row,
    Col,
    Card, 
    CardHeader,
    CardTitle,
    CardBody,
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    FormFeedback
} from 'reactstrap';
import {ActionIcon} from '../GlobalElements/Button';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';


/**********************************************************************************/
/********************************* Education History ******************************/
/**********************************************************************************/
/* This the modal that pops up on the Education history page to modify or edit    */
/* education details                                                              */
/**********************************************************************************/

const INITIAL_STATE_EDUCATION_HISTORY = {
    loading: true,
    error: null,
    eduDocs: null,
  };

class EducationHistoryBase extends Component {
    constructor(props) {
        super(props);
        this.state = {...INITIAL_STATE_EDUCATION_HISTORY};
        this.loadData = this.loadData.bind(this);

        // props.uid
        // props.didLoad
    }

    // Load education data from database
    componentDidMount() {
        this.loadData();
    }

    // Loads data from Firebase and sets loading state to false
    loadData = () => {
        this.setState({ loading: true });
        this.props.firebase.doGetEducationEntries(this.props.uid)
        .then(querySnapshot => {
            const eduDocs = [];

            querySnapshot.forEach(doc => {
                eduDocs.push({
                    id: doc.id,
                    data: doc.data(),
                });
            });
            this.setState({ eduDocs, loading: false });
        })
        .then(() => {
            if (this.props.didLoad() !== undefined) this.props.didLoad();
        })
        .catch(error => {
            console.log("Error getting documents: ", error);
            this.setState({error});
        });
    }
    
    render() {
        if (this.state && !this.state.loading) {

            var numDocs =  this.state.eduDocs.size;
            return (
                <div>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
                    <Card key={"education_summary_card"}>
                        <CardHeader>
                            <Row>
                                <Col xs="10">
                                    <CardTitle tag="h2">Education</CardTitle>
                                </Col>
                                <Col xs="2" className="text-right">
                                    <EducationEntry action="create"
                                                    uid={this.props.uid}
                                                    refreshPage={this.loadData} />
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            { numDocs !== 0? (
                                this.state.eduDocs.map(doc => 
                                    <Card key={doc.id}>
                                        <CardHeader className="card-no-top-padding">
                                            <Row>
                                                <Col xs="10">
                                                    <h3 className="card-title">{doc.data.school}</h3>
                                                    <h5 className="card-category">{moment(doc.data.startDate).format("MMM YYYY")} {doc.data.endDate? " to " + moment(doc.data.endDate).format("MMM YYYY") : " to Present"}</h5>
                                                </Col>
                                                <Col xs="2" className="text-right">
                                                    <EducationEntry action="edit" 
                                                                    doc={doc}
                                                                    uid={this.props.uid}
                                                                    refreshPage={this.loadData} />
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                        <CardBody>
                                            <Card>
                                                <CardHeader className="card-no-top-padding">
                                                    <h4 className="card-title">{doc.data.degree}</h4>
                                                    <h5 className="card-category">{doc.data.educationLevel}</h5>
                                                </CardHeader>
                                                <CardBody>
                                                    <h5 className="card-title">Relevant Courses</h5>
                                                    <p>{doc.data.courses}</p>
                                                    <h5 className="card-title">Relevant Activities</h5>
                                                    <p>{doc.data.activities}</p>
                                                </CardBody>
                                            </Card>
                                        </CardBody>
                                    </Card>
                            )): ( <center><div>Add New Education Entry <i className="tim-icons icon-spaceship" /></div></center> )}
                        </CardBody>
                    </Card>
                    {this.state.error && <p>{this.state.error.message}</p>}
                </div>
            );
        }

        // While data is being loaded from database
        return null;
    };
};


/**********************************************************************************/
/***************** Create/Delete/Remind/View Education Entry Modal ****************/
/**********************************************************************************/
/* This the modal that pops up on the Education history page to modify or edit    */
/* education details                                                              */
/**********************************************************************************/

const INITIAL_STATE_EDUCATION_ENTRY = {
    loading: true,
    changed: false,
    validToSubmit: false,
    mainModal: false, // true | false
    confirmationModal: null, // submit | cancel

    errorMainModal: null,
    errorConfirmModal: null,

    IsInvalidSchool: '',            // stores the style id, 'has-danger' if inValid
    isInvalidEducationLevel: '',    // stores the style id, 'has-danger' if inValid
    isInvalidDegree: '',            // stores the style id, 'has-danger' if inValid
    isInvalidStartDate: '',         // stores the style id, 'has-danger' if inValid
    isInvalidEndDate: '',           // stores the style id, 'has-danger' if inValid

    school: '',
    educationLevel: '',
    degree: '',
    grade: '',
    startDate: '',
    endDate: '',
    courses: '',
    activities: '',
  };

class EducationEntryBase extends Component {
    constructor(props) {
        super(props);
        this.state = {...INITIAL_STATE_EDUCATION_ENTRY};

        this.onSubmit = this.onSubmit.bind(this);
        this.onSubmitConfirm = this.onSubmitConfirm.bind(this);
        this.openMainModal = this.openMainModal.bind(this);
        this.closeMainModal = this.closeMainModal.bind(this);
        this.closeConfirmationModal = this.closeConfirmationModal.bind(this);
        this.closeMainModalConfirmation = this.closeMainModalConfirmation.bind(this);

        // props.action // CREATE | EDIT
        // props.refreshPage
        // props.uid; // UID of user provided by parent component through auth context
        // props.email; // email of user provided by parent component through auth context
        // props.buttonText // optional parameter to add text after the + symbol
        // props.renderFull // Renders the full button version

        // FOR Action === EDIT
        // props.doc
        
        // FOR Action === CREATE
        // props.uid
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.setState({ ...INITIAL_STATE_EDUCATION_ENTRY });
        if (this.props.action.toLowerCase() === "create"){
             // blank values if create
        } else if (this.props.action.toLowerCase() === "edit") {
            this.setState({ school: this.props.doc.data.school });
            this.setState({ educationLevel: this.props.doc.data.educationLevel });
            this.setState({ degree: this.props.doc.data.degree });
            this.setState({ grade: this.props.doc.data.grade });
            this.setState({ startDate: this.props.doc.data.startDate });
            this.setState({ endDate: this.props.doc.data.endDate });
            this.setState({ courses: this.props.doc.data.courses });
            this.setState({ activities: this.props.doc.data.activities });
        } else throw new Error ("Invalid action provided to EduationEntry component")
        this.setState({ loading: false });
    }

    // opens the main modal
    openModal() {
        this.setState({ mainModal: true });
    }

    //opens the main review modal
    openMainModal() {
        this.setState({ mainModal: true })
    }

    //action for the main modal close action. Opens confirmation if there are changes
    closeMainModal() {
        if (this.state.changed) this.setState({ confirmationModal: 'cancel'}); // open the confirmation modal
        else {
            this.closeConfirmationModal();
            this.closeMainModalConfirmation();
            this.loadData();
        }
    }

    //closes the main review modal
    closeMainModalConfirmation() {
        this.closeConfirmationModal();
        this.setState({ mainModal: false });
        this.loadData();
    }

    // closes the confirmation modal
    closeConfirmationModal() {
        this.setState({ confirmationModal: null });
    }

    //when a user hits the submit action
    onSubmit(action) {
        // action = create | delete | reminder
        if (action === "create") {
            let isValidToSubmit = this.validateSubmission("all");
            this.setState({ validToSubmit: isValidToSubmit });
            if ( !isValidToSubmit ) {
                this.setState({ errorMainModal: new Error("There may be invalid values or missing required fields.")});
            } else {
                this.onSubmitConfirm("create");
            }
        } else if (action === "save") {
            if (!this.state.changed ) {
                this.closeConfirmationModal();
                this.closeMainModalConfirmation();
            } else {
                this.onSubmitConfirm("save");
            }
        } else if (action === "delete") {
            this.setState({ confirmationModal: 'delete' })
        } else {
            this.closeMainModal();
        }
    }
    // when a user confirms they want to submit
    onSubmitConfirm(action) {
        if (action === "save") {
            this.props.firebase.doUpdateEducationEntry(
                this.props.doc.id,
                this.props.uid, 
                this.state.school,
                this.state.educationLevel,
                this.state.degree,
                this.state.grade,
                this.state.startDate,
                this.state.endDate,
                this.state.courses,
                this.state.activities
            )
            .then(() => {
                this.closeConfirmationModal();
                this.closeMainModalConfirmation();
                this.props.refreshPage();
            })
            .catch(error => {
                this.setState({errorMainModal: error});
            });
        } else if (action === "delete") {
            this.props.firebase.doDeleteEducationEntry(this.props.doc.id)
            .then(() => {
                this.closeConfirmationModal();
                this.closeMainModalConfirmation();
                this.props.refreshPage();
            })
            .catch(error => {
                this.setState({errorConfirmModal: error});
            });
        } else if (action === "create") {
            this.props.firebase.doAddEducationEntry(
                this.props.uid, 
                this.state.school,
                this.state.educationLevel,
                this.state.degree,
                this.state.grade,
                this.state.startDate,
                this.state.endDate,
                this.state.courses,
                this.state.activities
            )
            .then(() => {
                this.closeConfirmationModal();
                this.closeMainModalConfirmation();
                this.props.refreshPage();
            })
            .catch(error => {
                this.setState({errorConfirmModal: error});
            });
        }
    }

    onChange = event => {
        event.persist(); // allows event to be available to the callback

        this.setState({ 
            [event.target.name]: event.target.value
        }, () => {
            this.setState({validToSubmit: this.validateSubmission(event.target.name) });
            this.isChanged();
        });
    };

    // Validate whether the values of the form are valid
    validateSubmission = fieldToValidate => {
        var isValid = true;

        if (fieldToValidate === "school" || fieldToValidate === "all") {
            if (this.state.school === '') { 
                this.setState({IsInvalidSchool: "has-danger"});
                isValid = false;
            } else this.setState({IsInvalidSchool: ""});
        }
        
        if (fieldToValidate === "educationLevel" || fieldToValidate === "all") {
            if (this.state.educationLevel === '') { 
                this.setState({isInvalidEducationLevel: "has-danger"});
                isValid = false;
            } else this.setState({isInvalidEducationLevel: ""});
        }
        
        if (fieldToValidate === "degree" || fieldToValidate === "all") {
            if (this.state.degree === '') { 
                this.setState({isInvalidDegree: "has-danger"});
                isValid = false;
            } else this.setState({isInvalidDegree: ""});
        }
        
        if (fieldToValidate === "startDate" || fieldToValidate === "all") {
            if (this.state.startDate === '') { 
                this.setState({isInvalidStartDate: "has-danger"});
                isValid = false;
            } else this.setState({isInvalidStartDate: ""});
        }
        
        if (fieldToValidate === "endDate" || fieldToValidate === "all") {
            if (this.state.endDate !== '' && this.state.endDate<this.state.startDate) { 
                this.setState({isInvalidEndDate: "has-danger"});
                isValid = false;
            } else this.setState({isInvalidEndDate: ""});
        }

        return isValid;
    }

    // Validate whether there are changes to the form
    isChanged() {
        let isChanged = false;
        if (this.props.action.toLowerCase() === "create"){
            isChanged = this.state.school !== ''
                    || this.state.educationLevel !== ''
                    || this.state.degree !== ''
                    || this.state.grade !== ''
                    || this.state.startDate !== ''
                    || this.state.endDate !== ''
                    || this.state.courses !== ''
                    || this.state.activities !== '';
        } else if (this.props.action.toLowerCase() === "edit") {
            isChanged = this.state.school !== this.props.doc.data.school
                    || this.state.educationLevel !== this.props.doc.data.educationLevel
                    || this.state.degree !== this.props.doc.data.degree
                    || this.state.grade !== this.props.doc.data.grade
                    || this.state.startDate !== this.props.doc.data.startDate
                    || this.state.endDate !== this.props.doc.data.endDate
                    || this.state.courses !== this.props.doc.data.courses
                    || this.state.activities !== this.props.doc.data.activities;
        }
        this.setState({ changed: isChanged });
    }

    render() {
        if (this.state && !this.state.loading) {

            var actionIcon;
            var actionIconTooltipText;
            var actionIconUniqueID;
            if (this.props.action.toLowerCase() === "edit") {
                actionIcon = "tim-icons icon-pencil";
                actionIconTooltipText = "Edit Education Entry";
                actionIconUniqueID = this.props.doc.id;
            } else if (this.props.action.toLowerCase() === "create") {
                actionIcon = "tim-icons icon-simple-add";
                actionIconTooltipText = "Add New Education Entry";
                actionIconUniqueID = "create"
            }

            return (
                <>
                    <ActionIcon 
                        id={"educationEntryBase"+actionIconUniqueID}
                        iconClassName={actionIcon}
                        toolTipText={actionIconTooltipText}
                        onClick={this.openMainModal}
                        iconText={this.props.buttonText}
                        />
                    
                    <Modal isOpen={this.state.mainModal} toggle={() => this.closeMainModal()}>
                        <ModalHeader tag="h4" toggle={() => this.closeMainModal()}>
                            { actionIconTooltipText }
                        </ModalHeader>
                        <ModalBody>
                            <Form className="form-modalForm">
                                <FormGroup className={this.state.IsInvalidSchool}>
                                    <Label for="edu-school">School Name</Label>
                                    <Input
                                        id="edu-school"
                                        className="form-control"
                                        name = "school"
                                        value = {this.state.school}
                                        onChange = {e => this.onChange(e)}
                                        onBlur = {e => this.onChange(e)}
                                        type = "Select"
                                        placeholder = "Greendale Community College"
                                        required
                                    />
                                </FormGroup>
                                <FormGroup className={this.state.isInvalidEducationLevel}>
                                    <Label for="edu-educationLevel">Education Level</Label>
                                    <Input
                                        id="edu-educationLevel"
                                        className="form-control"
                                        name = "educationLevel"
                                        value = {this.state.educationLevel}
                                        onChange = {e => this.onChange(e)}
                                        onBlur = {e => this.onChange(e)}
                                        type = "select"
                                        placeholder = "Select"
                                        required>
                                        <option>Diploma</option>
                                        <option>Bachelor</option>
                                        <option>Masters</option>
                                        <option>PhD</option>
                                    </Input>
                                </FormGroup>
                                <FormGroup className={this.state.isInvalidDegree}>
                                    <Label for="edu-degree">Degree / Program</Label>
                                    <Input
                                        id="edu-degree"
                                        className="form-control"
                                        name = "degree"
                                        value = {this.state.degree}
                                        onChange = {e => this.onChange(e)}
                                        onBlur = {e => this.onChange(e)}
                                        type = "text"
                                        placeholder = ""
                                        required
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="edu-grade">GPA</Label>
                                    <Input
                                        id="edu-grade"
                                        className="form-control"
                                        name = "grade"
                                        value = {this.state.grade}
                                        onChange = {e => this.onChange(e)}
                                        onBlur = {e => this.onChange(e)}
                                        type = "text"
                                    />
                                </FormGroup>
                                <Row>
                                    <Col xs="6">
                                        <FormGroup className={this.state.isInvalidStartDate}>
                                            <Label for="edu-startDate">Start Date</Label>
                                            <Input 
                                                id="edu-startDate"
                                                type="month" 
                                                name="startDate" 
                                                value = {this.state.startDate}
                                                onChange = {e => this.onChange(e)}
                                                onBlur = {e => this.onChange(e)}
                                                />
                                        </FormGroup>
                                    </Col>
                                    <Col xs="6">
                                        <FormGroup className={this.state.isInvalidEndDate}>
                                            <Label for="edu-endDate">End Date</Label>
                                            <Input 
                                                id="edu-endDate"
                                                type="month" 
                                                name="endDate" 
                                                value = {this.state.endDate}
                                                onChange = {e => this.onChange(e)}
                                                onBlur = {e => this.onChange(e)}
                                                invalid = {this.state.isInvalidEndDate !== ''}
                                                />
                                            <FormFeedback>Cannot be before start date</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <FormGroup>
                                    <Label for="edu-courses">Relevant Courses</Label>
                                    <Input
                                        id="edu-courses"
                                        className="form-control"
                                        name = "courses"
                                        value = {this.state.courses}
                                        onChange = {e => this.onChange(e)}
                                        onBlur = {e => this.onChange(e)}
                                        type = "textarea"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="edu-activities">Relevant Activities</Label>
                                    <Input
                                        id="edu-activities"
                                        className="form-control"
                                        name = "activities"
                                        value = {this.state.activities}
                                        onChange = {e => this.onChange(e)}
                                        onBlur = {e => this.onChange(e)}
                                        type = "textarea"
                                        placeholder = ""
                                    />
                                </FormGroup>
                            </Form>

                            <Modal isOpen={this.state.confirmationModal === "delete"} toggle={() => this.closeConfirmationModal()} size="sm">
                                <ModalHeader tag="h4" toggle={ this.closeConfirmationModal }>Delete Confirmation</ModalHeader>
                                <ModalBody>
                                    <center>Are you sure you want to delete this entry?<br />This action can not be undone.</center>
                                    {this.state.errorConfirmModal && <><br /><Alert color="danger">{this.state.errorConfirmModal.message}</Alert></>}
                                </ModalBody>
                                <ModalFooter>
                                <ActionIcon 
                                    id={"educationModalSubmitConfirm"+actionIconUniqueID}
                                    iconClassName="tim-icons icon-check-2"
                                    toolTipText="Confirm deletion"
                                    onClick={() => this.onSubmitConfirm("delete")}
                                />
                                <ActionIcon 
                                    id={"educationModalSubmitCancel"+actionIconUniqueID}
                                    iconClassName="tim-icons icon-simple-remove"
                                    toolTipText="Continue editing"
                                    onClick={this.closeConfirmationModal}
                                />
                                    
                                </ModalFooter>
                            </Modal>

                            <Modal isOpen={this.state.confirmationModal === "cancel"} toggle={() => this.closeConfirmationModal()} size="sm">
                                <ModalHeader tag="h4" toggle={ this.closeConfirmationModal }>Cancel Confirmation</ModalHeader>
                                <ModalBody>
                                    <center>Are you sure you want to cancel?<br />There are unsaved changed.</center>
                                    {this.state.errorConfirmModal && <><br /><Alert color="danger">{this.state.errorConfirmModal.message}</Alert></>}
                                </ModalBody>
                                <ModalFooter>
                                    <ActionIcon 
                                        id={"educationModalCancelConfirm"+actionIconUniqueID}
                                        iconClassName="tim-icons icon-trash-simple"
                                        toolTipText="Discard changes"
                                        onClick={this.closeMainModalConfirmation}
                                    />
                                    <ActionIcon 
                                        id={"educationModalCancelCancel"+actionIconUniqueID}
                                        iconClassName="tim-icons icon-simple-remove"
                                        toolTipText="Continue editing"
                                        onClick={this.closeConfirmationModal}
                                    />
                                </ModalFooter>
                            </Modal>

                            {this.state.errorMainModal && <><br /><Alert color="danger">{this.state.errorMainModal.message}</Alert></>}
                        </ModalBody>
                        <ModalFooter>
                            { this.props.action.toLowerCase() === "create" ?
                                    <>
                                        <ActionIcon 
                                            id={"educationModalSubmit"+actionIconUniqueID}
                                            iconClassName="tim-icons icon-check-2"
                                            toolTipText="Create education entry"
                                            onClick={() => this.onSubmit("create")}
                                        />
                                    </>
                                : null
                            }
                            { this.props.action.toLowerCase() === "edit" ?
                                <>
                                    <ActionIcon 
                                        id={"educationModalSave"+actionIconUniqueID}
                                        iconClassName="tim-icons icon-check-2"
                                        toolTipText="Save changes"
                                        onClick={() => this.onSubmit("save")}
                                    /> 
                                    <ActionIcon 
                                        id={"educationModalSubmit"+actionIconUniqueID}
                                        iconClassName="tim-icons icon-trash-simple"
                                        toolTipText="Delete education entry"
                                        onClick={() => this.onSubmit("delete")}
                                    />
                                </>
                                : null
                            }
                            <ActionIcon 
                                id={"educationModalCancel"+actionIconUniqueID}
                                iconClassName="tim-icons icon-simple-remove"
                                toolTipText="Cancel and close"
                                onClick={this.closeMainModal}
                            />
                        </ModalFooter>
                    </Modal>
                </>

                // Dynamically geneate the list of year options for dropdown
                // let endYearOffset = 10;
                // let currentYear = (new Date()).getFullYear();
                // let startYears = [];
                // let endYears = [];
                // for(let x = currentYear; x >= 1950; x--) {
                //     startYears.push(x);
                // }
                // for(let x = (currentYear + endYearOffset); x >= 1950; x--) {
                //     endYears.push(x);
                // }
                // const startYearsList = startYears.map((x) => {return(<option key={x}>{x}</option>)});
                // const endYearsList = endYears.map((x) => {return(<option key={x}>{x}</option>)});


            )
        } else return null;
    }


}




const EducationHistory = withFirebase(EducationHistoryBase);
const EducationEntry = withFirebase(EducationEntryBase)

export default EducationHistory;
export {EducationEntry};