import React, {Component} from 'react';
import {withFirebase} from '../Firebase/Firebase';

import {
    Card,
    CardHeader,
    CardBody,
    Button, 
    Form, 
    FormGroup, 
    Label, 
    Input, 
    Row,
    Col,
    FormFeedback
} from 'reactstrap';

const INITIAL_STATE = {
    changed: false,
    validToSubmit: false,
    isValidFirstName: '',
    isValidLastName: '',

    loading: true,
    uid: '',
    email: '',
    role: '',
    firstName: '',
    lastName: '',
    organizationName: '',
    industry: '',
    educationInstType: '',
    primaryPhone: '',
    primaryPhoneType: '',
    addressLine1: '',
    addressLine2: '',
    country: '',
    provinceState: '',
    city: '',
    postalZip: '',
    addrOther: '',
    theme: '',
    industryFocus: '',
    dob: '',
    currentPassword: '',
    passwordOne: '',
    passwordTwo: '',
    error: null,
  };

class UserProfileFormBase extends Component {
    constructor(props) {
        super(props);
        
        // props.uid
        // props.email
        // props.didLoad
        
        INITIAL_STATE.uid = props.uid;
        INITIAL_STATE.email = props.email;
        this.state = {...INITIAL_STATE};
    }

    // Loads data from Firebase and sets loading state to false
    loadData = () => {
        this.props.firebase.doGetUserAccount(this.props.uid)
        .then(doc => {
            for(var k in doc.data()) {
                INITIAL_STATE[k]=doc.data()[k];
            };
        })
        .then(() => {
            INITIAL_STATE.loading = false;
            this.setState({ ...INITIAL_STATE }
                ,() => {if (this.props.didLoad !== undefined) this.props.didLoad(); });
            //console.log(this.state);
        })
        .catch(error => {
            this.setState({error});
        });
    }
    
    // Load profile data from database
    componentDidMount() {
        //console.log(props.uid);
        this.loadData();
    }

    onSubmit = event => {
        if (!this.state.changed) {
            this.setState({error: new Error("No changes were made.")});
        } else if (this.state.validToSubmit) {
            //this.setState({loading: true});
            
            this.props.firebase.doUpdateUserAccount(this.state.uid,
                                                    this.state.role,
                                                    this.state.firstName, 
                                                    this.state.lastName, 
                                                    this.state.organizationName,
                                                    this.state.industry,
                                                    this.state.educationInstType,
                                                    this.state.primaryPhone, 
                                                    this.state.primaryPhoneType, 
                                                    this.state.addressLine1,
                                                    this.state.addressLine2,
                                                    this.state.country,
                                                    this.state.provinceState,
                                                    this.state.city,
                                                    this.state.postalZip,
                                                    this.state.addrOther,
                                                    this.state.theme, 
                                                    this.state.industryFocus,
                                                    this.state.dob,
            ).then(() => {
                //this.loadData(); // no need to reload, the data state is already up to date
                console.log("asdf")
                this.setState({error: new Error("Profile successfully updated!")})
            }).catch(error => {
                this.setState({error});
            });
        } else this.setState({error: new Error("There may be invalid values or missing required fields.")})
    
        event.preventDefault();
    }

    // On change of the fields, update the state
    onChange = event => {
        event.persist();
        
        this.setState({
            [event.target.name]: event.target.value
        }, () => {
            this.setState({validToSubmit: this.validateSubmission(event.target.name)});
            this.isChanged();
        });
    };

    // Validate whether the field value(s) are valid to submit
    validateSubmission = fieldToValidate => {
        var isValid = true; 

        if (fieldToValidate === "firstName" || fieldToValidate === "all") {
            if (this.state.firstName === '') { 
                this.setState({isValidFirstName: "has-danger"});
                isValid = false;
            } else this.setState({isValidFirstName: ""});
        }

        if (fieldToValidate === "lastName" || fieldToValidate === "all") {
            if (this.state.lastName === '') { 
                this.setState({isValidLastName: "has-danger"});
                isValid = false;
            } else this.setState({isValidLastName: ""});
        }
        

        return isValid;
    }

    // Validate whether there are changes to the form
    isChanged() {
        var isChanged = INITIAL_STATE.firstName !== this.state.firstName
                    || INITIAL_STATE.lastName !== this.state.lastName
                    || INITIAL_STATE.organizationName !== this.state.organizationName
                    || INITIAL_STATE.industry !== this.state.industry
                    || INITIAL_STATE.educationInstType !== this.state.educationInstType
                    || INITIAL_STATE.primaryPhone !== this.state.primaryPhone
                    || INITIAL_STATE.primaryPhoneType !== this.state.primaryPhoneType
                    || INITIAL_STATE.addressLine1 !== this.state.addressLine1
                    || INITIAL_STATE.addressLine2 !== this.state.addressLine2
                    || INITIAL_STATE.country !== this.state.country
                    || INITIAL_STATE.provinceState !== this.state.provinceState
                    || INITIAL_STATE.city !== this.state.city
                    || INITIAL_STATE.postalZip !== this.state.postalZip
                    || INITIAL_STATE.addrOther !== this.state.addrOther
                    || INITIAL_STATE.theme !== this.state.theme
                    || INITIAL_STATE.industryFocus !== this.state.industryFocus
                    || INITIAL_STATE.dob !== this.state.dob

        this.setState({ changed: isChanged });
    }

    render() {
        if (this.state && !this.state.loading) {
            return (
                <div>
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardHeader>
                                    <h2 className="card-title">Account Details</h2>
                                </CardHeader>
                                <CardBody>
                                    <h4 className="card-title">Basics</h4>
                                    <Form onSubmit={this.onSubmit} className="form-userProfile">
                                        <FormGroup>
                                            <Label for="profile-email">Login Email</Label>
                                            <Input
                                                id="profile-email"
                                                className="form-control"
                                                name = "email"
                                                value = {this.state.email}
                                                onChange = {e => this.onChange(e)}
                                                onBlur = {e => this.onChange(e)}
                                                type = "text"
                                                disabled
                                            />
                                        </FormGroup>
                                        {/* <FormGroup>
                                            <Label for="profile-theme">Theme</Label>
                                            <Input
                                                id="profile-theme"
                                                className="form-control"
                                                name = "theme"
                                                value = {this.state.theme}
                                                onChange = {e => this.onChange(e)}
                                                onBlur = {e => this.onChange(e)}
                                                type = "select"
                                            >
                                                <option>Default</option>
                                                <option>Dark</option>
                                            </Input>
                                        </FormGroup> */}

                                        <hr />
                                        <h4 className="card-title">Personal Profile</h4>
                                        {(this.state.accountType === "organization" || this.state.accountType === "education") &&
                                            <FormGroup>
                                                <Label for="profile-organizationName">Organization Name</Label>
                                                <Input
                                                    id="profile-organizationName"
                                                    className="form-control"
                                                    name = "organizationName"
                                                    value = {this.state.organizationName}
                                                    onChange = {e => this.onChange(e)}
                                                    onBlur = {e => this.onChange(e)}
                                                    type = "text"
                                                />
                                            </FormGroup>
                                        }
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup className={this.state.isValidFirstName}>
                                                    <Label for="profile-firstName">First Name</Label>
                                                    <Input
                                                        id="profile-firstName"
                                                        className="form-control"
                                                        name = "firstName"
                                                        value = {this.state.firstName}
                                                        onChange = {e => this.onChange(e)}
                                                        onBlur = {e => this.onChange(e)}
                                                        type = "text"
                                                        invalid={this.state.isValidFirstName !== ''}
                                                    />
                                                    <FormFeedback>Please provide your first name.</FormFeedback>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup className={this.state.isValidLastName}>
                                                    <Label for="profile-lastName">Last Name</Label>
                                                    <Input
                                                        id="profile-lastName"
                                                        className="form-control"
                                                        name = "lastName"
                                                        value = {this.state.lastName}
                                                        onChange = {e => this.onChange(e)}
                                                        onBlur = {e => this.onChange(e)}
                                                        type = "text"
                                                        invalid={this.state.isValidLastName !== ''}
                                                    />
                                                    <FormFeedback>Please provide your last Name.</FormFeedback>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        {this.state.accountType === "individual" &&
                                            <FormGroup>
                                                <Label for="profile-industryFocus">Industry Focus</Label>
                                                <Input
                                                    id="profile-industryFocus"
                                                    className="form-control"
                                                    name = "industryFocus"
                                                    value = {this.state.industryFocus}
                                                    onChange = {e => this.onChange(e)}
                                                    onBlur = {e => this.onChange(e)}
                                                    type = "text"
                                                />
                                            </FormGroup>
                                        }
                                        <Row form>
                                            <Col md={8}>
                                                <FormGroup>
                                                    <Label for="profile-primaryPhone">Primary Phone</Label>
                                                    <Input
                                                        id="profile-primaryPhone"
                                                        className="form-control"
                                                        name = "primaryPhone"
                                                        value = {this.state.primaryPhone}
                                                        onChange = {e => this.onChange(e)}
                                                        onBlur = {e => this.onChange(e)}
                                                        type = "text"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="profile-primaryPhoneType">Primary Phone Type</Label>
                                                    <Input
                                                        id="profile-primaryPhoneType"
                                                        className="form-control"
                                                        name = "primaryPhoneType"
                                                        value = {this.state.primaryPhoneType}
                                                        onChange = {e => this.onChange(e)}
                                                        onBlur = {e => this.onChange(e)}
                                                        type = "select"
                                                    >
                                                        <option></option>
                                                        <option>Cell</option>
                                                        <option>Home</option>
                                                        <option>Fax</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        {this.state.accountType === "individual" &&
                                            <FormGroup>
                                                <Label for="profile-dob">Birthday</Label>
                                                <Input
                                                    id="profile-dob"
                                                    className="form-control"
                                                    name = "dob"
                                                    value = {this.state.dob}
                                                    onChange = {e => this.onChange(e)}
                                                    onBlur = {e => this.onChange(e)}
                                                    type = "date"
                                                />
                                            </FormGroup>
                                        }
                                        {this.state.accountType === "organization" &&
                                            <FormGroup>
                                                <Label for="profile-industry">Industry</Label>
                                                <Input
                                                    id="profile-industry"
                                                    className="form-control"
                                                    name = "industry"
                                                    value = {this.state.industry}
                                                    onChange = {e => this.onChange(e)}
                                                    onBlur = {e => this.onChange(e)}
                                                    type = "text"
                                                />
                                            </FormGroup>
                                        }
                                        {this.state.accountType === "education" &&
                                            <FormGroup>
                                                <Label for="profile-educationInstType">educationLevel</Label>
                                                <Input
                                                    id="profile-educationInstType"
                                                    className="form-control"
                                                    name = "educationInstType"
                                                    value = {this.state.educationInstType}
                                                    onChange = {e => this.onChange(e)}
                                                    onBlur = {e => this.onChange(e)}
                                                    type = "select"
                                                >
                                                    <option>Primary</option>
                                                    <option>Secondary</option>
                                                    <option>College</option>
                                                    <option>University</option>
                                                </Input>
                                            </FormGroup>
                                        }

                                        <hr />
                                        <h4 className="card-title">Location</h4>
                                        {/* <h4 className="card-title">Address</h4> */}
                                        {/* <FormGroup>
                                            <Label for="profile-addressLine1">Address Line 1</Label>
                                            <Input
                                                id="profile-addressLine1"
                                                className="form-control"
                                                name = "addressLine1"
                                                value = {this.state.addressLine1}
                                                onChange = {e => this.onChange(e)}
                                                onBlur = {e => this.onChange(e)}
                                                type = "text"
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="profile-addressLine2">Address Line 2</Label>
                                            <Input
                                                id="profile-addressLine2"
                                                className="form-control"
                                                name = "addressLine2"
                                                value = {this.state.addressLine2}
                                                onChange = {e => this.onChange(e)}
                                                onBlur = {e => this.onChange(e)}
                                                type = "text"
                                            />
                                        </FormGroup> */}
                                        <Row>
                                            <Col md={4} xs={12}>
                                                <FormGroup>
                                                    <Label for="profile-country">Country</Label>
                                                    <Input
                                                        id="profile-country"
                                                        className="form-control"
                                                        name = "country"
                                                        value = {this.state.country}
                                                        onChange = {e => this.onChange(e)}
                                                    onBlur = {e => this.onChange(e)}
                                                        type = "text"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={4} xs={12}>
                                                <FormGroup>
                                                    <Label for="profile-provinceState">Province / State</Label>
                                                    <Input
                                                        id="profile-provinceState"
                                                        className="form-control"
                                                        name = "provinceState"
                                                        value = {this.state.provinceState}
                                                        onChange = {e => this.onChange(e)}
                                                        onBlur = {e => this.onChange(e)}
                                                        type = "text"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={4} xs={12}>
                                                <FormGroup>
                                                    <Label for="profile-city">City</Label>
                                                    <Input
                                                        id="profile-city"
                                                        className="form-control"
                                                        name = "city"
                                                        value = {this.state.city}
                                                        onChange = {e => this.onChange(e)}
                                                        onBlur = {e => this.onChange(e)}
                                                        type = "text"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        {/* <Row>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="profile-postalZip">Postal / Zip</Label>
                                                    <Input
                                                        id="profile-postalZip"
                                                        className="form-control"
                                                        name = "postalZip"
                                                        value = {this.state.postalZip}
                                                        onChange = {e => this.onChange(e)}
                                                        onBlur = {e => this.onChange(e)}
                                                        type = "text"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row> */}

                                        <Button block color="primary">Save Profile</Button>
                                        {this.state.error && <p>{this.state.error.message}</p>}
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                        {/* <Col md="4">
                            <Card className="card-user">
                                <CardBody>
                                    <CardText />
                                    <div className="author">
                                        <div className="block block-one" />
                                        <div className="block block-two" />
                                        <div className="block block-three" />
                                        <div className="block block-four" />
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                        <img
                                            alt="..."
                                            className="avatar"
                                            src={require("../../assets/img/emilyz.jpg")}
                                        />
                                        <h5>Mike Andrew</h5>
                                        </a>
                                        <p className="description">Ceo/Co-Founder</p>
                                    </div>
                                    <div className="card-description">
                                        Do not be scared of the truth because we need to restart the
                                        human foundation in truth And I love you like Kanye loves
                                        Kanye I love Rick Owens’ bed design but the back is...
                                    </div>
                                </CardBody>
                                <CardFooter>
                                <div className="button-container">
                                    <Button className="btn-icon btn-round" color="facebook">
                                    <i className="fab fa-facebook" />
                                    </Button>
                                    <Button className="btn-icon btn-round" color="twitter">
                                    <i className="fab fa-twitter" />
                                    </Button>
                                    <Button className="btn-icon btn-round" color="google">
                                    <i className="fab fa-google-plus" />
                                    </Button>
                                </div>
                                </CardFooter>
                            </Card>
                        </Col> */}
                    </Row>
                </div>
            );
        }

        // While data is being loaded from database
        return "loading...";
    };
};

const UserProfileForm = withFirebase(UserProfileFormBase);

export default UserProfileForm;