import React, {Component} from 'react';
import {withFirebase} from '../../Firebase/Firebase';
import AchievementAddModal from './AddModal';
import LineItem from './LineItem';

import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle
} from 'reactstrap';


/*********************************************************************************/
/***************************** Render All Achivements ****************************/
/*********************************************************************************/

class RenderAllAchievementsBase extends Component {
    constructor(props) {
        super(props);
        this.deleteAchievementEntry = this.deleteAchievementEntry.bind(this);
        this.addAchivementEntry = this.addAchivementEntry.bind(this);
        this.childrenDidLoad = this.childrenDidLoad.bind(this);

        this.state = {
            loading: true,
            achievementList: this.props.projectDoc.achievementIDs,
            rendering: true,
            numComponentsToRender: 0,
            componentsRendered: 0,
        };
        this.onDragEnd = this.onDragEnd.bind(this);

        // props.uid
        // props.projectDocID
        // props.projectDoc
        // props.didLoad
    }

    componentDidMount() {
        if (this.state.achievementList !== undefined) {
            this.setState({ numComponentsToRender: this.state.achievementList.length }
                , () => { 
                    this.setState({ loading: false });
                    this.isRenderComplete();
                })
        } else {
            this.setState({ loading: false });
            this.isRenderComplete();
        }
    }

    isRenderComplete() {
        var isRenderCompleted = true;
        isRenderCompleted &= this.state.componentsRendered >= this.state.numComponentsToRender;
        this.setState({ rendering: !isRenderCompleted });
        if (isRenderCompleted && this.props.didLoad() !== undefined) {
            this.props.didLoad();
        }
    }

    childrenDidLoad() {
        var { componentsRendered } = this.state;
        componentsRendered++;
        this.setState( { componentsRendered: componentsRendered } 
                        ,() => { this.isRenderComplete();  })
    }    
  
    // Action for end of drag
    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) return;
    
        // render the new list
        let newList = this.reorder(this.state.achievementList,
            result.source.index,
            result.destination.index);

        // only update if the list has changed
        if (JSON.stringify(this.state.achievementList) !== JSON.stringify(newList)) {
            // set state with new list
            this.setState({achievementList: newList}, () => {
                // callback: update fs database with new list
                this.updateProjectAchievement();
            });
        }
    }

    // Calls the firebase function to update the Achievement array in firestore
    updateProjectAchievement() {
        this.props.firebase.doUpdateProjectAchievements(this.props.projectDocID,
                                                        this.state.achievementList
        ).catch(error => {
            console.log(error);
            this.setState({error});
        }); 
    }

    // Function called by sub components to delete an existing achivement ID from the list
    deleteAchievementEntry(entryIndex) {
        let newList = Array.from(this.state.achievementList);
        newList.splice(entryIndex, 1);
        // set state with new list
        this.setState({
            achievementList: newList
        }, () => {
            // update fs database with new list
            this.updateProjectAchievement();
        });
    }

    // Function called by sub components to add a new achivement entry. The new doc ID is added to the end of the list
    addAchivementEntry(achivementDocID) {
        let newList = [];
        if (this.state.achievementList !== undefined && this.state.achievementList !== undefined) newList = Array.from(this.state.achievementList);
        newList.push(achivementDocID);
        // set state with new list
        this.setState({
            achievementList: newList
        }, () => {
            // update fs database with new list
            this.updateProjectAchievement();
        });
    }

    // a little function to help us with reordering the result
    reorder (list, startIndex, endIndex) {
        let result = Array.from(list);
        let [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };
  
    // Normally you would want to split things out into separate components.
    // But in this example everything is just done in one place for simplicity
    render() {
        if (this.state && !this.state.loading) {
            return (
                <Card>
                    <CardHeader className="list-header">
                        <Row>
                            <Col xs="10">
                                <CardTitle tag="h5">Achievements</CardTitle>
                            </Col>
                            <Col xs="2" className="text-right"><AchievementAddModal onSubmit={this.addAchivementEntry} uid={this.props.uid} projectDocID={this.props.projectDocID} /></Col>
                        </Row>
                    </CardHeader>
                    <CardBody className="list-body">
                        {this.state.achievementList !== undefined && this.state.achievementList.length > 0?
                        //TODO: Fix styling for draggable component
                            // <DragDropContext onDragEnd={this.onDragEnd}>
                            //     <Droppable droppableId="droppable">
                            //         {(provided, snapshot) => (
                            //         <div
                            //             {...provided.droppableProps}
                            //             ref={provided.innerRef}
                            //             style={getListStyle(snapshot.isDraggingOver)}
                            //         >
                            //             {this.state.achievementList.map((value, index) => (
                            //                 <Draggable key={"achivement_"+this.props.projectDocID+"_"+index} draggableId={this.props.projectDocID+"_"+index} index={index}>
                            //                     {(provided, snapshot) => (
                            //                     <div className="no-margin no-padding"
                            //                         ref={provided.innerRef}
                            //                         {...provided.draggableProps}
                            //                         {...provided.dragHandleProps}
                            //                         style={getachievementListStyle(
                            //                             snapshot.isDragging,
                            //                             provided.draggableProps.style
                            //                         )}
                            //                     >
                            //                         <LineItem achievementDocID={value}
                            //                                         index={index}
                            //                                         onDelete={this.deleteAchievementEntry} />
                            //                     </div>
                            //                     )}
                            //                 </Draggable>
                            //             ))}
                            //             {provided.placeholder}
                            //         </div>
                            //         )}
                            //     </Droppable>
                            // </DragDropContext>

                            <>
                                {this.state.achievementList.map((docId, index) => (
                                    <LineItem achievementDocID={docId}
                                                    key={"achievementItem" + docId}
                                                    index={index}
                                                    onDelete={this.deleteAchievementEntry}
                                                    didLoad={this.childrenDidLoad} />
                                ))}
                            </>
                            : <p className="list-notice">Nothing here yet!</p>
                        }
                    </CardBody>
                </Card>
            );
        } return null;
    }
}


/************** Exports *****************/
const Achievements = withFirebase(RenderAllAchievementsBase);

export default Achievements;