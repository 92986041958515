import React, {Component} from 'react';
import {withFirebase} from '../Firebase/Firebase';
import moment from 'moment';
import {
    Row,
    Col,
    Form, 
    FormGroup, 
    Label, 
    Input,
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    Card, 
    CardTitle,
    CardHeader,
    CardBody,
    Table
} from 'reactstrap';
import {ActionIcon} from '../GlobalElements/Button';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';


/********************************************************************************/
/******************************* Review Summary *******************************/
/********************************************************************************/

const INITIAL_STATE_SUMMARY = {
    loading: true,
    changed: false,
    feedbackReviewerDocs: null,
    feedbackModalCurrentDoc: null,
    feedbackModalState: null,

    formVersion: '',
    feedbackGeneral: '',

    confirmationModal: null,
    cancelDocID: null,
    error: null,
    errorConfirmModal: null,
    errorCancelConfirmModal: null,
    errorViewFeedbackModal: null,
    errorCompleteFeedbackModal: null,
};

class ReviewSummaryBase extends Component {
    constructor(props) {
        super(props);
        this.state = {...INITIAL_STATE_SUMMARY};
        this.loadData = this.loadData.bind(this);

        // props.uid; // UID of user provided by parent component through auth context
        // props.email; // email of user provided by parent component through auth context
        // props.didLoad;
    }

    // conditional render of view feedback button
    renderViewFeedbackButton = doc => {
        if (doc.status === "completed") {
            return (
                <ReviewFeedbackRequest
                    action="view"
                    doc={doc}
                    refreshPage={this.loadData}
                />
            )
        }
    }

    // conditional render of edit feedback (reviewer) request button
    renderCompleteFeedbackButton = doc => {
        if ((doc.status === "new" || doc.status === "draft") && doc.data.reviewerUID === this.props.uid) {
            return (
                <ReviewFeedbackRequest
                    action="edit"
                    doc={doc}
                />
            )
        }
    }

    // Renders each row for the table based on the doc provided
    renderFeedbackTableRow = (doc, status) => {
        if (doc.status === status) {
            return (
                <tr key={doc.id}>
                    <td>{doc.data.projectName}</td>
                    <td>{moment(doc.data.startDate).format("MMM Do YYYY") >> moment(doc.data.endDate).format("MMM Do YYYY")}</td>
                    <td>{doc.data.projectRole}</td>
                    <td>{doc.data.projectHours}</td>
                    <td className="text-center">
                        { this.renderViewFeedbackButton(doc) }
                        { this.renderCompleteFeedbackButton(doc) }
                    </td>
                </tr>
            )
        } 
        return null;
    }

    // Counts how many row items there are for the specific doc parameters
    countFeedbackItems = (doc, docType) => {
        var itemsCount = 0;
        docType.map(status => {
            doc.map(doc => {
                if (doc.status === status) itemsCount++;
                return null;
            })
            return null;
        })
        return itemsCount;
    }

    // renders the card for each of the feedback doc types
    renderReviewSummaryCard = (doc, header, docType) => {
        var numDocs = this.countFeedbackItems(doc, docType);

        return (
            <Card key={"feedback summary "+header.trim()+"_"+docType}>
                <CardHeader>
                    <CardTitle tag="h4">{header}</CardTitle>
                </CardHeader>
                <CardBody>
                    { numDocs !== 0? (
                        <Table className="tablesorter" hover>
                            <thead className="text-primary">
                                <tr>
                                    <th>Project Name</th>
                                    <th>Start Range</th>
                                    <th>Role</th>
                                    <th>Hours</th>
                                    { docType[0] !== "cancelled"? <th className="text-center">Action</th>: null }
                                </tr>
                            </thead>
                            <tbody>
                                { docType.map(value => doc.map(doc => this.renderFeedbackTableRow(doc, value)))}
                            </tbody>
                        </Table>
                    ): (
                        <center><div>Nothing to see here! <i className="tim-icons icon-spaceship" /></div></center>
                    )}
                </CardBody>
            </Card>
        )
    }


    // Helper function to parse the return from firestore
    mapData = resultArray => {
        const returnArray = [];
        resultArray.forEach(doc => {
            returnArray.push({
                id: doc.id,
                status: doc.data().status,
                data: doc.data(),
            });
        })
        return returnArray;
    };

    // Loads data from Firebase and sets loading state to false
    loadData = () => {
        this.setState({ loading: true });

        var tagsReviewer = ["new", "draft", "completed"];
        var fsPromisesReviewer = [];
        tagsReviewer.map(tag =>
            fsPromisesReviewer.push(this.props.firebase.doGetFeedbackForReviewer(this.props.uid, tag)));

        Promise.all(fsPromisesReviewer)
        .then(QuerySnapshots => {
            var returnArray = [];
            QuerySnapshots.forEach(QuerySnapshot => 
                QuerySnapshot.forEach(DocumentSnapshot => returnArray.push(DocumentSnapshot)));
            this.setState({ feedbackReviewerDocs: this.mapData(returnArray), loading: false }
                ,() => {if (this.props.didLoad !== undefined) this.props.didLoad();} );
        }).catch(error => {
            console.log(error);
            this.setState({error});
        }); 
    }
    
    // Load feedback data from database
    componentDidMount() {
        //console.log(props.uid);
        this.loadData();
    }

    render() {
        if (this.state && !this.state.loading) {
            //const isInvalid = this.state === INITIAL_STATE;

            return (
                <>
                    { this.renderReviewSummaryCard( this.state.feedbackReviewerDocs, "Pending Reviews", ["new", "draft"]) }
                    { this.renderReviewSummaryCard( this.state.feedbackReviewerDocs, "Completed Reviews", ["completed"]) }
                    {this.state.error && <p>{this.state.error.message}</p>}
                </>
            );
        }
        // While data is being loaded from database
        return "loading...";
    };
};


/**********************************************************************************/
/*************************** Review Feedback Request Modal ************************/
/**********************************************************************************/
/* This the modal that pops up on the Review summary page for the reviewer to     */
/* provide feedback to the requestor. Owns the action button that loads the modal */
/**********************************************************************************/

const INITIAL_STATE_REVIEW_FEEDBACK_REQUEST = {
    loading: true,
    changed: false,
    mainModal: false, // true | false
    confirmationModal: null, // submit | cancel

    errorMainModal: null,
    errorConfirmModal: null,

    formVersion: '',
    feedbackGeneral: '',

};


class ReviewFeedbackRequestBase extends Component {
    constructor(props) {
        super(props);
        this.state = {...INITIAL_STATE_REVIEW_FEEDBACK_REQUEST};

        this.onSubmitConfirm = this.onSubmitConfirm.bind(this);
        this.openMainModal = this.openMainModal.bind(this);
        this.closeMainModal = this.closeMainModal.bind(this);
        this.closeConfirmationModal = this.closeConfirmationModal.bind(this);
        this.closeMainModalConfirmation = this.closeMainModalConfirmation.bind(this);

        // props.action // VIEW | EDIT  // view = read only, edit = edit capabilities // not required for standalone
        // props.doc
        // props.refreshPage
        // props.renderStandalone // boolean, default false 
    }

    // setup when the component initially loads
    componentDidMount() {
        
        this.loadInitialData();
    }

    // reset the initial state
    loadInitialData () {
        this.setState({ loading: true });
        console.log(this.props.doc)

        // map the editable data from the props.doc to the state
        this.setState({
            feedbackGeneral: this.props.doc.data.feedbackGeneral,
        })

        this.setState({ loading: false });
    }

    // save state of form when typing
    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
        this.isChanged();
    };

    // Validate whether there are changes to the form
    isChanged() {
        let isChanged = false;
        isChanged = isChanged || this.props.doc.data.feedbackGeneral !== this.state.feedbackGeneral;

        this.setState({ changed: isChanged });
    }

    //opens the main review modal
    openMainModal() {
        this.setState({ mainModal: true })
    }

    //action for the main modal close action. Opens confirmation if there are changes
    closeMainModal() {
        if (this.state.isChanged) this.setState({ confirmationModal: 'cancel'}); // open the confirmation modal
        else {
            this.closeConfirmationModal();
            this.closeMainModalConfirmation();
        }
    }

    //closes the main review modal
    closeMainModalConfirmation() {
        // Do something?
        this.setState({ mainModal: false });
    }

    //on submit of main review modal, if action is "complete" it opens the confirmation modal
    onSubmit(action) {
        // action = draft | complete
        if (action.toLowerCase() === "complete") {
            if (this.state.feedbackGeneral === '') this.setState({ errorMainModal: new Error("Feedback cannot be blank.")}); // throw error that there are no changes to submit if the feedback is blank
            else this.setState({ confirmationModal: 'submit'}); 
        } else if (action.toLowerCase() === "draft") {
            this.onSubmitConfirm("draft");
        }
    }

    //when the user confirms they want to submit
    onSubmitConfirm(action) {
        if (this.state.changed) {
            this.props.firebase.doUpdateFeedbackRequest(
                this.props.doc.id,
                action,
                this.state.feedbackGeneral
            ).then (() => {
                this.closeMainModal();
            }).catch(error => {
                this.setState({errorMainModal: error});
            })
        } else {
            this.closeMainModal(); // only make calls to firebase if there are changes
        }
    }

    // returns the Feedback form
    renderForm() {
        return (
            <>
                <Form className="form-newFeedbackRequest">
                    <b>Project Details</b>
                    <FormGroup className={this.state.isValidProjectName}>
                        <Label for="feedback-projectName">Project</Label>
                        <Input
                            id="feedback-projectName"
                            className="form-control"
                            name = "projectName"
                            value = {this.props.doc.data.projectName}
                            type = "text"
                            disabled
                        />
                    </FormGroup>
                    <FormGroup className={this.state.isValidProjectRole}>
                        <Label for="feedback-projectRole">Project Role</Label>
                        <Input
                            id="feedback-projectRole"
                            className="form-control"
                            name = "projectRole"
                            value = {this.props.doc.data.projectRole}
                            type = "text"
                            disabled
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="feedback-projectSummary">Project / Accomplishment Summary</Label>
                        <Input
                            id="feedback-projectSummary"
                            className="form-control"
                            name = "projectSummary"
                            value = {this.props.doc.data.projectSummary}
                            type = "textarea"
                            disabled
                        />
                    </FormGroup>
                    <Row>
                        <Col xs="4">
                            <FormGroup className={this.state.isValidStartDate}>
                                <Label for="feedback-startDate">Start Date</Label>
                                <Input 
                                    type="text" 
                                    name="startDate" 
                                    value = {moment(this.props.doc.data.startDate).format("MMM Do YYYY")}
                                    id="feedback-startDate"
                                    disabled
                                    />
                            </FormGroup>
                        </Col>
                        <Col xs="4">
                            <FormGroup className={this.state.isValidEndDate}>
                                <Label for="feedback-endDate">End Date</Label>
                                <Input 
                                    type="text" 
                                    name="endDate" 
                                    value = {moment(this.props.doc.data.endDate).format("MMM Do YYYY")}
                                    id="feedback-endDate"
                                    disabled
                                    />
                            </FormGroup>
                        </Col>
                        <Col xs="4">              
                            <FormGroup>
                                <Label for="feedback-projectHours">Captured Hours</Label>
                                <Input
                                    id="feedback-projectHours"
                                    className="form-control"
                                    name = "projectHours"
                                    value = {this.props.doc.data.projectHours}
                                    type = "text"
                                    disabled
                                />
                            </FormGroup>
                        </Col>
                    </Row>     
                </Form>

                <hr />
                { /* DEV NOTE: How will we accomodate different form version for various organizations? */}
                <Form className="form-newFeedbackRequest">
                    <b>Your Feedback</b>
                    <FormGroup>
                        <Label for="feedback-feedbackGeneral">General Feedback</Label>
                        <Input
                            id="feedback-feedbackGeneral"
                            className="form-control"
                            name = "feedbackGeneral"
                            value = {this.state.feedbackGeneral}
                            onChange = {e => this.onChange(e)}
                            onBlur = {e => this.onChange(e)}
                            type = "text"
                            required
                        />
                    </FormGroup>
                </Form>
                { /* Submit confirmation modal */ }
                <Modal isOpen={ this.state.confirmationModal === "submit" } toggle={ this.closeConfirmationModal } size="sm">
                    <ModalHeader tag="h4" toggle={ this.closeConfirmationModal }>Submit Confirmation</ModalHeader>
                    <ModalBody><center><p>Are you sure you would like to submit?</p></center></ModalBody>
                    <ModalFooter>
                        <ActionIcon 
                            id={"viewEditReviewFeedbackRequestSubmitConfirm"+this.props.doc.id}
                            iconClassName="tim-icons icon-check-2"
                            toolTipText="Confirm Submission"
                            onClick={() => this.onSubmitConfirm('submit')}
                        />
                        <ActionIcon 
                            id={"viewEditReviewFeedbackRequestSubmitCancel"+this.props.doc.id}
                            iconClassName="tim-icons icon-simple-remove"
                            toolTipText="Continue editing"
                            onClick={this.closeConfirmationModal}
                        />
                        {this.state.errorConfirmModal && <p>{this.state.errorConfirmModal.message}</p>}
                    </ModalFooter>
                </Modal>

                { /* Cancel confirmation modal */ }
                <Modal isOpen={this.state.confirmationModal === "cancel" } toggle={ this.closeConfirmationModal } size="sm">
                    <ModalHeader tag="h4" toggle={ this.closeConfirmationModal }>Cancel Confirmation</ModalHeader>
                    <ModalBody><center><p>Are you sure you would like to cancel? You unsaved changed will be lost.</p></center></ModalBody>
                    <ModalFooter>
                        <ActionIcon 
                            id={"viewEditReviewFeedbackRequestCancelConfirm"+this.props.doc.id}
                            iconClassName="tim-icons icon-check-2"
                            toolTipText="Confirm cancel"
                            onClick={this.closeMainModalConfirmation}
                        />
                        <ActionIcon 
                            id={"viewEditReviewFeedbackRequestCancelCancel"+this.props.doc.id}
                            iconClassName="tim-icons icon-simple-remove"
                            toolTipText="Continue editing"
                            onClick={this.closeConfirmationModal}
                        />
                        {this.state.errorConfirmModal && <p>{this.state.errorConfirmModal.message}</p>}
                    </ModalFooter>
                </Modal>
            </>
        )
    }

    // closes the confirmation modal
    closeConfirmationModal() {
        this.setState({ confirmationModal: null });
    }


    render() {
        if (this.state && !this.state.loading) {
            var isComplete = !(this.props.doc.data.status === "new" || this.props.doc.data.status === "draft");

            if (this.props.renderStandalone) {
                return (
                    <>
                        {this.renderForm()}
                        {isComplete? <b>Your feedback has already been submitted</b>: 
                            (
                                <>
                                    <ActionIcon 
                                        id={"viewEditReviewFeedbackRequestSubmit"+this.props.doc.id}
                                        iconClassName="tim-icons icon-check-2"
                                        toolTipText="Submit feedback"
                                        onClick={() => this.onSubmit("complete")}
                                    />
                                    <ActionIcon 
                                        id={"viewEditReviewFeedbackRequestSave"+this.props.doc.id}
                                        iconClassName="tim-icons icon-cloud-upload-94"
                                        toolTipText="Save changes"
                                        onClick={() => this.onSubmit("draft")}
                                    />
                                </>
                            )
                        }
                    </>
                )
            } else {
                var actionIcon;
                var actionIconTooltipText;
                if (this.props.action.toLowerCase() === "view") {
                    actionIcon = "tim-icons icon-zoom-split";
                    actionIconTooltipText = "Completed Review";
                } else if (this.props.action.toLowerCase() === "edit") {
                    actionIcon = "tim-icons icon-pencil";
                    actionIconTooltipText = "Feedback Request";
                }

                return (
                    <>
                        <ActionIcon 
                            id={"viewEditReviewFeedbackRequest"+this.props.doc.id}
                            iconClassName={actionIcon}
                            toolTipText={actionIconTooltipText}
                            onClick={this.openMainModal}
                        />

                        {/* This modal is for the requestor to view the details of completed feedback */ }
                        <Modal isOpen={ this.state.mainModal } toggle={ this.closeMainModal }>
                            <ModalHeader tag="h4" toggle={ this.closeMainModal }>{actionIconTooltipText + " " + this.props.doc.data.requestorFirstName + " " + this.props.doc.data.requestorLastName}</ModalHeader>
                            <ModalBody>
                                {this.renderForm()}
                            </ModalBody>
                            <ModalFooter>
                                { this.props.action.toLowerCase() === "edit" ? (
                                    <>
                                        <ActionIcon 
                                            id={"viewEditReviewFeedbackRequestSubmit"+this.props.doc.id}
                                            iconClassName="tim-icons icon-check-2"
                                            toolTipText="Submit feedback"
                                            onClick={() => this.onSubmit("complete")}
                                        />
                                        <ActionIcon 
                                            id={"viewEditReviewFeedbackRequestSave"+this.props.doc.id}
                                            iconClassName="tim-icons icon-cloud-upload-94"
                                            toolTipText="Save changes"
                                            onClick={() => this.onSubmit("draft")}
                                        />
                                    </>
                                ): null}
                                <ActionIcon 
                                    id={"viewEditReviewFeedbackRequestCancel"+this.props.doc.id}
                                    iconClassName="tim-icons icon-simple-remove"
                                    toolTipText="Cancel and close"
                                    onClick={this.closeMainModal}
                                />
                                {this.state.errorMainModal && <p>{this.state.errorMainModal.message}</p>}
                            </ModalFooter>
                        </Modal>
                    </>
                )
            }
        } return null;
    }
}



const ReviewSummary = withFirebase(ReviewSummaryBase);
const ReviewFeedbackRequest = withFirebase(ReviewFeedbackRequestBase);

export default ReviewSummary;
export {ReviewFeedbackRequest};