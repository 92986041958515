import React, {Component} from 'react';
import {withFirebase} from '../Firebase/Firebase';
import AchievementAddModal from '../Projects/Achievement/AddModal'
import {
    Button, 
    Form, 
    FormGroup, 
    Label, 
    Input, 
    Row,
    Col,
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    Table,
    FormFeedback,
    UncontrolledTooltip
} from 'reactstrap';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';



/***************************************************************************************/
/*************************** Goals - Goal Item (View/Create/Edit) **********************/
/***************************************************************************************/

const INITIAL_STATE_GOAL_ITEM_MODAL = {
    loading: true,
    changed: false,
    validToSubmit: false,
    gDoc: null,
    mainModal: false,
    cancelConfirmationModal: false,
    isValidDueDate: '',

    description: '',
    term: 'Short',
    dueDate: '',
    key: 0,
};

class GoalItemCreateEditModalBase extends Component {
    constructor(props) {
        super(props);
        //props.uid
        //props.mentorshipSessionID // optional if created from mentorshipSession
        //props.buttonText // optional
        //props.gDoc // if gDoc is passed in then it is NOT a create new
        //props.refreshPage

        this.state = {...INITIAL_STATE_GOAL_ITEM_MODAL};
    }

    // Load session data from fs on load
    componentDidMount() {
        this.loadDefaults();
    }

    loadDefaults() {
        this.setState({...INITIAL_STATE_GOAL_ITEM_MODAL});
        if (this.props.gDoc !== null && this.props.gDoc !== undefined) {
            this.setState({gDoc: this.props.gDoc});
            this.setState({description: this.props.gDoc.data.description});
            this.setState({term: this.props.gDoc.data.term});
            this.setState({dueDate: this.props.gDoc.data.dueDate});
            this.setState({key: this.props.gDoc.id})
        }
        this.setState({loading: false});
    }

    // call to firestore to create the FS entry
    submitCreateGoalItem() {
        return this.props.firebase.doCreateGoalItem( // then create the new mentorship relationship in firestore
            this.props.uid,
            this.props.mentorshipSessionID,
            this.state.description,
            this.state.term,
            this.state.dueDate
        ).then (() => {
            this.onConfirmationConfirm();
            this.props.refreshPage();
        }).catch(error => {
            this.setState({errorConfirmModal: error});
        })
    }

    // call to firestore to update the FS entry
    submitUpdateGoalItem() {
        return this.props.firebase.doUpdateGoalItem( // then create the new mentorship relationship in firestore
            this.props.gDoc.id,
            this.state.description,
            this.state.term,
            this.state.dueDate
        ).then (() => {
            this.onConfirmationConfirm();
            this.props.refreshPage();
        }).catch(error => {
            this.setState({errorConfirmModal: error});
        })
    }

    // open the main modal
    openMainModal() {
        this.setState({ mainModal: true });
    }


    onSubmit() {
        if (this.state.changed) {
            this.validateSubmission("all");
            if (this.state.validToSubmit) {
                if (this.state.gDoc) {
                    this.submitUpdateGoalItem();
                } else {
                    this.submitCreateGoalItem();
                }
            } else this.setState({ mainModalError: new Error("There may be invalid values or missing required fields.")});
        } else {
            this.onConfirmationConfirm();
        }
    }

    onRemove() {
        this.props.firebase.doDeleteGoalItem(this.props.gDoc.id)
        .then(() => {
            this.onConfirmationConfirm();
            this.props.refreshPage();
        }).catch(error => {
            this.setState({errorConfirmModal: error});
        })
    }
    
    onCloseModal() {
        if (this.state.changed) {
            this.setState({ cancelConfirmationModal: true });
        } else {
            this.onConfirmationConfirm();
        }
    }

    // modal actions: cancels the modal and resets it
    onConfirmationConfirm() {
        this.loadDefaults();
        this.setState({ loading: false });
    }
    
    // modal actions: close the confirmation modal
    onConfirmationCancel() {
        this.setState({ cancelConfirmationModal: false });
    }

    onChange = event => {
        event.persist(); // allows event to be available to the callback

        this.setState({
            [event.target.name]: event.target.value
        }, () => {
            this.validateSubmission(event.target.name);
            this.isChanged();
        });
    };

    // Validate whether there are changes to the form
    isChanged() {
        let isChanged = false
        if (this.state.gDoc !== null && this.state.gDoc !== undefined) {
            isChanged = this.state.description !== this.state.gDoc.data.description
                        || this.state.term !== this.state.gDoc.data.term
                        || this.state.dueDate !== this.state.gDoc.data.dueDate
        } else {
            isChanged = this.state.description !== INITIAL_STATE_GOAL_ITEM_MODAL['description']
                        || this.state.term !== INITIAL_STATE_GOAL_ITEM_MODAL['term']
                        || this.state.dueDate !== INITIAL_STATE_GOAL_ITEM_MODAL['dueDate'];
        }

        this.setState({ changed: isChanged });
    }

    // Validate whether the values of the form are valid
    validateSubmission = fieldToValidate => {
        var isValid = true; 

        if (fieldToValidate === "dueDate" || fieldToValidate === "all") {
            var today = new Date();
            if (this.state.dueDate !== '' && this.state.dueDate < today) { 
                this.setState({isValidDueDate: "has-danger"});
                isValid = false;
            } else this.setState({isValidDueDate: ""});
        }
        this.setState({ validToSubmit: isValid });
    }

    render() {
        if (this.state && !this.state.loading) {
            return (
                <>
                    <Button color="link"
                            id={"createEditGoal"+this.state.key}
                            title=""
                            type="button" 
                            className="btn-no-spacing"
                            onClick={() => this.openMainModal()}>
                        <p className="text-muted"><i className={this.state.gDoc? "tim-icons icon-pencil": "tim-icons icon-simple-add"} /></p>
                        {this.props.buttonText? " "+this.props.buttonText : null }
                    </Button>
                    <UncontrolledTooltip delay={0}
                                        target={"createEditGoal"+this.state.key}
                                        placement="right" >
                        {this.state.gDoc? "Edit Goal": "Create New Goal"}
                    </UncontrolledTooltip>

                    <Modal isOpen={this.state.mainModal} toggle={() => this.onCloseModal()} size="lg" >
                        <ModalHeader tag="h4" toggle={() => this.onCloseModal()} >
                            {this.state.gDoc? "Edit Goal" : "New Goal"}
                        </ModalHeader>
                        <ModalBody>
                            <Form className="form-newgoal">
                                <FormGroup>
                                    <Label for="goal-description">Goal Description</Label>
                                    <Input
                                        id="goal-description"
                                        className="form-control"
                                        name = "description"
                                        value = {this.state.description}
                                        onChange = {e => this.onChange(e)}
                                        onBlur = {e => this.onChange(e)}
                                        type = "textarea"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="session-term">Term</Label>
                                    <Input
                                        id="goal-term"
                                        className="form-control"
                                        name = "term"
                                        value = {this.state.term}
                                        onChange = {e => this.onChange(e)}
                                        onBlur = {e => this.onChange(e)}
                                        type = "select">
                                    <option>Short</option>
                                    <option>Medium</option>
                                    <option>Long</option>
                                    </Input>
                                </FormGroup>
                                <FormGroup className={this.state.isValidDueDate}>
                                    <Label for="goal-dueDate">Due Date</Label>
                                    <Input 
                                        id="goal-dueDate"
                                        type="date" 
                                        name="dueDate" 
                                        value = {this.state.dueDate}
                                        onChange = {e => this.onChange(e)}
                                        onBlur = {e => this.onChange(e)}
                                        />
                                    <FormFeedback>Due date cannot be in the past</FormFeedback>
                                </FormGroup>
                            </Form>

                            <Modal isOpen={this.state.cancelConfirmationModal} toggle={() => this.onCloseModal()} size="sm">
                                <ModalHeader tag="h4" toggle={() => this.onCloseModal()} >Cancel Confirmation</ModalHeader>
                                <ModalBody>
                                    <center>
                                        You have unsaved changes, are you sure you would like to cancel?
                                    </center>
                                </ModalBody>
                                <ModalFooter>
                                    <i className="tim-icons icon-check-2" onClick={() => this.onConfirmationConfirm()}/>
                                    <i className="tim-icons icon-simple-remove" onClick={() => this.onConfirmationCancel()}/>
                                </ModalFooter>
                            </Modal>
                            {this.state.mainModalError && <>{this.state.mainModalError.message}</>}
                        </ModalBody>
                        <ModalFooter key={"goals_create_edit_modal_footer_"+this.state.uniqueKey}>
                            <i className="tim-icons icon-check-2" onClick={() => this.onSubmit()}/>
                            {this.state.gDoc? <i className="tim-icons icon-trash-simple" onClick={() => this.onRemove()}/> : null}
                            <i className="tim-icons icon-simple-remove" onClick={() => this.onCloseModal()}/>
                        </ModalFooter>
                    </Modal>
                </>
            )
        } else return "loading..."
    }
}



/***************************************************************************************/
/*************************** Goals - RenderCheckboxGoal ********************************/
/***************************************************************************************/

class RenderCheckboxGoalBase extends Component {
    constructor(props) {
        super(props);
        //props.uid
        //props.gDoc
        //props.renderCheckbox // boolean
        //props.refreshPage

        this.state = { status: props.gDoc.data.status,
                        updateError: null }

        this.onCreateAchivement = this.onCreateAchivement.bind(this);
    }

    // When the checkbox is modified, the status is updated between active and completed
    handleChangeChk = () => {
        var newStatus = "active"
        if (this.state.status === "active") newStatus = "completed";

        this.props.firebase.doUpdateGoalStatus(this.props.gDoc.id, newStatus)
        .then(() => {
            this.setState({status: newStatus});
            //this.props.refreshPage(); // comment out to prevent reload on check
        }).catch(error => {
            console.log(error)
            this.setState({updateError: error});
        });
    }

    // when an achivement is created, the goal is archived
    onCreateAchivement = (achievementDocID) => {
        this.props.firebase.doUpdateGoalItemAchievement(this.props.gDoc.id, achievementDocID)
        .then(() => {
            this.setState({status: "archived"});
            //this.props.refreshPage(); // comment out to prevent reload on check
        }).catch(error => {
            console.log(error)
            this.setState({updateError: error});
        });
    }

    // renders the "create achivement" button for completed goals
    renderAchievementAddModal() {
        if (this.state.status === "completed") {
            return(
                <AchievementAddModal uid={this.props.uid} goalDocID={this.props.gDoc.id} goalDescription={this.props.gDoc.data.description} onSubmit={this.onCreateAchivement} />
            )
        } 
    }

    // Renders the edit button for non archived goals
    renderEdit() {
        if (this.state.status !== "archived") {
            return(
                <GoalItemCreateEditModal gDoc={this.props.gDoc} refreshPage={this.props.refreshPage} />
            )
        } 
    }

    render() {
        return (
            <>
                {/* {this.renderWithCheckbox()} */}
                { this.props.renderCheckbox? 
                    <>
                        <td>
                            <FormGroup check>
                                <Label check>
                                    <Input type="checkbox" checked={this.state.status === "completed"} onChange={this.handleChangeChk} />
                                    <span className="form-check-sign">
                                        <span className="check" />
                                    </span>
                                </Label>
                            </FormGroup>
                        </td>
                        <td>
                            <p className="text-muted">
                                {this.props.gDoc.data.description}
                            </p>
                        </td>
                        <td className="td-actions text-right">
                            {this.renderEdit()}
                            {this.renderAchievementAddModal()}
                        </td>
                    </>
                    :
                    <>
                        <td>
                            <p className="text-muted">
                                <li>
                                    {this.props.gDoc.data.description}
                                </li>
                            </p>
                        </td>
                        <td className="td-actions text-right">
                            {this.renderEdit()}
                            {this.renderAchievementAddModal()}
                        </td>
                    </>
            }
                {this.state.updateError && <>{this.state.updateError.message}</>}
            </>
        )
    };
};


/***************************************************************************************/
/*************************** Goals - RenderCheckboxListGoal ****************************/
/***************************************************************************************/


class RenderCheckboxGoalListBase extends Component {
    // constructor(props) {
    //     super(props);
    //     //props.uid
    //     //props.gDocs
    //     //props.renderCheckbox // boolean
    //     //props.refreshPage
    // }

    render() {
        if (this.props.gDocs.length > 0) {
            return (
                <div className={this.props.renderScrollbar? "table-full-width table-responsive": "" } >
                    <Table>
                        <tbody>
                            { this.props.gDocs.map(gDoc => 
                                <tr key={"rendergoallist_item_"+gDoc.id}> 
                                    <RenderCheckboxGoal renderCheckbox={this.props.renderCheckbox} 
                                    uid={this.props.uid} 
                                    gDoc={gDoc} 
                                    refreshPage={this.props.refreshPage} />
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
            )
        } else return null
    };
};


/***************************************************************************************/
/*************************** Goals - RenderGoalList ************************************/
/***************************************************************************************/

// Renders the unordered list of the goals with the appropriate action button beside it
class RenderGoalListBase extends Component {
    // constructor(props) {
    //     super(props);
    //     //props.uid
    //     //props.goalsList
    //     //props.refreshPage
    //     //props.renderSML
    //     //props.renderCheckbox
    //     //props.renderCreateAchievement
    // }

    render() {
        if (this.props.goalsList !== null && this.props.goalsList !== undefined) {
            if (this.props.renderSML) {
                var goalListForShortTerm = [];
                var goalListForMediumTerm = [];
                var goalListForLongTerm = [];
                this.props.goalsList.forEach(gDoc => { if (gDoc.data.term === "Short") goalListForShortTerm.push(gDoc); })
                this.props.goalsList.forEach(gDoc => { if (gDoc.data.term === "Medium") goalListForMediumTerm.push(gDoc); })
                this.props.goalsList.forEach(gDoc => { if (gDoc.data.term === "Long") goalListForLongTerm.push(gDoc); })
                return (
                    
                        <div className="table-goals-full-height table-full-width table-responsive">
                            {goalListForShortTerm.length > 0?
                                <>
                                    <Row>
                                        <Col xs="12">
                                            <h5>Short Term</h5>
                                        </Col>
                                        </Row>
                                    <Row>
                                        <Col xs="12">
                                            <RenderCheckboxGoalListBase uid={this.props.uid} gDocs={goalListForShortTerm} renderCheckbox={this.props.renderCheckbox} refreshPage={this.props.refreshPage} renderScrollbar={false} />
                                        </Col>
                                    </Row>
                                    <br />
                                </>
                            : null }
                            {goalListForMediumTerm.length > 0?
                                <>
                                    <Row>
                                        <Col xs="12">
                                            <h5>Medium Term</h5>
                                        </Col>
                                        </Row>
                                    <Row>
                                        <Col xs="12">
                                            <RenderCheckboxGoalListBase uid={this.props.uid} gDocs={goalListForMediumTerm} renderCheckbox={this.props.renderCheckbox} refreshPage={this.props.refreshPage} renderScrollbar={false} />
                                        </Col>
                                    </Row>
                                    <br />
                                </>
                            : null }
                            {goalListForLongTerm.length > 0?
                                <>
                                    <Row>
                                        <Col xs="12">
                                            <h5>Long Term</h5>
                                        </Col>
                                        </Row>
                                    <Row>
                                        <Col xs="12">
                                            <RenderCheckboxGoalListBase uid={this.props.uid} gDocs={goalListForLongTerm} renderCheckbox={this.props.renderCheckbox} refreshPage={this.props.refreshPage} renderScrollbar={false} />
                                        </Col>
                                    </Row>
                                    <br />
                                </>
                            : null }
                            
                            {goalListForShortTerm.length === 0 && goalListForMediumTerm.length === 0 && goalListForLongTerm.length === 0? <center><h5>You don't have any active goals.<br />Create one now!</h5></center>: null}
                        </div>
                );
            } else {
                return (
                    <RenderCheckboxGoalListBase uid={this.props.uid} gDocs={this.props.goalsList} renderCheckbox={this.props.renderCheckbox} refreshPage={this.props.refreshPage} renderScrollbar={false} />
                )
            }
        } else return null;
    };
};


/***************************************************************************************/
/*************************** Goals - Mentorship Session Goals **************************/
/***************************************************************************************/

const INITIAL_STATE_GOALS_MENTORSHIP_SESSION = {
    loading: true,
    goalItemDocs: null,
    error: null
};

class MentorshipSessionGoalsBase extends Component {
    constructor(props) {
        super(props);
        //props.uid
        //props.mentorshipSessionID
        this.state={
           loading: false,
        }

        this.loadData = this.loadData.bind(this);
    }
    
    // Helper function to parse the return from firestore
    mapData = resultArray => {
        const returnArray = [];
        resultArray.forEach(doc => {
            returnArray.push({
                id: doc.id,
                data: doc.data(),
            });
        })
        return returnArray;
    };

    // Load feedback data from database
    componentDidMount() {
        this.loadData();
    }

    // Loads data from Firebase and sets loading state to false
    loadData = () => {
        this.setState({ loading: true });

        // Get goal summary
        this.props.firebase.doGetGoalsItemsByMentorshipSessionID(this.props.mentorshipSessionID).then(goalItemQS => {
            INITIAL_STATE_GOALS_MENTORSHIP_SESSION['goalItemDocs'] = this.mapData(goalItemQS);
            this.setState({...INITIAL_STATE_GOALS_MENTORSHIP_SESSION});
            this.setState({ loading: false });
        }).catch(error => {
            console.log(error)
            this.setState({loadingError: error});
        });
    }

    render() {
        if (this.state && !this.state.loading) {

            return (
                <>
                    <RenderGoalList uid={this.props.uid} goalsList={this.state.goalItemDocs} renderSML={true} renderCheckbox={true} refreshPage={this.loadData} />
                    {this.state.error && <p>{this.state.error.message}</p>}
                </>
            );
        }

        // While data is being loaded from database
        return "loading...";
    };
};



/***************************************************************************************/
/*************************** Goals - Render Active Goals *******************************/
/***************************************************************************************/

const INITIAL_STATE_GOALS_ACTIVE = {
    loading: false,
    activeGoalDocs: null,
    error: null
};

class ActiveGoalsBase extends Component {
    constructor(props) {
        super(props);
        //props.uid
        //props.didLoad
        this.loadData = this.loadData.bind(this);
    }

    // Helper function to parse the return from firestore
    mapData = resultArray => {
        const returnArray = [];
        resultArray.forEach(doc => {
            returnArray.push({
                id: doc.id,
                status: doc.data().status,
                data: doc.data(),
            });
        })
        return returnArray;
    };

    // Load feedback data from database
    componentDidMount() {
        this.loadData();
    }

    
    // Loads data from Firebase and sets loading state to false
    loadData = () => {
        this.setState({ loading: true });

        // get the goals for each of the statuses
        /* Future enhancement to include pagination and limiting for archived goals */
        this.props.firebase.doGetGoalItemByUID(this.props.uid, 'active').then(activeQS => {
            INITIAL_STATE_GOALS_ACTIVE['activeGoalDocs'] = this.mapData(activeQS);
            this.setState({ ...INITIAL_STATE_GOALS_ACTIVE, loading: false }
                            ,() => { if (this.props.didLoad !== undefined) this.props.didLoad() });
        }).catch(error => {
            console.log(error)
            this.setState({loadingError: error});
        });
    }

    render() {
        if (this.state && !this.state.loading) {
            return (
                <>
                    <RenderGoalList uid={this.props.uid} goalsList={this.state.activeGoalDocs} renderSML={true} renderCheckbox={true} refreshPage={this.loadData} />
                    {this.state.error && <p>{this.state.error.message}</p>}
                </>
                );
        }
        // While data is being loaded from database
        return "loading...";
    };
};


/***************************************************************************************/
/*************************** Goals - Render Completed Goal *****************************/
/***************************************************************************************/


const INITIAL_STATE_GOALS_COMPLETED = {
    loading: true,
    completedGoalDocs: null,
    archivedGoalDocs: null,
    error: null
};

class CompletedGoalsBase extends Component {
    constructor(props) {
        super(props);
        //props.uid
        //props.didLoad
        this.state={}
        this.loadData = this.loadData.bind(this);
    }


    // Helper function to parse the return from firestore
    mapData = resultArray => {
        const returnArray = [];
        resultArray.forEach(doc => {
            returnArray.push({
                id: doc.id,
                status: doc.data().status,
                data: doc.data(),
            });
        })
        return returnArray;
    };

    // Load feedback data from database
    componentDidMount() {
        this.loadData();
    }
    
    // Loads data from Firebase and sets loading state to false
    loadData = () => {
        this.setState({ loading: true });

        // get the goals for each of the statuses
        /* Future enhancement to include pagination and limiting for archived goals */
        this.props.firebase.doGetGoalItemByUID(this.props.uid, 'completed').then(completedQS => {
            INITIAL_STATE_GOALS_COMPLETED['completedGoalDocs'] = this.mapData(completedQS);

            this.props.firebase.doGetGoalItemByUID(this.props.uid, 'archived').then(archivedQS => {
                INITIAL_STATE_GOALS_COMPLETED['archivedGoalDocs'] = this.mapData(archivedQS);
            }).then(() => {
                this.setState({ ...INITIAL_STATE_GOALS_COMPLETED, loading: false }
                                ,() => { if (this.props.didLoad !== undefined) this.props.didLoad() });
            })
        }).catch(error => {
            this.setState({loadingError: error});
        });
    }


    render() {
        if (this.state && !this.state.loading) {
            return (
                <div className="table-goals-full-height table-full-width table-responsive">
                    <Row>
                        <Col xs="12">
                            <h5>Completed Goals</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            { this.state.completedGoalDocs !== null && this.state.completedGoalDocs !== undefined && this.state.completedGoalDocs.length > 0? 
                                <RenderGoalList goalsList={this.state.completedGoalDocs} renderCheckbox={true} uid={this.props.uid} refreshPage={this.loadData} />
                                : <center><p className="text-muted">Nothing to show here. Keep up the good work!</p><br/ ></center>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <h5>Archived Goals</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            { this.state.archivedGoalDocs !== null && this.state.archivedGoalDocs !== undefined && this.state.archivedGoalDocs.length > 0? 
                                <RenderGoalList goalsList={this.state.archivedGoalDocs} renderCheckbox={false} uid={this.props.uid} refreshPage={this.loadData} />
                                : <center><p className="text-muted">Nothing to show here. Keep up the good work!</p></center>
                            }
                        </Col>
                    </Row>
                </div>
            );
        } else return "loading..."
    };
};



const RenderGoalList = withFirebase(RenderGoalListBase);
const GoalItemCreateEditModal = withFirebase(GoalItemCreateEditModalBase);
const ActiveGoals = withFirebase(ActiveGoalsBase);
const CompletedGoals = withFirebase(CompletedGoalsBase);
const RenderCheckboxGoal = withFirebase(RenderCheckboxGoalBase);
const MentorshipSessionGoals = withFirebase(MentorshipSessionGoalsBase);

export {RenderGoalList, 
        GoalItemCreateEditModal,
        ActiveGoals,
        CompletedGoals,
        MentorshipSessionGoals};