import React, {Component} from 'react';
import {AuthUserContext, withAuthorization} from '../../Components/Session/Session';

import LoadingDiv from '../../Components/Misc/Loading'
import {MentorshipMenteeSummary, MentorshipMentorSummary} from '../../Components/Mentorship/MentorshipRelationship';
 
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import classnames from 'classnames';

const INITIAL_STATE = {
  something: false,
  activeTab: 'mentee',
  childrenToLoad: {
    MentorshipMenteeSummary: false,
    MentorshipMentorSummary: false
  },
};

class PerformanceView extends Component {
  constructor (props) {
      super(props);

      this.state = {...INITIAL_STATE};
      this.childrenDidLoad = this.childrenDidLoad.bind(this);
  };

  componentDidMount() {
    if (window.location.hash === "#mentor") this.setState({ activeTab: 'mentor' }); // if hash is set in the url then the default tab is loaded
  }

  isRenderComplete() {
    var isRenderCompleted = !this.state.loading;
    for (var key in this.state.childrenToLoad) {
        isRenderCompleted &= this.state.childrenToLoad[key];
        //console.log(key + " : " + this.state.childrenToLoad[key]);
    }
    this.setState({ rendering: !isRenderCompleted });
    if (isRenderCompleted && this.props.didLoad !== undefined) {
      this.props.didLoad();
    }
  }

  childrenDidLoad(component) {
      var { childrenToLoad } = this.state;
      childrenToLoad[component] = true;
      this.setState( { childrenToLoad: childrenToLoad } 
                      ,() => { this.isRenderComplete();  })
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      window.location.hash = tab; // set the hash for the url to determine default tab

      this.setState({
        activeTab: tab
      });
    }
  }

  render () {
    return (
      <div className="content">
        <Row>
          <Col md="12">
            <AuthUserContext.Consumer>
              {authUser => (
                <div>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === 'mentee' })}
                        onClick={() => { this.toggle('mentee'); }}
                      >
                        Your Mentors
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === 'mentor' })}
                        onClick={() => { this.toggle('mentor'); }}
                      >
                        Your Mentees
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <br />
                  <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="mentee">
                      <LoadingDiv />
                      <MentorshipMenteeSummary uid={authUser.uid} email={authUser.email} didLoad={() => this.childrenDidLoad("MentorshipMenteeSummary")} />
                    </TabPane>
                    <TabPane tabId="mentor">
                      <LoadingDiv />
                      <MentorshipMentorSummary uid={authUser.uid} email={authUser.email} didLoad={() => this.childrenDidLoad("MentorshipMentorSummary")} />
                    </TabPane>
                  </TabContent>
                </div>
              )}
            </AuthUserContext.Consumer>
          </Col>
        </Row>
      </div>

      
    );
  };
};

const condition = authUser => !!authUser;

export default withAuthorization(condition)(PerformanceView);
