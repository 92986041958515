import React, {Component} from 'react';
import {withFirebase} from '../Firebase/Firebase';
import {
    Button, 
    FormGroup, 
    Label, 
    Input, 
    Row,
    Col,
    Alert
} from 'reactstrap';

const INITIAL_STATE_FEEDBACK_LOOKUP = {
    userFound: false,
    isValidEmail: false,
    isValidated: false, 
    email: '',
}

class UserLookupFeedbackBase extends Component {
    constructor(props) {
        super(props);

        //this.props.setReviewer;
        //this.props.clearReviewer;
        //this.props.divClassName;
        //this.props.alertMessage; // optional, if not provided it is not shown

        this.state = {...INITIAL_STATE_FEEDBACK_LOOKUP};
    }

    validateEmail = email => {
        let isValid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(email);
        this.setState({ isValidEmail: isValid });
    }

    onChange = event => {
        this.validateEmail(event.target.value);
        this.setState({ 
            [event.target.name]: event.target.value,
         });
    }

    onSubmit = () => {
        if (this.state.isValidated) {
            this.setState({...INITIAL_STATE_FEEDBACK_LOOKUP});
            this.props.clearReviewer();
        } else {
            this.props.firebase.doGetUserByEmail(this.state.email)
            .then(userRecord => {
                //console.log();
                if (userRecord.docs[0] === undefined) {
                    let retObj = {
                        uid: null,
                        email: this.state.email,
                        firstName: '',
                        lastName: '',
                    }
                    this.props.setReviewer(retObj);
                } else {
                    let retObj = {
                        uid: userRecord.docs[0].id,
                        email: userRecord.docs[0].data().email,
                        firstName: userRecord.docs[0].data().firstName,
                        lastName: userRecord.docs[0].data().lastName,
                    }
                    this.props.setReviewer(retObj);
                }
                this.setState({isValidated: true});
            })
        }
    }


    render () {
        return (
            <FormGroup className={this.props.divClassName} >
                <Label for="feedback-email">Email</Label>
                <Row>
                    <Col xs="9">
                        <Input
                            id="feedback-projectName"
                            className="form-control"
                            name = "email"
                            value = {this.state.email}
                            onChange = {this.onChange}
                            onBlur = {this.onChange}
                            type = "text"
                            disabled = {this.state.isValidated}
                            required
                        />
                    </Col>
                    <Col xs="3">
                        <Button block
                                color="primary" 
                                disabled={(!this.state.isValidEmail && !this.state.isValidated) } 
                                onClick={() => this.onSubmit()}> 
                            {this.state.isValidated ? "Clear": "Lookup"}
                        </Button>
                    </Col>
                </Row>
                    {/* <Collapse>
                    
                    <Row>
                        <Col md="12">
                            <div class="alert alert-info fade show" role="alert"><span></span></div>
                        </Col>
                    </Row>
                    </Collapse> */}
                
                {this.state.isValidated && this.props.alertMessage !== undefined?
                    <Row>
                        <Col xs="12">
                                <br />
                                <Alert className="alert-with-icon" color="info">
                                    <span
                                        className="tim-icons icon-bell-55"
                                        data-notify="icon"
                                    />
                                    <span data-notify="message">
                                        {this.props.alertMessage}
                                    </span>
                                </Alert>
                        </Col>
                    </Row>
                : null}
            </FormGroup>

        )
    }
}



const UserLookupFeedback = withFirebase(UserLookupFeedbackBase);

export {UserLookupFeedback};