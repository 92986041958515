import React, {Component} from 'react';
import {AuthUserContext, withAuthorization} from '../../Components/Session/Session';

import LoadingDiv from '../../Components/Misc/Loading'
import EducationHistory from '../../Components/Education/Education';
import CertificationHistory from '../../Components/Certification/Certification';

import {
  Row,
  Col
} from "reactstrap";

const INITIAL_STATE = {
  rendering: true,
  childrenToLoad: {
    EducationHistory: false,
    CertificationHistory: false
  },
};

class EducationView extends Component {
  constructor (props) {
      super(props);
      this.childrenDidLoad = this.childrenDidLoad.bind(this);

      this.state = {...INITIAL_STATE};
  };

  isRenderComplete() {
    var isRenderCompleted = !this.state.loading;
    for (var key in this.state.childrenToLoad) {
        isRenderCompleted &= this.state.childrenToLoad[key];
        //console.log(key + " : " + this.state.childrenToLoad[key]);
    }
    this.setState({ rendering: !isRenderCompleted });
    if (isRenderCompleted && this.props.didLoad() !== undefined) this.props.didLoad();
  }

  childrenDidLoad(component) {
      var { childrenToLoad } = this.state;
      childrenToLoad[component] = true;
      this.setState( { childrenToLoad: childrenToLoad } 
                      ,() => { this.isRenderComplete();  })
  }

  render () {
    return (
      <div className="content">
        <Row>
          <Col md="12">
            <AuthUserContext.Consumer>
              {authUser => (
                <>
                  <LoadingDiv />
                  <EducationHistory uid={authUser.uid} didLoad={() => this.childrenDidLoad("EducationHistory")}/>
                  <CertificationHistory uid={authUser.uid} didLoad={() => this.childrenDidLoad("CertificationHistory")} />
                </>
              )}
            </AuthUserContext.Consumer>
          </Col>
        </Row>
      </div>
    );
  };
};

const condition = authUser => !!authUser;

export default withAuthorization(condition)(EducationView);
