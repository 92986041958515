import React, {Component} from 'react';
import {withFirebase} from '../Firebase/Firebase';
import moment from 'moment';
import {
    Button, 
    Form, 
    FormGroup, 
    Label, 
    Input, 
    Row,
    Col,
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    Card, 
    CardTitle,
    CardHeader,
    CardBody,
    Collapse,
    UncontrolledTooltip
} from 'reactstrap';
import {MentorshipSessionGoals, GoalItemCreateEditModal} from '../Mentorship/Goal'
import {MentorshipSessionActionItems, ActionItemCreateEditModal} from '../Mentorship/ActionItem'

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';



/***************************************************************************************/
/*************************** Session - Create New Session ****************************/
/***************************************************************************************/

const INITIAL_STATE_CREATEMODAL = {
    loading: true,
    changed: false,
    validToSubmit: false,
    mainCreateModal: false,
    confirmationModal: null,

    isValidDateTime: '',

    location: '',
    locationMap: '',
    dateTime: '',
    notesGeneral: '',

    errorCreateModal: null,
    errorConfirmModal: null,
};

class CreateNewSessionModalBase extends Component {
    constructor(props) {
        super(props);
        //props.mentorshipID
        //props.buttonText
        //props.refreshPage
        this.state = {...INITIAL_STATE_CREATEMODAL};
    }

    
    // Launches the main feedback modal
    openCreateModal = () => {
        this.setState({mainCreateModal: true});
    }

    // Submit button for the main feedback modal
    onSubmit = () => {
        if (this.validateSubmission("all")) {
            // call to firestore to create the FS entry
            return this.props.firebase.doCreateMentorshipSession( // then create the new mentorship relationship in firestore
                this.props.mentorshipID,
                this.state.location,
                this.state.locationMap, // for future map integration
                this.state.dateTime,
                this.state.notesGeneral
            ).then (() => {
                this.setState({...INITIAL_STATE_CREATEMODAL});
                this.props.refreshPage();
            }).catch(error => {
                this.setState({errorConfirmModal: error});
            })
        } else this.setState({ errorCreateModal: new Error("There may be invalid values or missing required fields.")});
    }

    // Confirm button on the submit/cancel confirmation modal
    onConfirmationConfirm = () => {
        if (this.state.confirmationModal === "cancel") this.setState({...INITIAL_STATE_CREATEMODAL});
        //else if (this.state.confirmationModal === "submit") this.submitCreateMentorshipSession();
        else this.setState({ errorConfirmModal: new Error("Invalid action provided for confirmation") });
    }

    // Cancels the confirmation model, returns back to main modal
    onConfirmationCancel = () => {
        this.setState({confirmationModal: null});
    }

    // Close button action for main modal
    closeCreateModal = () => {
        if (this.state.changed) this.setState({confirmationModal: "cancel"});
        else this.setState({...INITIAL_STATE_CREATEMODAL});
    } 

    onChange = event => {
        event.persist(); // allows event to be available to the callback

        this.setState({
            [event.target.name]: event.target.value
        }, () => {
            this.setState({ validToSubmit: this.validateSubmission(event.target.name) });
            this.isChanged();
        });
    };

    // Validate whether there are changes to the form
    isChanged() {
        let isChanged = this.state.location !== INITIAL_STATE_CREATEMODAL["location"]
                        || this.state.dateTime !== INITIAL_STATE_CREATEMODAL["dateTime"]
                        || this.state.notesGeneral !== INITIAL_STATE_CREATEMODAL["notesGeneral"];

        this.setState({ changed: isChanged });
    }

    // Validate whether the values of the form are valid
    validateSubmission = fieldToValidate => {
        var isValid = true; 

        if (fieldToValidate === "dateTime" || fieldToValidate === "all") {
            var today = new Date();
            if (this.state.dateTime !== '' && this.state.dateTime < today) { 
                this.setState({isValidDateTime: "has-danger"});
                isValid = false;
            } else this.setState({isValidDateTime: ""});
        }
        return isValid;
    }

    render() {
        return (
            <>
                <Button block 
                        color="primary"
                        id={"NewMentorShipSessionButton"}
                        title=""
                        type="button" 
                        onClick={() => this.openCreateModal()} >
                    <i className="tim-icons icon-simple-add" />
                    { this.props.buttonText? " "+this.props.buttonText : null }
                </Button>

                <Modal isOpen={this.state.mainCreateModal} toggle={() => this.closeCreateModal()} size="lg">
                    <ModalHeader tag="h4" toggle={() => this.closeCreateModal()}>New Mentorship Session</ModalHeader>
                    <ModalBody>
                        <Form className="form-newMentorshipSession">
                            
                            <FormGroup className={this.state.isValidDateTime}>
                                <Label for="mentorshipSession-dateTime">Session Date</Label>
                                <Input 
                                    id="mentorshipSession-dateTime"
                                    type="date" 
                                    name="dateTime" 
                                    value = {this.state.dateTime}
                                    onChange = {e => this.onChange(e)}
                                    onBlur = {e => this.onChange(e)}
                                    />
                            </FormGroup>
                            <FormGroup>
                                <Label for="mentorshipSession-location">Meeting Location</Label>
                                <Input
                                    id="mentorshipSession-location"
                                    className="form-control"
                                    name = "location"
                                    value = {this.state.location}
                                    onChange = {e => this.onChange(e)}
                                    onBlur = {e => this.onChange(e)}
                                    type = "text"
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="session-notesGeneral">General Notes / Session Goals</Label>
                                <Input
                                    id="mentorshipSession-notesGeneral"
                                    className="form-control"
                                    name = "notesGeneral"
                                    value = {this.state.notesGeneral}
                                    onChange = {e => this.onChange(e)}
                                    onBlur = {e => this.onChange(e)}
                                    type = "textarea"
                                />
                            </FormGroup>
                        </Form>

                        <Modal isOpen={this.state.confirmationModal !== null} toggle={() => this.onConfirmationCancel()} size="sm">
                            <ModalHeader tag="h4" toggle={() => this.onConfirmationCancel()}>{this.state.confirmationModal === "submit"? "Submit" : "Cancel"} Confirmation</ModalHeader>
                            <ModalBody>
                                <center>
                                    Are you sure you would like to {this.state.confirmationModal === "submit"? "submit?" : "cancel?"}
                                </center>
                            </ModalBody>
                            <ModalFooter>
                                <i className="tim-icons icon-check-2" onClick={() => this.onConfirmationConfirm()}/>
                                <i className="tim-icons icon-simple-remove" onClick={() => this.onConfirmationCancel()}/>
                                {this.state.errorConfirmModal && <p>{this.state.errorConfirmModal.message}</p>}
                            </ModalFooter>
                        </Modal>
                        {this.state.errorCreateModal && <>{this.state.errorCreateModal.message}</>}
                    </ModalBody>
                    <ModalFooter>
                        <i className="tim-icons icon-check-2" onClick={() => this.onSubmit()}/>
                        <i className="tim-icons icon-simple-remove" onClick={() => this.closeCreateModal()}/>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}


/***************************************************************************************/
/*************************** Session - Reschedule / Edit Session ****************************/
/***************************************************************************************/

const INITIAL_STATE_EDIT_MODAL = {
    loading: true,
    changed: false,
    validToSubmit: false,
    mainModal: false,
    confirmationModal: null,

    isValidDateTime: '',

    location: '',
    locationMap: '',
    dateTime: '',
    notesGeneral: '',

    errorMainModal: null,
    errorConfirmModal: null,
};

class EditSessionModalBase extends Component {
    constructor(props) {
        super(props);
        //props.sessionID
        //props.sessionDoc
        //props.buttonText
        //props.refreshPage
        //props.renderButton
        INITIAL_STATE_EDIT_MODAL["status"] = props.sessionDoc.status;
        INITIAL_STATE_EDIT_MODAL["location"] = props.sessionDoc.location;
        INITIAL_STATE_EDIT_MODAL["locationMap"] = props.sessionDoc.locationMap;
        INITIAL_STATE_EDIT_MODAL["dateTime"] = props.sessionDoc.dateTime;
        INITIAL_STATE_EDIT_MODAL["notesGeneral"] = props.sessionDoc.notesGeneral;
        this.state = {...INITIAL_STATE_EDIT_MODAL};
    }

    
    // Launches the main feedback modal
    openCreateModal = () => {
        this.setState({mainModal: true});
    }

    // Submit button for the main feedback modal
    onSubmit = () => {
        if (this.validateSubmission("all")) this.setState({confirmationModal: "submit"});
        else this.setState({ errorMainModal: new Error("There may be invalid values or missing required fields.")});
    }

    // call to firestore to create the FS entry
    submitEditMentorshipSession = () => {
        if (this.state.dateTime !== INITIAL_STATE_EDIT_MODAL["dateTime"])
            this.setState({status: "rescheduled"});

        return this.props.firebase.doEditBasicMentorshipSession( // then create the new mentorship relationship in firestore
            this.props.sessionID,
            this.state.status,
            this.state.location,
            this.state.locationMap, // for future map integration
            this.state.dateTime,
            this.state.notesGeneral
        ).then (() => {
            this.setState({...INITIAL_STATE_EDIT_MODAL});
            this.props.refreshPage();
        }).catch(error => {
            this.setState({errorConfirmModal: error});
        })
    }

    // Confirm button on the submit/cancel confirmation modal
    onConfirmationConfirm = () => {
        if (this.state.confirmationModal === "cancel") this.setState({...INITIAL_STATE_EDIT_MODAL});
        else if (this.state.confirmationModal === "submit") {
            this.submitEditMentorshipSession();
        } else this.setState({ errorConfirmModal: new Error("Invalid action provided for confirmation") });
    }

    // Cancels the confirmation model, returns back to main modal
    onConfirmationCancel = () => {
        this.setState({confirmationModal: null});
    }

    // Close button action for main modal
    closeCreateModal = () => {
        if (this.state.changed) this.setState({confirmationModal: "cancel"});
        else this.setState({...INITIAL_STATE_EDIT_MODAL});
    } 

    onChange = event => {
        event.persist(); // allows event to be available to the callback

        this.setState({
            [event.target.name]: event.target.value
        }, () => {
            this.setState({ validToSubmit: this.validateSubmission(event.target.name) });
            this.isChanged();
        });
    };

    // Validate whether there are changes to the form
    isChanged() {
        let isChanged = this.state.location !== INITIAL_STATE_EDIT_MODAL["location"]
                        || this.state.dateTime !== INITIAL_STATE_EDIT_MODAL["dateTime"]
                        || this.state.notesGeneral !== INITIAL_STATE_EDIT_MODAL["notesGeneral"];

        this.setState({ changed: isChanged });
    }

    // Validate whether the values of the form are valid
    validateSubmission = fieldToValidate => {
        var isValid = true; 

        if (fieldToValidate === "dateTime" || fieldToValidate === "all") {
            var today = new Date();
            if (this.state.dateTime !== '' && this.state.dateTime < today) { 
                this.setState({isValidDateTime: "has-danger"});
                isValid = false;
            } else this.setState({isValidDateTime: ""});
        }
        return isValid;
    }

    render() {
        return (
            <div>
                {this.props.renderButton? 
                    <Button block color="primary" onClick={() => this.openCreateModal()}>
                        <i className="tim-icons icon-pencil" />
                        { this.props.buttonText? " "+this.props.buttonText : null }
                    </Button>
                    : <div onClick={() => this.openCreateModal()}>
                        <i className="tim-icons icon-pencil" />
                        { this.props.buttonText? " "+this.props.buttonText : null }
                    </div>
                } 

                <Modal isOpen={this.state.mainModal} toggle={() => this.closeCreateModal()} size="lg">
                    <ModalHeader tag="h4" toggle={() => this.closeCreateModal()}>Edit Mentorship Session</ModalHeader>
                    <ModalBody>
                        <Form className="form-newMentorshipSession">
                            
                            <FormGroup className={this.state.isValidDateTime}>
                                <Label for="mentorshipSession-dateTime">Session Date</Label>
                                <Input 
                                    id="mentorshipSession-dateTime"
                                    type="date" 
                                    name="dateTime" 
                                    value = {this.state.dateTime}
                                    onChange = {e => this.onChange(e)}
                                    onBlur = {e => this.onChange(e)}
                                    />
                            </FormGroup>
                            <FormGroup>
                                <Label for="mentorshipSession-location">Meeting Location</Label>
                                <Input
                                    id="mentorshipSession-location"
                                    className="form-control"
                                    name = "location"
                                    value = {this.state.location}
                                    onChange = {e => this.onChange(e)}
                                    onBlur = {e => this.onChange(e)}
                                    type = "text"
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="session-notesGeneral">General Notes / Session Goals</Label>
                                <Input
                                    id="mentorshipSession-notesGeneral"
                                    className="form-control"
                                    name = "notesGeneral"
                                    value = {this.state.notesGeneral}
                                    onChange = {e => this.onChange(e)}
                                    onBlur = {e => this.onChange(e)}
                                    type = "textarea"
                                />
                            </FormGroup>
                        </Form>

                        <Modal isOpen={this.state.confirmationModal !== null} toggle={() => this.onConfirmationCancel()} size="sm">
                            <ModalHeader tag="h4" toggle={() => this.onConfirmationCancel()}>{this.state.confirmationModal === "submit"? "Submit" : "Cancel"} Confirmation</ModalHeader>
                            <ModalBody>
                                <center>
                                    Are you sure you would like to {this.state.confirmationModal === "submit"? "submit?" : "cancel?"}
                                </center>
                            </ModalBody>
                            <ModalFooter>
                                <i className="tim-icons icon-check-2" onClick={() => this.onConfirmationConfirm()}/>
                                <i className="tim-icons icon-simple-remove" onClick={() => this.onConfirmationCancel()}/>
                                {this.state.errorConfirmModal && <p>{this.state.errorConfirmModal.message}</p>}
                            </ModalFooter>
                        </Modal>
                        {this.state.errorMainModal && <>{this.state.errorMainModal.message}</>}
                    </ModalBody>
                    <ModalFooter>
                        <i className="tim-icons icon-simple-add" onClick={() => this.onSubmit()}/>
                        <i className="tim-icons icon-simple-remove" onClick={() => this.closeCreateModal()}/>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

/***************************************************************************************/
/*************************** Session - Status actions (start, pause, complete) ****************************/
/***************************************************************************************/

const INITIAL_STATE_STATUS_ACTION = {
    loading: true,
    updateStatus: '',
    buttonText: ''
};

class SessionStatusActionBase extends Component {
    constructor(props) {
        super(props);
        //props.sessionID
        //props.mentorshipID // only required if status is complete
        //props.action [start,pause,complete]
        //props.refreshPage
        if (props.action === "start") {
            INITIAL_STATE_STATUS_ACTION["updateStatus"] = "open";
            INITIAL_STATE_STATUS_ACTION["buttonText"] = "Start Session";
            INITIAL_STATE_STATUS_ACTION["buttonIcon"] = "tim-icons icon-triangle-right-17";
            INITIAL_STATE_STATUS_ACTION["buttonStyle"] = "primary";
        } else if (props.action === "pause") {
            INITIAL_STATE_STATUS_ACTION["updateStatus"] = "paused";
            INITIAL_STATE_STATUS_ACTION["buttonText"] = "Pause Session";
            INITIAL_STATE_STATUS_ACTION["buttonIcon"] = "tim-icons icon-button-pause";
            INITIAL_STATE_STATUS_ACTION["buttonStyle"] = "primary";
        } else if (props.action === "cancel") {
            INITIAL_STATE_STATUS_ACTION["updateStatus"] = "cancelled";
            INITIAL_STATE_STATUS_ACTION["buttonText"] = "Cancel Session";
            INITIAL_STATE_STATUS_ACTION["buttonIcon"] = "tim-icons icon-simple-remove";
            INITIAL_STATE_STATUS_ACTION["buttonStyle"] = "danger";
        } else if (props.action === "complete") {
            INITIAL_STATE_STATUS_ACTION["updateStatus"] = "completed";
            INITIAL_STATE_STATUS_ACTION["buttonText"] = "Complete Session";
            INITIAL_STATE_STATUS_ACTION["buttonIcon"] = "tim-icons icon-check-2";
            INITIAL_STATE_STATUS_ACTION["buttonStyle"] = "secondary";
        } else 
            throw new Error("Invalid value provided for 'action'. Provided value: '" + props.action + "' Expected values: [start, pause, complete]");

        this.state = {...INITIAL_STATE_STATUS_ACTION};
    }

    // call to firestore to create the FS entry
    submitSessionStatus = () => {
        return this.props.firebase.doUpdateStatusMentorshipSession( // then create the new mentorship relationship in firestore
            this.props.sessionID,
            this.props.mentorshipID,
            this.state.updateStatus
        ).then (() => {
            this.setState({...INITIAL_STATE_STATUS_ACTION});
            this.props.refreshPage();
        }).catch(error => {
            //this.setState({errorConfirmModal: error});
        })
    }

    render() {
        return (
            <Button block color={INITIAL_STATE_STATUS_ACTION["buttonStyle"]} onClick={() => this.submitSessionStatus()}>
                <i className={INITIAL_STATE_STATUS_ACTION["buttonIcon"]} />
                { " " + this.state.buttonText }
            </Button>
        )
    }
}


/***************************************************************************************/
/*************************** Session - Next Session Details ************************************/
/***************************************************************************************/

const INITIAL_STATE_NEXT_SESSION_DETAILS = {
    loading: false,
    sDoc: null,
    error: null
};

class NextSessionDetailsBase extends Component {
    constructor(props) {
        super(props);
        //props.uid
        //props.sessionID
        //props.mentorshipID
        //props.refreshPage
        //props.renderFor [mentor|mentee]
        //props.didLoad
        this.state={...INITIAL_STATE_NEXT_SESSION_DETAILS}
        this.loadData = this.loadData.bind(this);
    }

    // Loads data from Firebase and sets loading state to false
    loadData = () => {
        if (this.props.sessionID !== null && this.props.sessionID !== '' && this.props.sessionID !== undefined) {
            this.setState({ loading: true });

            this.props.firebase.doGetMentorshipSessionByID(this.props.sessionID)
            .then(QuerySnapshot => {
                if (QuerySnapshot.data() !== undefined)
                    this.setState({ sDoc: QuerySnapshot.data(), loading: false }
                    ,() => { if (this.props.didLoad !== undefined) this.props.didLoad() });
            }).catch(error => {
                console.log(error);
                this.setState({error});
            }); 
        } else {
            this.setState({ loading: false }
            ,() => { if (this.props.didLoad !== undefined) this.props.didLoad() });
        }
    }
    
    // Load session data from fs on load
    componentDidMount() {
        this.loadData();
    }

    render() {
        if (this.state && !this.state.loading) {
            if (this.state.sDoc !== null && this.state.sDoc !== undefined) {
                return (
                    <>
                        { ['new', 'paused'].includes(this.state.sDoc.status) ?
                            <>
                                <CardTitle tag="h4">Next Session Details</CardTitle>
                                <table>
                                    <tr>
                                        <td>
                                            <p><b>Date: </b></p>
                                        </td>
                                        <td>
                                            <p>{this.state.sDoc.dateTime}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p><b>Location:</b></p>
                                        </td>
                                        <td>
                                            <p>{this.state.sDoc.location}</p>
                                        </td>
                                    </tr>
                                </table>
                                <Row>
                                    <Col md="6" xs="12">
                                        <SessionStatusAction action="start" sessionID={this.props.sessionID} refreshPage={this.props.refreshPage} />
                                    </Col>
                                    <Col md="6" xs="12">
                                        <EditSessionModal renderButton={true} buttonText="Edit Session" sessionID={this.props.sessionID} sessionDoc={this.state.sDoc} refreshPage={this.props.refreshPage} />
                                    </Col>
                                </Row>
                            </> :
                            <> 
                                <h4>Current Session Details</h4>
                                <RenderSingleSessionSummary sDoc={this.state.sDoc} 
                                                            uid={this.props.uid}
                                                            sessionID={this.props.sessionID} 
                                                            refreshPage={this.props.refreshPage}
                                                            excludeHeader={true}
                                                            renderFor={this.props.renderFor}
                                                            />
                                
                                <Row>
                                    {/* <Col md="6" xs="12">
                                        <SessionStatusAction action="cancel" mentorshipID={this.props.mentorshipID} sessionID={this.props.sessionID} refreshPage={this.props.refreshPage} />
                                    </Col> */}
                                    <Col xs="12">
                                        <SessionStatusAction action="complete" mentorshipID={this.props.mentorshipID} sessionID={this.props.sessionID} refreshPage={this.props.refreshPage} />
                                    </Col>
                                </Row>
                            </>
                        }
                    </>
                );
            }else {
                return (
                    <>
                        <CardTitle tag="h4">No Upcoming Sessions</CardTitle>
                        <CreateNewSessionModal buttonText="New Mentorship Session" mentorshipID={this.props.mentorshipID} refreshPage={this.props.refreshPage} />
                    </>
                )
            }
        } else return "loading...";
    };
};


/***************************************************************************************/
/*************************** Session - Render Single Session Summary ************/
/***************************************************************************************/


const INITIAL_STATE_RENDER_SINGLE_SESSION_SUMMARY = {
    loading: true,
    sDoc: null,
    error: null,
    collapsed: false,
    notesGeneral: "",
    notesMentee: "",
    notesMentor: "",
    changed: false,
};

class RenderSingleSessionSummaryBase extends Component {
    constructor(props) {
        super(props);
        //props.uid
        //props.sDoc //optional.. pass in the doc directly from another component to prevent retrieval
        //props.collapsed // if true/false if passed then include collapse, if Null dont show chevron
        //props.sessionID
        //props.refreshPage
        //props.title
        //props.excludeHeader // Optional, if False, it is skipped
        //props.boldHeader // boolean
        //props.renderFor [mentor|mentee]
        //props.didLoad
        this.state={...INITIAL_STATE_RENDER_SINGLE_SESSION_SUMMARY}
        this.loadData = this.loadData.bind(this);
    }

    // Loads data from Firebase and sets loading state to false
    loadData = () => {
        this.setState({loading: true, collapsed: this.props.collapsed});

        if (this.props.sDoc !== null && this.props.sDoc !== '' && this.props.sDoc !== undefined) {
            this.setState({
                sDoc: this.props.sDoc,
                notesGeneral: this.props.sDoc.notesGeneral,
                notesMentee: this.props.sDoc.notesMentee,
                notesMentor: this.props.sDoc.notesMentor,
                loading: false
            });
        } else {
            if (this.props.sessionID !== null && this.props.sessionID !== '' && this.props.sessionID !== undefined) {
                if (this.state.sDoc === null) {
                    // only retrieve if the sDoc is not passed in
                    this.props.firebase.doGetMentorshipSessionByID(this.props.sessionID)
                    .then(QuerySnapshot => {
                        // TODO: Retrieve Goals
                        if (QuerySnapshot.data() !== undefined)
                            this.setState({
                                sDoc: QuerySnapshot.data(),
                                notesGeneral: QuerySnapshot.data().notesGeneral,
                                notesMentee: QuerySnapshot.data().notesMentee,
                                notesMentor: QuerySnapshot.data().notesMentor,
                                loading: false }
                                ,() => { if (this.props.didLoad !== undefined) this.props.didLoad() });
                    }).catch(error => {
                        console.log(error);
                        this.setState({error, loading: false});
                    }); 
                } else {
                    this.setState({loading: false});
                }
            } else {
                this.setState({loading: false});
            }
        }
    }
    
    // Load session data from fs on load
    componentDidMount() {
        this.loadData();
    }

    // Calls to update the updated session details
    saveSession() {
        return this.props.firebase.doEditNotesMentorshipSession( 
            this.props.sessionID,
            this.state.notesGeneral,
            this.state.notesMentee,
            this.state.notesMentor
        ).then (() => {
            this.setState({...INITIAL_STATE_EDIT_MODAL});
            this.props.refreshPage();
        }).catch(error => {
            this.setState({errorConfirmModal: error});
        })
    }

    onChange = event => {
        event.persist(); // allows event to be available to the callback

        this.setState({
            [event.target.name]: event.target.value
        }, () => {
            //this.setState({ validToSubmit: this.validateSubmission(event.target.name) });
            this.isChanged();
        });
    };

    // Validate whether there are changes to the form
    isChanged() {
        let isChanged = this.state.notesGeneral !== this.state.sDoc.notesGeneral
                        || this.state.notesMentee !== this.state.sDoc.notesMentee
                        || this.state.notesMentor !== this.state.sDoc.notesMentor;
        this.setState({ changed: isChanged });
    }

    // Toggle the visibility of the card body
    toggleCollapse = () => {
        this.setState(state => ({ collapsed: !state.collapsed }));
    }

    render() {
        if (this.state && !this.state.loading) {
            if (this.state.sDoc === null) return null; // no doc was found
            if (!['mentor', 'mentee'].includes(this.props.renderFor)) return null; // no doc was found
            return (
                <>
                    {this.props.excludeHeader? null: 
                        <>
                            <hr />
                            <Card>
                                <CardHeader className="list-header">
                                    <Row>
                                        <Col xs="10">
                                            <CardTitle tag="h5">
                                                {this.props.boldHeader? 
                                                    <i>{this.props.title? this.props.title + " - " : null} {moment(this.state.sDoc.dateTime).format("MMM DD, YYYY")}</i>
                                                    :<>{this.props.title? this.props.title + " - " : null} {moment(this.state.sDoc.dateTime).format("MMM DD, YYYY")}</>
                                                }
                                            </CardTitle>
                                        </Col>
                                        <Col>
                                            <Button color="link btn-action-icon"
                                                    id={"showHideSession"+this.props.sessionID}
                                                    title=""
                                                    type="button" 
                                                    onClick={() => this.toggleCollapse()} >
                                                <i className={this.state.collapsed? "tim-icons icon-minimal-up": "tim-icons icon-minimal-down" } />
                                            </Button>
                                            <UncontrolledTooltip delay={0}
                                                                target={"showHideSession"+this.props.sessionID}
                                                                placement="right" >
                                                {this.state.collapsed? "Expand Session": "Collapse Session" }
                                            </UncontrolledTooltip>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody className="list-body">
                                    
                                </CardBody>
                            </Card>
                        </>
                    }
                    <Collapse isOpen={!this.state.collapsed}>
                        {this.props.renderFor === "mentor"? 
                            <>
                                <Row>
                                    <Col xs="10">
                                        <h5>Goals Set</h5>
                                    </Col>
                                    <Col xs="2" className="text-right">
                                        <GoalItemCreateEditModal uid={this.props.uid} mentorshipSessionID={this.props.sessionID} refreshPage={this.loadData} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12">
                                        <MentorshipSessionGoals uid={this.props.uid} mentorshipSessionID={this.props.sessionID} />
                                    </Col>
                                </Row>
                                
                            </>
                            : null
                        }
                        {this.props.renderFor === "mentee"? 
                            <>
                                <Row>
                                    <Col xs="10">
                                        <h5>Action Items</h5>
                                    </Col>
                                    <Col xs="2" className="text-right">
                                        <ActionItemCreateEditModal uid={this.props.uid} mentorshipSessionID={this.props.sessionID} refreshPage={this.loadData} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12">
                                        <MentorshipSessionActionItems uid={this.props.uid} mentorshipSessionID={this.props.sessionID} />
                                    </Col>
                                </Row>
                            </>
                                : null
                        }
                        <Row>
                            <Col xs="12">
                                <h5>General Notes (Shared)</h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12">
                                <Form className="form-mentorshipSession">
                                    <FormGroup>
                                        <Input
                                            id="mentorshipSession-notesGeneral-mentee"
                                            className="form-control"
                                            name = "notesGeneral"
                                            value = {this.state.notesGeneral}
                                            onChange = {e => this.onChange(e)}
                                            onBlur = {e => this.onChange(e)}
                                            type = "textarea"
                                        />
                                    </FormGroup>
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12">
                                <h5>Your Notes (Private)</h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12">
                                {this.props.renderFor === "mentor"? 
                                    // if render mentor section, then it is mentee requesting the render so the mentee's private notes should be shown
                                    <Form className="form-mentorshipSession">
                                        <FormGroup>
                                            <Input
                                                id="mentorshipSession-notesMentee"
                                                className="form-control"
                                                name = "notesMentee"
                                                value = {this.state.notesMentee}
                                                onChange = {e => this.onChange(e)}
                                                onBlur = {e => this.onChange(e)}
                                                type = "textarea"
                                            />
                                        </FormGroup>
                                    </Form>
                                    : null
                                }
                                {this.props.renderFor === "mentee"? 
                                    // if render mentee section, then it is mentor requesting the render so the mentor's private notes should be shown
                                    <Form className="form-mentorshipSession-mentor">
                                        <FormGroup>
                                            <Input
                                                id="mentorshipSession-notesMentor"
                                                className="form-control"
                                                name = "notesMentor"
                                                value = {this.state.notesMentor}
                                                onChange = {e => this.onChange(e)}
                                                onBlur = {e => this.onChange(e)}
                                                type = "textarea"
                                            />
                                        </FormGroup>
                                    </Form>
                                    : null
                                }
                            </Col>
                        </Row>
                        <Collapse isOpen={this.state.changed}>
                            <Row>
                                {/* <Col md={{ size: 4, offset: 8 }}> */}
                                <Col xs="12">
                                    <Button block onClick={() => this.saveSession()}>Save</Button>
                                </Col>
                            </Row>
                        </Collapse>
                    </Collapse>
                </>
            );
        } else return "loading...";
    };
};


/***************************************************************************************/
/*************************** Session - Mentee Last Session Summary *********************/
/***************************************************************************************/


const INITIAL_STATE_LAST_SESSION_SUMMARY = {
    loading: true,
};

class LastSessionSummaryBase extends Component {
    constructor(props) {
        super(props);
        //props.uid
        //props.sessionID
        //props.refreshPage
        //props.renderFor [mentor|mentee]
        //props.didLoad

        this.state={...INITIAL_STATE_LAST_SESSION_SUMMARY}
    }

    render() {
        return (
            <RenderSingleSessionSummary sessionID={this.props.sessionID} 
                                        uid={this.props.uid}
                                        refreshPage={this.props.refreshPage}
                                        title="Last Mentorship Session"
                                        collapsed={true}
                                        renderFor = {this.props.renderFor}
                                        boldHeader={true} 
                                        didLoad={this.props.didLoad} />
        );
    };
};



/***************************************************************************************/
/*************************** Session - Mentee Session History **************************/
/***************************************************************************************/

const INITIAL_STATE_MENTEE_HISTORY_SUMMARY = {
    loading: true,
    sDocs: null,
    error: null,
    notesGeneral: "",
    notesMentee: "",
    changed: false,
};


class SessionHistoryBase extends Component {
    constructor(props) {
        super(props);
        //props.uid
        //props.mentorshipID
        //props.refreshPage
        //props.numSessionsToLoad
        //props.lastSessionID
        //props.renderFor [mentor|mentee]
        this.state={...INITIAL_STATE_MENTEE_HISTORY_SUMMARY}
        this.loadData = this.loadData.bind(this);
    }
    

    // Loads data from Firebase and sets loading state to false
    loadData = () => {
        if (this.props.mentorshipID !== null && this.props.numSessionsToLoad !== null) {
            this.setState({ loading: true });
            this.props.firebase.doGetMentorshipSessionsByMentorshipSesssionID(this.props.mentorshipID, "completed", this.props.numSessionsToLoad)
            .then(QuerySnapshots => {
                // transforms the data to be easier to handle and stores it in the state
                this.setState({ sDocs: this.mapData(QuerySnapshots), loading: false }
                    ,() => { if (this.props.didLoad !== undefined) this.props.didLoad() });
            }).catch(error => {
                console.log(error)
                this.setState({error});
            }); 
        }
    }

    // Load session data from fs on load
    componentDidMount() {
        this.loadData();
    }
    
    // Helper function to parse the return from firestore
    mapData = resultArray => {
        const returnArray = [];
        resultArray.forEach(doc => {
            if (doc.id !== this.props.lastSessionID) { // exclude the last session from the array
                returnArray.push({
                    id: doc.id,
                    date: doc.data().dateTime,
                    data: doc.data(),
                });
            }
        })
        return returnArray;
    };
        
    // renders the card for each of the sessions
    renderPrevSessionRow= (sDoc) => {
        return (
            <RenderSingleSessionSummary uid={this.props.uid} 
                                                sDoc={sDoc.data} 
                                                sessionID={sDoc.id} 
                                                refreshPage={this.props.refreshPage} 
                                                collapsed={true} 
                                                key={"menteesessionhistory_"+sDoc.id}
                                                renderFor={this.props.renderFor}
                                                boldHeader={true} />
        )
    }

    render() {
        if (this.state && !this.state.loading) {
            return (
                <>
                    <CardTitle tag="h4">Previous Sessions</CardTitle>
                    {this.state.sDocs !== null ? 
                        this.state.sDocs.map(sDoc => this.renderPrevSessionRow(sDoc))
                        : null 
                    }
                </>
            );
        }

        // While data is being loaded from database
        return "loading...";
    };
};



/***************************************************************************************/
/*************************** Session - Mentor Upcoming Sessions Summary ****************/
/***************************************************************************************/

class UpcomingSessionsSummaryBase extends Component {
    constructor(props) {
        super(props);
        //props.sessionID
        //props.renderFor [mentor|mentee]

        this.state={}
    }


    render() {
        return (
            <>
            </>
        );
    };
};



const NextSessionDetails = withFirebase(NextSessionDetailsBase);
const EditSessionModal = withFirebase(EditSessionModalBase);
const SessionStatusAction = withFirebase(SessionStatusActionBase);
const CreateNewSessionModal = withFirebase(CreateNewSessionModalBase);
const RenderSingleSessionSummary = withFirebase(RenderSingleSessionSummaryBase);
const SessionHistory = withFirebase(SessionHistoryBase);
const LastSessionSummary = withFirebase(LastSessionSummaryBase);
const UpcomingSessionsSummary = withFirebase(UpcomingSessionsSummaryBase);

export {NextSessionDetails,
        EditSessionModal,
        SessionStatusAction,
        CreateNewSessionModal,
        RenderSingleSessionSummary,
        SessionHistory,
        LastSessionSummary,
        UpcomingSessionsSummary};