import React, {Component} from 'react';
import {withFirebase} from '../../Firebase/Firebase';
import moment from 'moment';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import {RenderAllPositionCards, PositionAdd} from '../Position/Position';

import {
    Button, 
    Form, 
    FormGroup, 
    Label, 
    Input, 
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Card,
    CardHeader,
    CardTitle,
    Collapse,
    FormFeedback,
    UncontrolledTooltip
} from 'reactstrap';



/*********************************************************************************/
/***************************** Render All Org Card *******************************/
/*********************************************************************************/

const INITIAL_STATE_RENDER = {
    loading: true,
    rendering: true,
    organizationDocs: null,
    error: null,
    numComponentsToRender: 0,
    componentsRendered: 0,
}

class RenderAllOrgCardsBase extends Component {
    constructor(props) {
        super(props);
        this.loadData = this.loadData.bind(this);
        this.childrenDidLoad = this.childrenDidLoad.bind(this);

        // props.uid
        // props.email
        // props.didLoad
        this.state = {...INITIAL_STATE_RENDER};
    }

    // Helper function to parse the return from firestore
    mapData = resultArray => {
        const returnArray = [];
        resultArray.forEach(doc => {
            returnArray.push({
                id: doc.id,
                data: doc.data(),
            });
        })
        return returnArray;
    };

    loadData = () => {
        this.setState({ loading: true });

        this.props.firebase.doGetOrganizationEntries(this.props.uid)
        .then(QuerySnapshots => {
            var returnArray = [];
            QuerySnapshots.forEach(DocumentSnapshot => returnArray.push(DocumentSnapshot));
            this.setState({ organizationDocs: this.mapData(returnArray), loading: false, numComponentsToRender: returnArray.length });
            this.isRenderComplete();
        })
        .catch(error => {
            console.log(error);
            this.setState({error});
        }); 
    }

    isRenderComplete() {
        var isRenderCompleted = !this.state.loading;
        isRenderCompleted &= this.state.componentsRendered >= this.state.numComponentsToRender;
        this.setState({ rendering: !isRenderCompleted });
        if (isRenderCompleted && this.props.didLoad !== undefined) {
            this.props.didLoad();
        }
    }

    childrenDidLoad() {
        var { componentsRendered } = this.state;
        componentsRendered++;
        this.setState( { componentsRendered: componentsRendered } 
                        ,() => { this.isRenderComplete();  })
    }

    // Retrieve organization query snapshot
    componentDidMount() {
        this.loadData();
    }

    render() {
        if (this.state && !this.state.loading) {

            return (
                <>
                    { this.state.organizationDocs.map(doc => (<OrganizationCard key={"org_card_" + doc.id} 
                                                                                orgDocID={doc.id} 
                                                                                orgDoc={doc.data} 
                                                                                uid={this.props.uid}
                                                                                email={this.props.email} 
                                                                                refreshPage={this.loadData}
                                                                                didLoad={this.childrenDidLoad} />)) }
                    <OrganizationAddModal uid={this.props.uid} 
                                            refreshPage={this.loadData} />
                    {this.state.error && <p>{this.state.error.message}</p>}
                </>
            );
        }
        return null;
    }

}


/*********************************************************************************/
/******************************* Organization Card *******************************/
/*********************************************************************************/

const INITIAL_STATE_ORG_CARD = {
    collapse: true,
    error: null,
};

class OrganizationCardBase extends Component {
    constructor(props) {
        super(props);
        
        // props.refreshPage
        // props.orgDocID
        // props.orgDoc
        // props.uid
        // props.email
        // props.didLoad
        /* Required Fields:
            name
            location
            industry
            description
            startDate
            endDate
        */
        this.state = {...INITIAL_STATE_ORG_CARD};
    }

    // Toggle the visibility of the card body
    toggleCollapse = () => {
        this.setState(state => ({ collapse: !state.collapse }));
    }

    render() {
        
        return (
            <>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col xs="10">
                                <CardTitle tag="h2">{this.props.orgDoc.name}</CardTitle>
                                <h5 className="card-category">{moment(this.props.orgDoc.startDate).format("MMM YYYY")} {this.props.orgDoc.endDate? " to " + moment(this.props.orgDoc.endDate).format("MMM YYYY") : " to Present"}</h5>
                            </Col>
                            <Col xs="2" className="text-right">
                                <PositionAdd uid={this.props.uid} 
                                            orgDocID={this.props.orgDocID} 
                                            refreshPage={this.props.refreshPage} />

                                <Button color="link"
                                        id={"achievementOrganization"+this.props.orgDocID}
                                        type="button" 
                                        className="btn-left-spacing"
                                        onClick={() => this.toggleCollapse()} >
                                    <i className={this.state.collapse? "tim-icons icon-minimal-up": "tim-icons icon-minimal-down" }/>
                                </Button>
                                <UncontrolledTooltip delay={0}
                                                    target={"achievementOrganization"+this.props.orgDocID}
                                                    placement="right" >
                                    {this.state.collapse? "Hide Content":"Show Content" }
                                </UncontrolledTooltip>
                            </Col>
                        </Row>
                    </CardHeader>
                    <Collapse isOpen={this.state.collapse}>
                        <RenderAllPositionCards orgDocID={this.props.orgDocID} 
                                                uid={this.props.uid}
                                                email={this.props.email}
                                                didLoad={this.props.didLoad} />
                    </Collapse>
                </Card>
            </>
        )
    }
}


/*********************************************************************************/
/******************************* Organization Edit Modal *************************/
/*********************************************************************************/

const INITIAL_STATE_EDIT_ORG = {
    changed: false,
    validToSubmit: false,

    isValidName: '',
    isValidLocation: '',
    isValidIndustry: '',
    isValidStartDate: '',
    isValidEndDate: '',
    isValidProjectName: '',

    name: '',
    location: '',
    industry: '',
    description: '',
    startDate: '',
    endDate: '',
    editOrganizationModal: false,
    confirmationModal: null,
    error: null,
};

class OrganizationEditModalBase extends Component {
    constructor(props) {
        super(props);

        //props.refreshPage
        //orgDocID={this.props.orgDocID} 
        //orgDoc={this.props.orgDoc}
        /* Required Fields:
            name
            location
            industry
            description
            startDate
            endDate
        */

        INITIAL_STATE_EDIT_ORG.name = props.orgDoc.name;
        INITIAL_STATE_EDIT_ORG.location = props.orgDoc.location;
        INITIAL_STATE_EDIT_ORG.industry = props.orgDoc.industry;
        INITIAL_STATE_EDIT_ORG.description = props.orgDoc.description;
        INITIAL_STATE_EDIT_ORG.startDate = props.orgDoc.startDate;
        INITIAL_STATE_EDIT_ORG.endDate = props.orgDoc.endDate;

        this.state = {...INITIAL_STATE_EDIT_ORG};
    }

    onChange = event => {
        event.persist();
        
        this.setState({
            [event.target.name]: event.target.value
        }, () => {
            this.setState({validToSubmit: this.validateSubmission(event.target.name)});
            this.isChanged();
        });
    };

    // Validate whether the field value(s) are valid to submit
    validateSubmission = fieldToValidate => {
        var isValid = true; 

        if (fieldToValidate === "name" || fieldToValidate === "all") {
            if (this.state.name === '') { 
                this.setState({isValidName: "has-danger"});
                isValid = false;
            } else this.setState({isValidName: ""});
        }
        if (fieldToValidate === "location" || fieldToValidate === "all") {
            if (this.state.location === '') { 
                this.setState({isValidLocation: "has-danger"});
                isValid = false;
            } else this.setState({isValidLocation: ""});
        }
        if (fieldToValidate === "industry" || fieldToValidate === "all") {
            if (this.state.industry === '') { 
                this.setState({isValidIndustry: "has-danger"});
                isValid = false;
            } else this.setState({isValidIndustry: ""});
        }
        if (fieldToValidate === "startDate" || fieldToValidate === "all") {
            if (this.state.startDate === '') { 
                this.setState({isValidStartDate: "has-danger"});
                isValid = false;
            } else this.setState({isValidStartDate: ""});
        }
        if (fieldToValidate === "endDate" || fieldToValidate === "all") {
            if (this.state.endDate !== '' && this.state.endDate < this.state.startDate) { 
                this.setState({isValidEndDate: "has-danger"});
                isValid = false;
            } else this.setState({isValidProjectName: ""});
        }

        return isValid;
    }

    // Validate whether there are changes to the form
    isChanged() {
        var isChanged = INITIAL_STATE_EDIT_ORG.name !== this.state.name
            || INITIAL_STATE_EDIT_ORG.location !== this.state.location
            || INITIAL_STATE_EDIT_ORG.industry !== this.state.industry
            || INITIAL_STATE_EDIT_ORG.description !== this.state.description
            || INITIAL_STATE_EDIT_ORG.startDate !== this.state.startDate
            || INITIAL_STATE_EDIT_ORG.endDate !== this.state.endDate;

        this.setState({ changed: isChanged });
    }

    // Close button action for main feedback modal
    closeEditModal = () => {
        if (this.state.changed) this.setState({confirmationModal: "close"});
        else this.setState({...INITIAL_STATE_EDIT_ORG});
    } 

    // Delete confirmation button action for main edit modal
    deleteOrgConfirmation = () => {
        this.setState({confirmationModal: "delete"});
    }

    // Delete position entry after confirmation
    deleteOrgEntry = () => {
        this.props.firebase.doDeleteOrganizationEntry(
            this.props.orgDocID
        ).then(() => {
            this.props.refreshPage();
        }).catch(error => {
            console.log(error);
            this.setState({error});
        }); 
    }

    // Creates the Organization entry in 
    updateOrganizationEntry = () => {
        if (!this.state.changed){
            this.closeEditModal();
        } else if (this.validateSubmission("all")) {
            this.props.firebase.doUpdateOrganizationEntry(
                this.props.orgDocID, 
                this.state.name, 
                this.state.location,
                this.state.industry,
                this.state.description,
                this.state.startDate,
                this.state.endDate
            ).then(() => {
                this.props.refreshPage();
                //this.setState({...INITIAL_STATE_EDIT_ORG});
            }).catch(error => {
                console.log(error);
                this.setState({error});
            }); 
        } else this.setState({error: new Error("There may be invalid values or missing required fields.")})
    }

    render() {
        
        return (
            <>

                <Button color="link"
                        id={"achievementOrganizationEdit"+this.props.orgDocID}
                        type="button" 
                        className="btn-left-spacing"
                        onClick={() => this.setState({editOrganizationModal: true})} >
                    <i className="tim-icons icon-pencil" />
                </Button>
                <UncontrolledTooltip delay={0}
                                    target={"achievementOrganizationEdit"+this.props.orgDocID}
                                    placement="right" >
                    Edit Organization
                </UncontrolledTooltip>

                <Modal isOpen={this.state.editOrganizationModal} toggle={() => this.closeEditModal()} size="lg">
                    <ModalHeader tag="h4" toggle={() => this.closeEditModal()}>Edit Organization</ModalHeader>
                    <ModalBody>
                        <Form id="Organization-edit">
                            <FormGroup className={this.state.isValidName}>
                                <Label for="editOrg-name">Organization Name</Label>
                                <Input
                                    id="editOrg-name"
                                    className="form-control"
                                    name = "name"
                                    value = {this.state.name}
                                    onChange = {e => this.onChange(e)}
                                    onBlur = {e => this.onChange(e)}
                                    type = "text"
                                    required
                                />
                            </FormGroup>
                            <FormGroup className={this.state.isValidIndustry}>
                                <Label for="editOrg-industry">Industry</Label>
                                <Input
                                    id="editOrg-industry"
                                    className="form-control"
                                    name = "industry"
                                    value = {this.state.industry}
                                    onChange = {e => this.onChange(e)}
                                    onBlur = {e => this.onChange(e)}
                                    type = "text"
                                    required
                                />
                            </FormGroup>
                            <FormGroup className={this.state.isValidLocation}>
                                <Label for="editOrg-location">Location</Label>
                                <Input
                                    id="editOrg-location"
                                    className="form-control"
                                    name = "location"
                                    value = {this.state.location}
                                    onChange = {e => this.onChange(e)}
                                    onBlur = {e => this.onChange(e)}
                                    type = "text"
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="editOrg-description">Description</Label>
                                <Input
                                    id="editOrg-description"
                                    className="form-control"
                                    name = "description"
                                    value = {this.state.description}
                                    onChange = {e => this.onChange(e)}
                                    type = "textarea"
                                    required
                                />
                            </FormGroup>
                            <Row>
                                <Col xs="6">
                                    <FormGroup className={this.state.isValidStartDate}>
                                        <Label for="editOrg-startDate">Start Date</Label>
                                        <Input 
                                            type="date" 
                                            name="startDate" 
                                            value = {this.state.startDate}
                                            onChange = {e => this.onChange(e)}
                                            onBlur = {e => this.onChange(e)}
                                            id="editOrg-startDate"
                                            />
                                    </FormGroup>
                                </Col>
                                <Col xs="6">
                                    <FormGroup className={this.state.isValidEndDate}>
                                        <Label for="editOrg-endDate">End Date</Label>
                                        <Input 
                                            type="date" 
                                            name="endDate" 
                                            value = {this.state.endDate}
                                            onChange = {e => this.onChange(e)}
                                            onBlur = {e => this.onChange(e)}
                                            id="editOrg-endDate"
                                            invalid={this.state.isValidEndDate !== ''}
                                            />
                                        <FormFeedback>Cannot be before start date</FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>


                        <Modal isOpen={this.state.confirmationModal !== null} toggle={() => this.setState({confirmationModal: null})} size="sm">
                            <ModalHeader tag="h4">
                                {this.state.confirmationModal === "cancel"? "Cancel": "Delete"} Confirmation
                            </ModalHeader>
                            <ModalBody>
                                {this.state.confirmationModal === "cancel"? 
                                    <>There are unsaved changes.<br />Are you sure you would like to cancel?</>
                                    : <>Are you sure you would like to delete this organization?<br />This change can not be undone.</>}
                            </ModalBody>
                            <ModalFooter>
                                {this.state.confirmationModal === "cancel"? 
                                    <i className="tim-icons icon-check-2" onClick={() => this.setState({...INITIAL_STATE_ADD_ORG})}/>
                                    :<i className="tim-icons icon-check-2" onClick={() => this.deleteOrgEntry()}/>}
                                    <i className="tim-icons icon-simple-remove" onClick={() => this.setState({confirmationModal: null})}/>
                            </ModalFooter>
                        </Modal>
                        
                        {this.state.error && <p>{this.state.error.message}</p>}
                    </ModalBody>
                    <ModalFooter>
                        <i className="tim-icons icon-trash-simple" onClick={() => this.deleteOrgConfirmation()}/>
                        <i className="tim-icons icon-check-2" onClick={() => this.updateOrganizationEntry()}/>
                        <i className="tim-icons icon-simple-remove" onClick={() => this.closeEditModal()}/>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}

/*********************************************************************************/
/****************************** Add Organization Modal ***************************/
/*********************************************************************************/

const INITIAL_STATE_ADD_ORG = {
    changed: false,
    validToSubmit: false,

    isValidName: '',
    isValidLocation: '',
    isValidIndustry: '',
    isValidStartDate: '',
    isValidProjectName: '',

    addOrganizationModal: false,
    name: '',
    industry: '',
    location: '',
    description: '',
    startDate: '',
    endDate: '',
    confirmationModal: false,
    error: null,
};

class OrganizationAddModalBase extends Component {
    constructor(props) {
        super(props);
        
        //props.refreshPage
        //uid={this.props.uid}

        this.state = {...INITIAL_STATE_ADD_ORG};
    }

    onChange = event => {
        event.persist();
        
        this.setState({
            [event.target.name]: event.target.value
        }, () => {
            this.setState({validToSubmit: this.validateSubmission(event.target.name)});
            this.isChanged();
        });
    };

    // Validate whether the field value(s) are valid to submit
    validateSubmission = fieldToValidate => {
        var isValid = true; 

        if (fieldToValidate === "name" || fieldToValidate === "all") {
            if (this.state.name === '') { 
                this.setState({isValidName: "has-danger"});
                isValid = false;
            } else this.setState({isValidName: ""});
        }
        if (fieldToValidate === "location" || fieldToValidate === "all") {
            if (this.state.location === '') { 
                this.setState({isValidLocation: "has-danger"});
                isValid = false;
            } else this.setState({isValidLocation: ""});
        }
        if (fieldToValidate === "industry" || fieldToValidate === "all") {
            if (this.state.industry === '') { 
                this.setState({isValidIndustry: "has-danger"});
                isValid = false;
            } else this.setState({isValidIndustry: ""});
        }
        if (fieldToValidate === "startDate" || fieldToValidate === "all") {
            if (this.state.startDate === '') { 
                this.setState({isValidStartDate: "has-danger"});
                isValid = false;
            } else this.setState({isValidStartDate: ""});
        }
        if (fieldToValidate === "endDate" || fieldToValidate === "all") {
            if (this.state.endDate !== '' && this.state.endDate < this.state.startDate) { 
                this.setState({isValidEndDate: "has-danger"});
                isValid = false;
            } else this.setState({isValidProjectName: ""});
        }

        return isValid;
    }

    // Validate whether there are changes to the form
    isChanged() {
        let isChanged = INITIAL_STATE_ADD_ORG.name !== this.state.name
            || INITIAL_STATE_ADD_ORG.location !== this.state.location
            || INITIAL_STATE_ADD_ORG.industry !== this.state.industry
            || INITIAL_STATE_ADD_ORG.description !== this.state.description
            || INITIAL_STATE_ADD_ORG.startDate !== this.state.startDate
            || INITIAL_STATE_ADD_ORG.endDate !== this.state.endDate;
        
        this.setState({ changed: isChanged });
    }

    // Close button action for main feedback modal
    closeCreateModal = () => {
        if (this.state.changed) this.setState({confirmationModal: true});
        else this.setState({...INITIAL_STATE_ADD_ORG});
    } 

    // Creates the Organization entry in 
    createOrganizationEntry = () => {
        if (this.validateSubmission("all")) {
            this.props.firebase.doAddOrganizationEntry(
                this.props.uid, 
                this.state.name, 
                this.state.location,
                this.state.industry,
                this.state.description,
                this.state.startDate,
                this.state.endDate
            ).then(() => {
                this.props.refreshPage();
                //this.setState({...INITIAL_STATE_ADD_ORG});
            }).catch(error => {
                console.log(error);
                this.setState({error});
            }); 
        } else this.setState({ error: new Error("There may be invalid values or missing required fields.")})
    }

    render() {
        
        return (
            <>
                <Button block color="primary" onClick={() => this.setState({addOrganizationModal: true})}>
                    <i className="tim-icons icon-simple-add" 
                        alt="Add New Organization" />{' '}
                    Add New Organization
                </Button> 

                <Modal isOpen={this.state.addOrganizationModal} toggle={() => this.closeCreateModal()} size="lg">
                    <ModalHeader tag="h4" toggle={() => this.closeCreateModal()}>Add Organization</ModalHeader>
                    <ModalBody>
                        <Form id="Organization-addNew">
                        <FormGroup className={this.state.isValidName}>
                                <Label for="addOrg-name">Organization Name</Label>
                                <Input
                                    id="addOrg-name"
                                    className="form-control"
                                    name = "name"
                                    value = {this.state.name}
                                    onChange = {e => this.onChange(e)}
                                    onBlur = {e => this.onChange(e)}
                                    type = "text"
                                    required
                                />
                            </FormGroup>
                            <FormGroup className={this.state.isValidIndustry}>
                                <Label for="addOrg-industry">Industry</Label>
                                <Input
                                    id="addOrg-industry"
                                    className="form-control"
                                    name = "industry"
                                    value = {this.state.industry}
                                    onChange = {e => this.onChange(e)}
                                    onBlur = {e => this.onChange(e)}
                                    type = "text"
                                    required
                                />
                            </FormGroup>
                            <FormGroup className={this.state.isValidLocation}>
                                <Label for="addOrg-location">Location</Label>
                                <Input
                                    id="addOrg-location"
                                    className="form-control"
                                    name = "location"
                                    value = {this.state.location}
                                    onChange = {e => this.onChange(e)}
                                    onBlur = {e => this.onChange(e)}
                                    type = "text"
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="addOrg-description">Description</Label>
                                <Input
                                    id="addOrg-description"
                                    className="form-control"
                                    name = "description"
                                    value = {this.state.description}
                                    onChange = {e => this.onChange(e)}
                                    onBlur = {e => this.onChange(e)}
                                    type = "textarea"
                                    required
                                />
                            </FormGroup>
                            <Row>
                                <Col xs="6">
                                    <FormGroup className={this.state.isValidStartDate}>
                                        <Label for="addOrg-startDate">Start Date</Label>
                                        <Input 
                                            type="date" 
                                            name="startDate" 
                                            value = {this.state.startDate}
                                            onChange = {e => this.onChange(e)}
                                            onBlur = {e => this.onChange(e)}
                                            id="addOrg-startDate"
                                            />
                                    </FormGroup>
                                </Col>
                                <Col xs="6">
                                    <FormGroup className={this.state.isValidEndDate}>
                                        <Label for="addOrg-endDate">End Date</Label>
                                        <Input 
                                            type="date" 
                                            name="endDate" 
                                            value = {this.state.endDate}
                                            onChange = {e => this.onChange(e)}
                                            onBlur = {e => this.onChange(e)}
                                            id="addOrg-endDate"
                                            invalid={this.state.isValidEndDate !== ''}
                                            />
                                        <FormFeedback>Cannot be before start date</FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>

                        <Modal isOpen={this.state.confirmationModal} toggle={() => this.setState({confirmationModal: false})} size="sm">
                            <ModalHeader tag="h4">Cancel Confirmation</ModalHeader>
                            <ModalBody>
                                <center>There are unsaved changes.<br />Are you sure you would like to cancel?</center>
                            </ModalBody>
                            <ModalFooter>
                                <i className="tim-icons icon-check-2" onClick={() => this.setState({...INITIAL_STATE_ADD_ORG})}/>
                                <i className="tim-icons icon-simple-remove" onClick={() => this.setState({confirmationModal: false})}/>
                            </ModalFooter>
                        </Modal>
                        
                        {this.state.error && <p>{this.state.error.message}</p>}
                    </ModalBody>
                    <ModalFooter>
                        <i className="tim-icons icon-simple-add" onClick={() => this.createOrganizationEntry()}/>
                        <i className="tim-icons icon-simple-remove" onClick={() => this.closeCreateModal()}/>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}

/************** Exports *****************/
const OrganizationAddModal = withFirebase(OrganizationAddModalBase);
const OrganizationCard = withFirebase(OrganizationCardBase);
const OrganizationEditModal = withFirebase(OrganizationEditModalBase);
const RenderAllOrgCards = withFirebase(RenderAllOrgCardsBase);

export {RenderAllOrgCards, OrganizationCard, OrganizationAddModal, OrganizationEditModal};